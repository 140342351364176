import Operator from 'src/entities/accounts/Operator';
import Company from 'src/entities/companies/Company';
import { AutoFormFields } from '../BaseEntity';

export type OperatorFullFormFields = AutoFormFields<OperatorFull>;

export default class OperatorFull extends Operator {
	company?: Company;

	constructor(json: any) {
		super(json);
		this.company = json.company ? new Company(json.company) : undefined;
	}

	static formFields(entity?: OperatorFull): OperatorFullFormFields {
		return {
			...Operator.formFields(entity),
			company: entity?.company?.getLink(),
		};
	}
}
