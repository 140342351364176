import DocumentDescription from 'src/entities/booking/progress/steps/insurance/DocumentDescription';
import AgreementDescription from 'src/entities/booking/progress/steps/insurance/AgreementDescription';
import MessageHolder from 'src/entities/booking/progress/steps/insurance/MessageHolder';
import { fromJsonArray } from 'src/helper/helper';
import { getDescriptionOrDefault } from 'src/entities/booking/progress/steps/insurance/V4InsuranceOfferRS';

export default class V4InsuranceContractRS {
	token: string;
	documentDescription: DocumentDescription[];
	agreementDescription: AgreementDescription[];
	messageHolder: MessageHolder;

	constructor(json: any) {
		this.token = json.token;
		this.documentDescription = fromJsonArray(DocumentDescription, json.documentDescription);
		this.agreementDescription = fromJsonArray(AgreementDescription, json.agreementDescription);
		this.messageHolder = new MessageHolder(json.messageHolder);
	}

	getInterpolatedProductAgreementText(): string {
		return getDescriptionOrDefault(this.agreementDescription).agreementText;
	}
}
