import { Component } from 'react';
import { CompiledRoutes, S_COMPONENT } from 'src/routing';

export const SEARCH_ROOT = 'search';

export const SEARCH_ROUTES = new CompiledRoutes(SEARCH_ROOT, {
	[S_COMPONENT]: Component,
	companies: {
		[S_COMPONENT]: Component,
	},
	id: {
		[S_COMPONENT]: Component,
	},
	csbus: {
		[S_COMPONENT]: Component,
	},
	// The slug path needs to be the last route. Else it would match for companies
	':slug': {
		[S_COMPONENT]: Component,
	},
});
