import BasicType from 'src/entities/basic-types/BasicType';

export default class Location implements BasicType {
	address: string;
	lat: number;
	lng: number;
	timeZone?: string;
	country?: string;

	constructor(address: string, lat: number, lng: number, timeZone?: string, country?: string) {
		this.address = address;
		this.lat = lat;
		this.lng = lng;
		this.timeZone = timeZone;
		this.country = country;
	}

	toString(): string {
		return this.address;
	}

	equals(other?: Location | null): boolean {
		if (other == null) return this.address == null && this.lat == null && this.lng == null;

		return this.address === other.address && this.lat === other.lat && this.lng === other.lng;
	}

	static fromJson(json: any): Location | undefined {
		return json ? new Location(json.address, json.lat, json.lng, json.timeZone, json.country) : undefined;
	}

	static fromGoogleMapsResult(
		result: google.maps.places.PlaceResult | google.maps.GeocoderResult | null,
	): Location | null {
		const address = result?.formatted_address;
		const location = result?.geometry?.location;
		const addressComponents = result?.address_components;

		if (!address || !location || !addressComponents) return null;

		const country = addressComponents.find(component => component.types.includes('country'))?.short_name;

		return new Location(address, location.lat(), location.lng(), undefined, country);
	}

	getParams(): Record<string, unknown> {
		return {
			address: this.address,
			lat: this.lat,
			lng: this.lng,
			timeZone: this.timeZone,
			country: this.country,
		};
	}
}
