import { composeWithDevTools } from '@redux-devtools/extension';
import { Store, applyMiddleware, createStore } from '@reduxjs/toolkit';
import { History } from 'history';
import { assign } from 'lodash';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { IS_PRODUCTION } from 'src/config/env';
import buildRootReducer from 'src/redux/reducers';
import rootSaga from 'src/redux/sagas';
import { StoreState } from 'src/types';
import URI from 'urijs';

/**
 * Production store configuration
 */
function configureProductionStore(sagaMiddleware: SagaMiddleware<any>): Store<StoreState> {
	return createStore(buildRootReducer(), applyMiddleware(sagaMiddleware));
}

/**
 * Development store configuration
 */
function configureDevStore(sagaMiddleware: SagaMiddleware<any>): Store<StoreState> {
	const logger = createLogger({
		collapsed: true,
		diff: true,
		duration: true,
	});

	return createStore(buildRootReducer(), composeWithDevTools(applyMiddleware(sagaMiddleware, logger)));
}

// Initialize the store. It would be nice to wrap this in a setup function but we need global access to the
// store in a lot of places because we were lazy and didn't hook it up properly...
const sagaMiddleware = createSagaMiddleware();

export const store = IS_PRODUCTION ? configureProductionStore(sagaMiddleware) : configureDevStore(sagaMiddleware);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

// For this reason we need to patch in the saga context after the store is created
export function patchSagaContext(history: History) {
	history.listen(() => {
		addLocationQuery(history);
	});
	addLocationQuery(history);
	sagaMiddleware.setContext({ history });
}

/**
 * History listener parsing search query with the help of "urijs"
 * and store the parsed query parameters in the redux state store
 *
 * Parsed query can be accessed from state under: state.router.location.query
 */
function addLocationQuery(h: History) {
	h.location = assign(h.location, {
		query: h.location.search && h.location.search.length > 0 ? URI.parseQuery(h.location.search.slice(1)) : {},
	});
}
