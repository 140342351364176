import { FlagNameValues } from 'semantic-ui-react';
import { emptyStringMenuItem, MenuItem } from 'src/components/elements/form-inputs/SelectField';
import countries from 'src/config/countries/countries.json';
import { SupportedLanguage } from 'src/config/i18next/i18next';

const FlagNames = new Set<FlagNameValues>([
	'ad',
	'andorra',
	'ae',
	'united arab emirates',
	'uae',
	'af',
	'afghanistan',
	'ag',
	'antigua',
	'ai',
	'anguilla',
	'al',
	'albania',
	'am',
	'armenia',
	'an',
	'netherlands antilles',
	'ao',
	'angola',
	'ar',
	'argentina',
	'as',
	'american samoa',
	'at',
	'austria',
	'au',
	'australia',
	'aw',
	'aruba',
	'ax',
	'aland islands',
	'az',
	'azerbaijan',
	'ba',
	'bosnia',
	'bb',
	'barbados',
	'bd',
	'bangladesh',
	'be',
	'belgium',
	'bf',
	'burkina faso',
	'bg',
	'bulgaria',
	'bh',
	'bahrain',
	'bi',
	'burundi',
	'bj',
	'benin',
	'bm',
	'bermuda',
	'bn',
	'brunei',
	'bo',
	'bolivia',
	'br',
	'brazil',
	'bs',
	'bahamas',
	'bt',
	'bhutan',
	'bv',
	'bouvet island',
	'bw',
	'botswana',
	'by',
	'belarus',
	'bz',
	'belize',
	'ca',
	'canada',
	'cc',
	'cocos islands',
	'cd',
	'congo',
	'cf',
	'central african republic',
	'cg',
	'congo brazzaville',
	'ch',
	'switzerland',
	'ci',
	'cote divoire',
	'ck',
	'cook islands',
	'cl',
	'chile',
	'cm',
	'cameroon',
	'cn',
	'china',
	'co',
	'colombia',
	'cr',
	'costa rica',
	'cs',
	'cu',
	'cuba',
	'cv',
	'cape verde',
	'cx',
	'christmas island',
	'cy',
	'cyprus',
	'cz',
	'czech republic',
	'de',
	'germany',
	'dj',
	'djibouti',
	'dk',
	'denmark',
	'dm',
	'dominica',
	'do',
	'dominican republic',
	'dz',
	'algeria',
	'ec',
	'ecuador',
	'ee',
	'estonia',
	'eg',
	'egypt',
	'eh',
	'western sahara',
	'er',
	'eritrea',
	'es',
	'spain',
	'et',
	'ethiopia',
	'eu',
	'european union',
	'fi',
	'finland',
	'fj',
	'fiji',
	'fk',
	'falkland islands',
	'fm',
	'micronesia',
	'fo',
	'faroe islands',
	'fr',
	'france',
	'ga',
	'gabon',
	'gb',
	'united kingdom',
	'gd',
	'grenada',
	'ge',
	'georgia',
	'gf',
	'french guiana',
	'gh',
	'ghana',
	'gi',
	'gibraltar',
	'gl',
	'greenland',
	'gm',
	'gambia',
	'gn',
	'guinea',
	'gp',
	'guadeloupe',
	'gq',
	'equatorial guinea',
	'gr',
	'greece',
	'gs',
	'sandwich islands',
	'gt',
	'guatemala',
	'gu',
	'guam',
	'gw',
	'guinea-bissau',
	'gy',
	'guyana',
	'hk',
	'hong kong',
	'hm',
	'heard island',
	'hn',
	'honduras',
	'hr',
	'croatia',
	'ht',
	'haiti',
	'hu',
	'hungary',
	'id',
	'indonesia',
	'ie',
	'ireland',
	'il',
	'israel',
	'in',
	'india',
	'io',
	'indian ocean territory',
	'iq',
	'iraq',
	'ir',
	'iran',
	'is',
	'iceland',
	'it',
	'italy',
	'jm',
	'jamaica',
	'jo',
	'jordan',
	'jp',
	'japan',
	'ke',
	'kenya',
	'kg',
	'kyrgyzstan',
	'kh',
	'cambodia',
	'ki',
	'kiribati',
	'km',
	'comoros',
	'kn',
	'saint kitts and nevis',
	'kp',
	'north korea',
	'kr',
	'south korea',
	'kw',
	'kuwait',
	'ky',
	'cayman islands',
	'kz',
	'kazakhstan',
	'la',
	'laos',
	'lb',
	'lebanon',
	'lc',
	'saint lucia',
	'li',
	'liechtenstein',
	'lk',
	'sri lanka',
	'lr',
	'liberia',
	'ls',
	'lesotho',
	'lt',
	'lithuania',
	'lu',
	'luxembourg',
	'lv',
	'latvia',
	'ly',
	'libya',
	'ma',
	'morocco',
	'mc',
	'monaco',
	'md',
	'moldova',
	'me',
	'montenegro',
	'mg',
	'madagascar',
	'mh',
	'marshall islands',
	'mk',
	'macedonia',
	'ml',
	'mali',
	'mm',
	'myanmar',
	'burma',
	'mn',
	'mongolia',
	'mo',
	'macau',
	'mp',
	'northern mariana islands',
	'mq',
	'martinique',
	'mr',
	'mauritania',
	'ms',
	'montserrat',
	'mt',
	'malta',
	'mu',
	'mauritius',
	'mv',
	'maldives',
	'mw',
	'malawi',
	'mx',
	'mexico',
	'my',
	'malaysia',
	'mz',
	'mozambique',
	'na',
	'namibia',
	'nc',
	'new caledonia',
	'ne',
	'niger',
	'nf',
	'norfolk island',
	'ng',
	'nigeria',
	'ni',
	'nicaragua',
	'nl',
	'netherlands',
	'no',
	'norway',
	'np',
	'nepal',
	'nr',
	'nauru',
	'nu',
	'niue',
	'nz',
	'new zealand',
	'om',
	'oman',
	'pa',
	'panama',
	'pe',
	'peru',
	'pf',
	'french polynesia',
	'pg',
	'new guinea',
	'ph',
	'philippines',
	'pk',
	'pakistan',
	'pl',
	'poland',
	'pm',
	'saint pierre',
	'pn',
	'pitcairn islands',
	'pr',
	'puerto rico',
	'ps',
	'palestine',
	'pt',
	'portugal',
	'pw',
	'palau',
	'py',
	'paraguay',
	'qa',
	'qatar',
	're',
	'reunion',
	'ro',
	'romania',
	'rs',
	'serbia',
	'ru',
	'russia',
	'rw',
	'rwanda',
	'sa',
	'saudi arabia',
	'sb',
	'solomon islands',
	'sc',
	'seychelles',
	'gb sct',
	'scotland',
	'sd',
	'sudan',
	'se',
	'sweden',
	'sg',
	'singapore',
	'sh',
	'saint helena',
	'si',
	'slovenia',
	'sj',
	'svalbard',
	'jan mayen',
	'sk',
	'slovakia',
	'sl',
	'sierra leone',
	'sm',
	'san marino',
	'sn',
	'senegal',
	'so',
	'somalia',
	'sr',
	'suriname',
	'st',
	'sao tome',
	'sv',
	'el salvador',
	'sy',
	'syria',
	'sz',
	'swaziland',
	'tc',
	'caicos islands',
	'td',
	'chad',
	'tf',
	'french territories',
	'tg',
	'togo',
	'th',
	'thailand',
	'tj',
	'tajikistan',
	'tk',
	'tokelau',
	'tl',
	'timorleste',
	'tm',
	'turkmenistan',
	'tn',
	'tunisia',
	'to',
	'tonga',
	'tr',
	'turkey',
	'tt',
	'trinidad',
	'tv',
	'tuvalu',
	'tw',
	'taiwan',
	'tz',
	'tanzania',
	'ua',
	'ukraine',
	'ug',
	'uganda',
	'um',
	'us minor islands',
	'us',
	'america',
	'united states',
	'uy',
	'uruguay',
	'uz',
	'uzbekistan',
	'va',
	'vatican city',
	'vc',
	'saint vincent',
	've',
	'venezuela',
	'vg',
	'british virgin islands',
	'vi',
	'us virgin islands',
	'vn',
	'vietnam',
	'vu',
	'vanuatu',
	'gb wls',
	'wales',
	'wf',
	'wallis and futuna',
	'ws',
	'samoa',
	'ye',
	'yemen',
	'yt',
	'mayotte',
	'za',
	'south africa',
	'zm',
	'zambia',
	'zw',
	'zimbabwe',
]);

/**
 * Represents a single country from the countries.json.
 *
 * https://restcountries.eu
 */
export interface Country {
	name: string;
	alpha2Code: string;
	alpha3Code: string;
	flag?: string;
	translations: Record<string, string | undefined>;
}

/**
 * Return 3 letter country code for the given 2 letter country code, or null if not available.
 */
export const get3LetterCountryCode = (twoLetterCountryCode: string): string | null => {
	const country = countries.find((c: Country) => c.alpha2Code === twoLetterCountryCode);

	return country ? country.alpha3Code : null;
};

/**
 * Use this array of country menu items in every selection dropdown.
 */
export const countryMenuItems: MenuItem[] = [];

/**
 * Populates {@link countryMenuItems} every time the language changes.
 */
export const populateCountryMenuItems = (language: SupportedLanguage, defaultCountryAlpha3Code: string) => {
	countryMenuItems.length = 0;

	countries.forEach((country: Country) => {
		const countryCode = country.alpha2Code.toLowerCase() as FlagNameValues;
		const flag = FlagNames.has(countryCode) ? countryCode : undefined;
		countryMenuItems.push({
			key: country.alpha3Code,
			value: country.alpha3Code,
			text: getCountryLabel(language, country) ?? '',
			flag,
		});
	});

	countryMenuItems.sort((a, b) => {
		const nameA = a.text.toLowerCase();
		const nameB = b.text.toLowerCase();
		return nameA.localeCompare(nameB);
	});

	// Add empty menu item at the start
	countryMenuItems.splice(0, 0, emptyStringMenuItem);

	// Move default country to the start (before empty menu item)
	const defaultCountryIndex = countryMenuItems.findIndex(item => item.value === defaultCountryAlpha3Code);
	const defaultCountryItem = countryMenuItems[defaultCountryIndex];
	countryMenuItems.splice(defaultCountryIndex, 1);
	countryMenuItems.splice(0, 0, defaultCountryItem);
};

/**
 * Returns the country label for the given language
 */
const getCountryLabel = (language: SupportedLanguage, country: Country) => {
	return country.translations[language] ?? country.name;
};
