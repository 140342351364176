import uniqueId from 'lodash/uniqueId';
import { ReactNode, useMemo } from 'react';

import { DialogContext, type DialogVariant, type IDialogContext } from '../types';

import { Modal, ModalProps } from 'semantic-ui-react';
import { UseDisclosure } from 'src/hooks/useDisclosure';
import { DialogActions, type DialogActionsProps } from './DialogActions';
import { DialogContent, type DialogContentProps } from './DialogContent';

interface DialogProps<Variant extends DialogVariant = DialogVariant>
	extends UseDisclosure,
		Pick<ModalProps, 'size' | 'dimmer'> {
	readonly variant: Variant;
	readonly title?: string;
	readonly children?: DialogContentProps<Variant>['content'];
	readonly actions?: DialogActionsProps<Variant>['actions'];
	readonly disableTrivialClose?: boolean;
}

export const Dialog = <Variant extends DialogVariant>({
	variant,
	actions,
	title,
	state,
	size,
	dimmer,
	children,
	disableTrivialClose,
	close,
}: DialogProps<Variant>) => {
	const id = useMemo(() => uniqueId(`${variant}-dialog`), [variant]);

	const value: IDialogContext = useMemo(
		() => ({
			id,
			close,
		}),
		[id, close],
	);

	const handleClose = () => {
		if (disableTrivialClose) return;
		close();
	};

	return (
		<DialogContext.Provider value={value}>
			<Modal
				open={state.open}
				size={size}
				dimmer={dimmer}
				centered
				// closeIcon={modalProps.closeIcon}
				closeOnDimmerClick={!disableTrivialClose}
				closeOnEscape={!disableTrivialClose}
				onClose={handleClose}>
				{title && <Modal.Header content={title} />}
				<DialogContent variant={variant} content={children} />
				<DialogActions variant={variant} actions={actions} disableTrivialClose={disableTrivialClose} />
			</Modal>
		</DialogContext.Provider>
	);
};
