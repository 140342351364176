import Money from 'src/entities/basic-types/Money';
import BundleItem, { BundleItemFormFields } from 'src/entities/bundle/BundleItem';
import { productFullFromJson } from 'src/entities/factories/product-factory';
import { ProductFull } from 'src/entities/products/ProductFull';
import Stop from 'src/entities/products/stop/Stop';
import BundleFull from 'src/entities/bundle/BundleFull';
import { AutoFormFields } from '../BaseEntity';

export type BundleItemFullFormFields = AutoFormFields<BundleItemFull>;

export default class BundleItemFull extends BundleItem {
	product: ProductFull;
	bundle: BundleFull;
	stop?: Stop;
	readonly price: Money;
	readonly booked: number;
	readonly cancelled: number;

	constructor(json: any) {
		super(json);

		this.product = productFullFromJson(json.product);
		this.bundle = new BundleFull(json.bundle);
		this.stop = json.stop != null ? new Stop(json.stop) : undefined;
		this.price = Money.fromJson(json.price);
		this.booked = json.booked.first as number;
		this.cancelled = json.booked.second as number;
	}

	toString(): string {
		return `${this.className}: ${this.product.id}`;
	}

	static formFields(entity?: BundleItemFull): BundleItemFullFormFields {
		return {
			...BundleItem.formFields(entity),
			product: entity?.product.getLink(),
			bundle: entity?.bundle.getLink(),
			stop: entity?.stop?.getLink(),
			price: entity?.price,
			booked: entity?.booked,
			cancelled: entity?.cancelled,
		};
	}
}
