import { FC, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import 'src/App.css';
import ConnectedApp from 'src/ConnectedApp';
import RequestCookies from 'src/components/error/RequestCookies';
import { ACCOUNT_ROOT } from 'src/scenes/AccountRoutes';
import { BACKEND_ROOT } from 'src/scenes/BackendRoutes';
import { BOOKING_ROOT } from 'src/scenes/BookingRoutes';
import { DEV_ROOT } from 'src/scenes/DevRoutes';
import { PRINT_ROOT } from 'src/scenes/PrintRoutes';
import { SEARCH_ROOT } from 'src/scenes/SearchRoutes';
import { STATIC_ROOT } from 'src/scenes/StaticRoutes';
import { IS_DEVELOPMENT } from './config/env';

// Dynamically load scenes to keep Webpack bundles smallish
const Dev = lazy(() => import('src/scenes/Dev'));
const Landing = lazy(() => import('src/scenes/Landing'));
const Account = lazy(() => import('src/scenes/Account'));
const Backend = lazy(() => import('src/scenes/Backend'));
const Booking = lazy(() => import('src/scenes/Booking'));
const Search = lazy(() => import('src/scenes/Search'));
const Static = lazy(() => import('src/scenes/Static'));
const Print = lazy(() => import('src/scenes/Print'));

const App: FC = () => {
	return (
		<ConnectedApp>
			<Switch>
				{IS_DEVELOPMENT && (
					<Route path={`/${DEV_ROOT}`}>
						<Dev />
					</Route>
				)}
				<Route path={`/${ACCOUNT_ROOT}`}>
					<Account />
				</Route>
				<Route path={`/${BACKEND_ROOT}`}>
					<Backend />
				</Route>
				<Route path={`/${BOOKING_ROOT}`}>
					<Booking />
				</Route>
				<Route path={`/${SEARCH_ROOT}`}>
					<Search />
				</Route>
				<Route path={`/${STATIC_ROOT}`}>
					<Static />
				</Route>
				<Route path={`/${PRINT_ROOT}`}>
					<Print />
				</Route>
				<Route path={`/request-cookies`}>
					<RequestCookies />
				</Route>
				<Route path="/">
					<Landing />
				</Route>
			</Switch>
		</ConnectedApp>
	);
};

export default App;
