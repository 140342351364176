import { Action, ReducersMapObject, combineReducers } from '@reduxjs/toolkit';
import { assign, filter, forEach } from 'lodash';
import { AbstractEntityDescriptions, EntityDescriptions } from 'src/entities/description';
import EntityDescription, { Projection } from 'src/entities/description/EntityDescription';
import { createEntity } from 'src/entities/factories';
import Pagination from 'src/entities/pagination/Pagination';
import { FAILURE, NOT_MODIFIED, REQUEST, SUCCESS_DELETE, SUCCESS_FETCH } from 'src/redux/actions';
import { entityCollectionDefaultState } from 'src/redux/selectors';
import { EntityCollectionState } from 'src/types';

/**
 * Reducer Actions such as REQUEST, FAILURE, etc.
 */
interface ReducerActions {
	REQUEST: string;
	SUCCESS_FETCH: string;
	SUCCESS_DELETE: string;
	NOT_MODIFIED: string;
	FAILURE: string;
}

export interface FetchedAction extends Action {
	entityDescription: EntityDescription;
	response: any;
	projection?: Projection;
	mergeItems?: boolean;
	error?: any;
}

/**
 * Merges the entity into the given array
 */
const mergeItem = (items: any[], entity: any, action: FetchedAction) => {
	const existingIndex = items.findIndex((item: any) => item.id === entity.id);

	if (existingIndex === -1) items.push(createEntity(entity, action.entityDescription.repository, action.projection));
	else items[existingIndex] = createEntity(entity, action.entityDescription.repository, action.projection);
};

/**
 * This generic reducer function processes all actions dependent on action types
 */
export function processReducer(
	state: EntityCollectionState<any> = entityCollectionDefaultState,
	action: FetchedAction,
	reducerActions: ReducerActions,
) {
	switch (action.type) {
		case reducerActions.REQUEST:
			return assign({}, state, { isFetching: true, error: undefined });
		case reducerActions.SUCCESS_FETCH: {
			const entities = getFetchedEntitiesFromResponse(action);
			let items: any[] = [];

			// receive collection of entities
			if (Array.isArray(entities)) {
				// merges the received items instead of replacing them
				if (action.mergeItems) {
					items = state.items.slice(0, state.items.length);
					entities.forEach((entity: any) => {
						mergeItem(items, entity, action);
					});

					// replace existing items with new ones
				} else {
					entities.forEach((entity: any) => {
						items.push(createEntity(entity, action.entityDescription.repository, action.projection));
					});
				}

				// merge single received entity into existing entities collection
			} else {
				items = state.items.slice(0, state.items.length);
				mergeItem(items, entities, action);
			}

			const pagination = new Pagination(action.response);

			return assign({}, state, { isFetching: false, items, pagination, error: undefined });
		}
		case reducerActions.NOT_MODIFIED: {
			return {
				error: undefined,
				isFetching: false,
				items: state.items,
			};
		}
		case reducerActions.SUCCESS_DELETE: {
			const items = state.items.slice(0, state.items.length);
			const existingIndex = items.findIndex((item: any) => item.id === action.response.id);

			if (existingIndex !== -1) items.splice(existingIndex, 1);

			return {
				error: undefined,
				isFetching: false,
				items,
			};
		}
		case reducerActions.FAILURE:
			return assign({}, state, { isFetching: false, error: action.error });
		default:
			return state;
	}
}

/**
 * Extracts the entities form the response and takes care of the received format
 */
function getFetchedEntitiesFromResponse(action: FetchedAction): any[] {
	const response = action.response;
	if (response != null) {
		if (response._embedded != null) return response._embedded[action.entityDescription.repository];
		if (Array.isArray(response)) return response;
		if (Array.isArray(response[action.entityDescription.repository]))
			return response[action.entityDescription.repository];
		if (response.content != null && (response.content.id || Array.isArray(response.content)))
			return response.content;
		return response;
	}

	return [];
}

/**
 * Creates reducers for all entities defined in {@link EntityDescriptions}
 */
export default function createReducers(): ReducersMapObject {
	const reducers: any = [];

	const childEntityDescriptions: EntityDescription[] = [];

	forEach(AbstractEntityDescriptions, (abstractEntityDescription: EntityDescription) => {
		if (abstractEntityDescription.children !== undefined) {
			// add child entity descriptions to array for later filtering
			childEntityDescriptions.push(...abstractEntityDescription.children);

			// create nested combined reducer for abstract entity description
			reducers[abstractEntityDescription.reducer] = combineReducers(
				createChildReducers(abstractEntityDescription.children),
			);

			// set parent entity descriptions
			abstractEntityDescription.children.forEach((child: EntityDescription) =>
				child.setParent(abstractEntityDescription),
			);
		}
	});

	// create reducers for flat entity descriptions
	filter(EntityDescriptions, entityDescription => !childEntityDescriptions.includes(entityDescription)).forEach(
		(value: EntityDescription) => {
			reducers[value.reducer] = createReducer(value.name);
		},
	);

	return reducers;
}

const createChildReducers = (children: EntityDescription[]): ReducersMapObject => {
	const reducers: any = [];

	children.forEach(child => {
		reducers[child.reducer] = createReducer(child.name);
	});

	return reducers;
};

const createReducer = (name: string) => {
	return (state: any = {}, action: FetchedAction) => {
		const actions = {
			REQUEST: `${name}_${REQUEST}`,
			SUCCESS_FETCH: `${name}_${SUCCESS_FETCH}`,
			SUCCESS_DELETE: `${name}_${SUCCESS_DELETE}`,
			NOT_MODIFIED: `${name}_${NOT_MODIFIED}`,
			FAILURE: `${name}_${FAILURE}`,
		};

		switch (action.type) {
			case actions.REQUEST:
			case actions.SUCCESS_FETCH:
			case actions.SUCCESS_DELETE:
			case actions.NOT_MODIFIED:
			case actions.FAILURE:
				const projection: string = action.projection ?? 'default';

				return {
					...state,
					[projection]: processReducer(state[projection], action, actions),
				};

			default:
				return state;
		}
	};
};
