import BasicType from 'src/entities/basic-types/BasicType';

export default class BundleContingent implements BasicType {
	total: number | null;
	external: number | null;
	bookableProducts: number;

	constructor(total: number | null, external: number | null, bookableProducts: number) {
		this.total = total;
		this.external = external;
		this.bookableProducts = bookableProducts;
	}

	static fromJson(json: any, bookableProducts: number): BundleContingent {
		return new BundleContingent(json.total, json.external, bookableProducts);
	}

	toString(): string {
		return `${this.total ?? '∞'} (${this.external ?? '∞'})`;
	}

	equals(other: BundleContingent): boolean {
		return this.total === other.total && this.external === other.external;
	}
}
