import Category from 'src/entities/category/Category';
import Document from 'src/entities/basic-types/Document';
import EventLocation from 'src/entities/event-location/EventLocation';
import Event from 'src/entities/event/Event';
import { fromJsonArray, fromJsonArrayWith } from 'src/helper/helper';
import { AutoFormFields } from 'src/entities/BaseEntity';

export type EventFullFormFields = AutoFormFields<EventFull> & {
	company?: string;
};

export default class EventFull extends Event {
	eventLocation: EventLocation;
	categories: Category[];
	readonly documents: Document[];

	constructor(json: any) {
		super(json);

		this.eventLocation = new EventLocation(json.eventLocation);
		this.categories = fromJsonArray(Category, json.categories);
		this.documents = fromJsonArrayWith(Document.fromJson, json.documents);
	}

	static formFields(entity?: EventFull): EventFullFormFields {
		return {
			...Event.formFields(entity),
			categories: entity?.categories.map(category => category.getLink()),
			eventLocation: entity?.eventLocation?.getLink(),
			documents: entity?.documents ?? [],
		};
	}
}
