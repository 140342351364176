import Message from 'src/entities/booking/progress/steps/insurance/Message';
import { fromJsonArray } from 'src/helper/helper';

export default class MessageHolder {
	error: boolean;
	info: boolean;
	message: Message[];

	constructor(json: any) {
		this.error = json.error;
		this.info = json.info;
		this.message = fromJsonArray(Message, json.message);
	}
}
