import { Action, UnknownAction } from '@reduxjs/toolkit';
import { History } from 'history';
import HttpStatus from 'http-status-codes';
import { SagaIterator } from 'redux-saga';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { LoginFormFields } from 'src/components/authentication/LoginForm';
import { AccountFull } from 'src/entities/accounts/AccountFull';
import Role from 'src/entities/accounts/Role';
import { AbstractEntityDescriptions } from 'src/entities/description';
import { resetStore } from 'src/redux/actions';
import {
	CHANGE_PASSWORD,
	CHANGE_USERNAME,
	CONFIRM_CHANGE_USERNAME,
	CONFIRM_REGISTER,
	failureOwnAccount,
	FETCH_OWN_ACCOUNT,
	fetchOwnAccount,
	FORGOT_PASSWORD,
	LOGIN,
	LOGOUT,
	PATCH_OWN_ACCOUNT,
	REGISTER,
	requestOwnAccount,
	RESET_PASSWORD,
	successFetchOwnAccount,
} from 'src/redux/actions/account';
import { setNotification } from 'src/redux/actions/system-notifications';
import { SESSION_STORAGE } from 'src/redux/persistors';
import { BaseAction } from 'src/redux/sagas';
import { getOwnAccount } from 'src/redux/selectors/account';
import { buildUrl, S_ABS_PATH } from 'src/routing';
import { ACCOUNT_ROUTES } from 'src/scenes/AccountRoutes';
import { BACKEND_ROOT, BACKEND_ROUTES } from 'src/scenes/BackendRoutes';
import { BOOKING_ROOT } from 'src/scenes/BookingRoutes';
import { LANDING_ROUTES } from 'src/scenes/LandingRoutes';
import {
	changePassword,
	confirmChangeUsername,
	confirmRegister,
	fetchAuthenticatedAccount,
	forgotPassword,
	login,
	logout,
	patchOwnAccount,
	patchOwnCustomerOrResellerAccount,
	register,
	requestChangeUsername,
	resetPassword,
} from 'src/services/account';
import { SingleEntityState, StatusType } from 'src/types';

/******************************************************************************/
/** ***************************** Subroutines **********************************/
/******************************************************************************/

export interface LoginAction extends Action {
	credentials: LoginFormFields;
	noRedirect?: boolean;
}

/**
 * Generates a saga that manages the login process
 */
function* loginSaga(action: LoginAction): SagaIterator {
	yield put(requestOwnAccount());

	const { error } = yield call(login, action.credentials);

	yield error ? put(failureOwnAccount(error)) : call(fetchAuthenticatedAccountSaga, action);
}

/**
 * Generates a saga that manages the logout process
 */
function* logoutSaga(action: UnknownAction): SagaIterator {
	const { error } = yield call(logout);
	const history: History = yield getContext('history');

	if (error) yield put(failureOwnAccount(error));
	else {
		// On successful logout we reset the ownAccount state and redirect
		yield put(resetStore());

		if (action.noRedirect === false || action.noRedirect === undefined)
			history.push(buildUrl(ACCOUNT_ROUTES.login, undefined, { logout: true }));
	}
}

interface UpdateDataAction extends BaseAction {
	data: any;
}

/**
 * Generates a saga that manages the register process
 */
function* registerSaga(action: UpdateDataAction): SagaIterator {
	const { error } = yield call(register, action.data);
	const history: History = yield getContext('history');

	if (error) yield put(failureOwnAccount(error));
	else history.push(buildUrl(ACCOUNT_ROUTES.register, undefined, { register_confirm: true }));
}

interface UpdateTokenAction extends BaseAction {
	token: string;
}

/**
 * Generates a saga that manages the register confirmation process
 */
function* confirmRegisterSaga(action: UpdateTokenAction): SagaIterator {
	const { error } = yield call(confirmRegister, action.token);
	const history: History = yield getContext('history');

	if (error) {
		if (error.status === HttpStatus.NOT_FOUND)
			history.replace(buildUrl(ACCOUNT_ROUTES.register, undefined, { status: 'already_confirmed' }));
		else history.replace(buildUrl(ACCOUNT_ROUTES.register, undefined, { status: 'invalid' }));
	} else history.replace(buildUrl(ACCOUNT_ROUTES.register, undefined, { status: 'success' }));
}

/**
 * Generates a saga that gets called after a successful login
 * This saga fetches the authenticated account and dispatches the appropriate account actions
 */
export function* fetchAuthenticatedAccountSaga(action: LoginAction): SagaIterator {
	yield put(requestOwnAccount());
	const { response, error } = yield call(fetchAuthenticatedAccount);

	if (error) {
		yield put(failureOwnAccount(error));
	} else {
		yield put(successFetchOwnAccount(response));

		// only redirect if fetch authenticated account got called after successful login
		if (action.type === LOGIN && (action.noRedirect === false || action.noRedirect === undefined)) {
			const account: SingleEntityState<AccountFull> = yield select(getOwnAccount);
			if (account.content) yield call(redirectAfterLogin, account.content);
		}
	}
}

/**
 * Generates a saga that gets called after an change password action.
 */
function* changePasswordSaga(action: UpdateDataAction): SagaIterator {
	const { error } = yield call(changePassword, action.data);
	const history: History = yield getContext('history');

	if (error) {
		if (error.status === HttpStatus.BAD_REQUEST)
			history.replace(buildUrl(ACCOUNT_ROUTES.manage.password, undefined, { status: 'wrong_password' }));
		else history.replace(buildUrl(ACCOUNT_ROUTES.manage.password, undefined, { status: 'unknown' }));
	} else history.replace(buildUrl(ACCOUNT_ROUTES.manage.password, undefined, { status: 'success' }));
}

/**
 * Generates a saga that gets called after an change password action.
 */
function* changeUsernameSaga(action: UpdateDataAction): SagaIterator {
	const { error } = yield call(requestChangeUsername, action.data);
	const history: History = yield getContext('history');

	if (error) history.replace(buildUrl(ACCOUNT_ROUTES.manage.username, undefined, { status: 'unknown' }));
	else history.replace(buildUrl(ACCOUNT_ROUTES.manage.username, undefined, { status: 'confirm' }));
}

/**
 * Generates a saga that manages the change username confirmation process
 */
function* confirmChangeUsernameSaga(action: UpdateTokenAction): SagaIterator {
	const { error } = yield call(confirmChangeUsername, action.token);
	const history: History = yield getContext('history');

	if (error) {
		if (error.status === HttpStatus.NOT_FOUND)
			history.replace(buildUrl(ACCOUNT_ROUTES.manage.username, undefined, { status: 'already_confirmed' }));
		else history.replace(buildUrl(ACCOUNT_ROUTES.manage.username, undefined, { status: 'invalid' }));
	} else {
		history.replace(buildUrl(ACCOUNT_ROUTES.manage.username, undefined, { status: 'success' }));
		yield put(fetchOwnAccount());
	}
}

/**
 * Generates a saga that gets called after a forgot password action.
 */
function* forgotPasswordSaga(action: UpdateDataAction): SagaIterator {
	const { error } = yield call(forgotPassword, action.data);
	const history: History = yield getContext('history');

	const { pathname, search, hash } = history.location;

	const searchParams = new URLSearchParams(search);

	if (error) {
		if (error.status === HttpStatus.NOT_FOUND) searchParams.set('status', 'wrong_username');
		else searchParams.set('status', 'unknown');
	} else searchParams.set('status', 'success');

	history.replace({
		pathname,
		hash,
		search: searchParams.toString(),
	});
}

interface ResetPasswordAction extends UpdateTokenAction {
	password: string;
}

/**
 * Generates a saga that gets called after a reset password action.
 */
function* resetPasswordSaga(action: ResetPasswordAction): SagaIterator {
	const { error } = yield call(resetPassword, action.token, action.password);
	const history: History = yield getContext('history');

	if (error) {
		if (error.status === HttpStatus.BAD_REQUEST)
			history.replace(buildUrl(ACCOUNT_ROUTES.reset, undefined, { status: 'wrong_token' }));
		else history.replace(buildUrl(ACCOUNT_ROUTES.reset, undefined, { status: 'unknown' }));
	} else history.replace(buildUrl(ACCOUNT_ROUTES.reset, undefined, { status: 'success' }));
}

/**
 * Redirect to path set in session storage, or depending on the logged in accounts {@link Role} if no path is set.
 */
function* redirectAfterLogin(account: AccountFull) {
	let redirectAfterLoginURL = sessionStorage.getItem(SESSION_STORAGE.REDIRECT_AFTER_LOGIN_URL);
	sessionStorage.setItem(SESSION_STORAGE.REDIRECT_AFTER_LOGIN_URL, '');
	const history: History = yield getContext('history');

	switch (account.role) {
		case Role.ADMIN:
			if (!redirectAfterLoginURL || redirectAfterLoginURL.includes(BOOKING_ROOT))
				redirectAfterLoginURL = buildUrl(BACKEND_ROUTES.administrations.companies);
			break;
		case Role.OPERATOR:
			if (!redirectAfterLoginURL || redirectAfterLoginURL.includes(BACKEND_ROUTES.administrations[S_ABS_PATH]))
				redirectAfterLoginURL = buildUrl(BACKEND_ROUTES.operations.events);
			break;
		case Role.CUSTOMER:
		case Role.RESELLER:
			if (!redirectAfterLoginURL || redirectAfterLoginURL.includes(BACKEND_ROOT))
				redirectAfterLoginURL = buildUrl(LANDING_ROUTES);
			break;
		default:
			console.error(`Unknown Role: ${account.role}`);
	}

	if (redirectAfterLoginURL) history.push(redirectAfterLoginURL);
}

interface PatchOwnAccountAction extends LoginAction {
	data: any;
}

/**
 * Generates a saga that patches the own account with the given data in the action props
 * and fetches the updated data after success
 */
function* patchOwnAccountSaga(action: PatchOwnAccountAction): SagaIterator {
	yield put(requestOwnAccount());

	const patchFunction =
		action.data.role === Role.CUSTOMER || action.data.role === Role.RESELLER
			? patchOwnCustomerOrResellerAccount
			: patchOwnAccount;

	const { error } = yield call(patchFunction, action.data);
	if (error) {
		yield put(failureOwnAccount(error));
		yield put(setNotification(StatusType.ERROR, error.status, error.method, AbstractEntityDescriptions.ACCOUNT));
	} else {
		yield call(fetchAuthenticatedAccountSaga, action);
	}
}

/******************************************************************************/
/** ***************************** WATCHERS *************************************/
/******************************************************************************/

/**
 * Login Watcher
 */
export function* generateLoginWatcher() {
	yield takeLatest(LOGIN, loginSaga);
}

/**
 * Logout Watcher
 */
export function* generateLogoutWatcher() {
	yield takeLatest(LOGOUT, logoutSaga);
}

/**
 * Registration Watcher
 */
export function* generateRegisterWatcher() {
	yield takeLatest(REGISTER, registerSaga);
}

/**
 * Registration Confirmation Watcher
 */
export function* generateConfirmRegisterWatcher() {
	yield takeLatest(CONFIRM_REGISTER, confirmRegisterSaga);
}

/**
 * Fetch own Account watcher
 */
export function* generateFetchOwnAccountWatcher() {
	yield takeLatest(FETCH_OWN_ACCOUNT, fetchAuthenticatedAccountSaga);
}

/**
 * Change Password Watcher
 */
export function* changePasswordWatcher() {
	yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
}

/**
 * Change Username Watcher
 */
export function* changeUsernameWatcher() {
	yield takeLatest(CHANGE_USERNAME, changeUsernameSaga);
}

/**
 * Confirm Change Username Watcher
 */
export function* confirmChangeUsernameWatcher() {
	yield takeLatest(CONFIRM_CHANGE_USERNAME, confirmChangeUsernameSaga);
}

/**
 * Forgot Password Watcher
 */
export function* forgotPasswordWatcher() {
	yield takeLatest(FORGOT_PASSWORD, forgotPasswordSaga);
}

/**
 * Reset Password Watcher
 */
export function* resetPasswordWatcher() {
	yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
}

/**
 * Patch Own Account Watcher
 */
export function* patchOwnAccountWatcher() {
	yield takeLatest(PATCH_OWN_ACCOUNT, patchOwnAccountSaga);
}
