import { UnknownAction } from '@reduxjs/toolkit';
import { Theme } from 'src/entities/integration/Integration';
import { RESTORE_PREVIOUS_THEME, SET_THEME, SWITCH_THEME } from 'src/redux/actions/theme';
import { ThemeState } from 'src/types';

/**
 * Reducer that manges the current active tab index of a semantic ui Tab
 */
function themeReducer(state: ThemeState | null = null, action: UnknownAction) {
	switch (action.type) {
		case SET_THEME:
			return { currentTheme: action.theme };
		case SWITCH_THEME: {
			// Prevent switching to the same theme as currently active
			if (state!.currentTheme === action.theme) return state;

			return { currentTheme: action.theme, previousTheme: state!.currentTheme };
		}
		case RESTORE_PREVIOUS_THEME:
			// Only restore theme if there is a previous theme
			if (state!.previousTheme == null) return state;
			// If a theme has been provided, check if the current theme is the same then restore the previous theme
			if (action.theme) {
				if (action.theme === state!.currentTheme)
					return { currentTheme: state!.previousTheme, previousTheme: state!.currentTheme };
				return state;
			}
			return { currentTheme: state!.previousTheme, previousTheme: state!.currentTheme };
		default:
			return state ?? { currentTheme: Theme.DEFAULT };
	}
}

export default themeReducer;
