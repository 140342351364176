import { History } from 'history';
import { SagaIterator } from 'redux-saga';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { EntityDescriptions } from 'src/entities/description';
import { Projection } from 'src/entities/description/EntityDescription';
import { PaymentMethod } from 'src/entities/payment/Gateway';
import {
	ACCEPT_PENDING_BOOKING,
	DELETE_PENDING_BOOKING,
	GENERATE_MARGINAL_TAX_INVOICE,
	GENERATE_REGULAR_TAX_INVOICE,
	REPAY_BOOKING,
} from 'src/redux/actions/booking';
import { BaseAction, FetchAction, fetchSaga } from 'src/redux/sagas/index';
import { buildUrl } from 'src/routing';
import { BACKEND_ROUTES } from 'src/scenes/BackendRoutes';
import {
	acceptPendingBooking,
	deletePendingBooking,
	generateMarginalTaxInvoice,
	generateRegularTaxInvoice,
	repayBooking,
} from 'src/services/booking';

interface BookingAction extends BaseAction {
	bookingId: number;
	bookingItemTaxes: any;
}

function* marginalTaxInvoiceSaga(action: BookingAction): SagaIterator {
	yield put(EntityDescriptions.BOOKING.action.request(Projection.DETAILS));
	if (!action.bookingId) return console.error('No bookingId provided for marginalTaxInvoiceSaga');

	const { error, response } = yield call(generateMarginalTaxInvoice, action.bookingId);

	yield error
		? put(EntityDescriptions.BOOKING.action.failure(error, Projection.DETAILS))
		: put(EntityDescriptions.BOOKING.action.successFetch(response, Projection.DETAILS));
}

function* regularTaxInvoiceSaga(action: BookingAction): SagaIterator {
	yield put(EntityDescriptions.BOOKING.action.request(Projection.DETAILS));
	if (!action.bookingId) return console.error('No bookingId provided for regularTaxInvoiceSaga');

	const { error, response } = yield call(generateRegularTaxInvoice, action.bookingId, action.bookingItemTaxes.items);

	yield error
		? put(EntityDescriptions.BOOKING.action.failure(error, Projection.DETAILS))
		: put(EntityDescriptions.BOOKING.action.successFetch(response, Projection.DETAILS));
}

/**
 * Generates a delete pending booking saga
 */
function* deletePendingBookingSaga(action: BookingAction): SagaIterator {
	yield put(EntityDescriptions.BOOKING.action.request(action.projection));
	const history: History = yield getContext('history');

	if (!action.id) return console.error('No id provided for acceptPendingBookingSaga');

	const { error, response } = yield call(deletePendingBooking, action.id);

	if (error) {
		yield put(EntityDescriptions.BOOKING.action.failure(error, action.projection));
	} else {
		yield put(EntityDescriptions.BOOKING.action.successDelete({ id: action.id }, action.projection));
		history.replace(buildUrl(BACKEND_ROUTES.operations.overviews.bookings));
	}
}

/**
 * Generates a accept pending booking saga
 */
function* acceptPendingBookingSaga(action: BookingAction): SagaIterator {
	yield put(EntityDescriptions.BOOKING.action.request(action.projection));
	if (!action.id) return console.error('No id provided for acceptPendingBookingSaga');

	const { error, response } = yield call(acceptPendingBooking, action.id);

	yield error
		? put(EntityDescriptions.BOOKING.action.failure(error, action.projection))
		: call(fetchSaga, EntityDescriptions.BOOKING, action);
}

interface RepayBookingAction extends FetchAction {
	token: string;
	paymentMethod: PaymentMethod;
}

function* repayBookingSaga(action: RepayBookingAction): SagaIterator {
	yield put(EntityDescriptions.BOOKING.action.request(action.projection));
	if (!action.token) return console.error('No token provided for repayBookingSaga');

	const { error, response } = yield call(repayBooking, action.token, action.paymentMethod);

	if (error) {
		yield put(EntityDescriptions.BOOKING.action.failure(error, action.projection));
	}
	// Online payment
	else if (response.paymentRedirectUrl != null) {
		// Redirect to online payment site
		if (window.parentIFrame) {
			window.parentIFrame.sendMessage({ replaceMainPageUrl: response.paymentRedirectUrl });
		} else {
			window.location.replace(response.paymentRedirectUrl);
		}
	}
	// Payment on invoice
	else {
		// TODO: google analytics
		// const bookingProgress = yield select<StoreState, SingleEntityState<BookingProgress>>(getBookingProgress);
		// const bookingProgressContent = bookingProgress.content as BookingProgress;
		//
		// window.dataLayer.push({
		//     event: 'purchase',
		//     transactionId: response.token,
		//     transactionAffiliation: bookingProgressContent.getFirstRow().bundle.company.name,
		//     transactionTotal: bookingProgressContent.cheapestPrice.amount.toNumber(),
		// });

		action.searchMethod = `findBookingByToken?token=${action.token}`;
		action.publicEndpoint = true;
		yield call(fetchSaga, EntityDescriptions.BOOKING, action);
	}
}

export function* generateMarginalTaxInvoiceWatcher() {
	yield takeLatest(GENERATE_MARGINAL_TAX_INVOICE, marginalTaxInvoiceSaga);
}

export function* generateRegularTaxInvoiceWatcher() {
	yield takeLatest(GENERATE_REGULAR_TAX_INVOICE, regularTaxInvoiceSaga);
}

export function* generateDeletePendingBookingWatcher() {
	yield takeLatest(DELETE_PENDING_BOOKING, deletePendingBookingSaga);
}

export function* generateAcceptPendingBookingWatcher() {
	yield takeLatest(ACCEPT_PENDING_BOOKING, acceptPendingBookingSaga);
}

export function* generateRepayBookingWatcher() {
	yield takeLatest(REPAY_BOOKING, repayBookingSaga);
}
