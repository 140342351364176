import React from 'react';
import { InputProps, SemanticShorthandItem } from 'semantic-ui-react';

/**
 * General Form Validation Props for customizing validation appearance
 */
export interface ValidationLabelProps {
	label: React.ReactNode;
	isError: boolean;
	icon?: any | SemanticShorthandItem<InputProps>;
}

export const getValidationLabelProps = (meta: any, label: string): ValidationLabelProps => {
	const { dirty, touched, error, warning } = meta;

	const isError = (dirty || touched) && error !== undefined;
	const isWarn = (dirty || touched) && warning !== undefined;

	const errorSpan = <span className="text red">({error})</span>;
	const warnSpan = <span className="text yellow">({warning})</span>;
	const icon = isError ? 'exclamation triangle' : isWarn ? 'exclamation circle' : undefined;

	const labelSpan = (
		<label>
			{label} {isError && errorSpan}
			{isWarn && warnSpan}
		</label>
	);

	return {
		label: labelSpan,
		isError,
		icon,
	};
};
