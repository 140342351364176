import moment from 'moment-timezone';
import 'node_modules/react-datepicker/dist/react-datepicker.css';
import { FC, FocusEvent } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import 'src/components/elements/datetime/DateTimePicker.css';
import { intoMoment } from './utils';

interface DateTimePickerProps {
	value: moment.Moment | null;
	onChange?: (date: moment.Moment | null) => void;
	onBlur?: (event?: FocusEvent) => void;
	disabled?: boolean;
	isClearable?: boolean;
	minDateTime?: moment.Moment;
	maxDateTime?: moment.Moment;
	placeholder?: string;
}

export const DateTimePicker: FC<DateTimePickerProps> = ({
	value,
	isClearable,
	disabled,
	placeholder,
	onChange,
	onBlur,
}) => {
	const { t } = useTranslation();
	return (
		<ReactDatePicker
			selected={value?.toDate()}
			showTimeSelect
			timeFormat="p"
			dateFormat="Pp"
			timeIntervals={15}
			placeholderText={placeholder}
			preventOpenOnFocus
			onChange={(date: Date) => onChange?.(intoMoment(date))}
			onCalendarClose={() => onBlur?.()}
			disabled={disabled}
			popperProps={{
				strategy: 'fixed',
			}}
			timeCaption={t('datetime_picker.time')}
			disabledKeyboardNavigation={true}
			isClearable={isClearable}
		/>
	);
};
