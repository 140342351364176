import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import Document from 'src/entities/basic-types/Document';
import Location from 'src/entities/basic-types/Location';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';

export type HotelFormFields = Omit<AutoFormFields<Hotel>, 'csBusHotelId'>;

export default class Hotel extends BaseEntity {
	name: string;
	description: string;
	logo?: Document;
	location: Location;
	stars?: number;
	readonly csBusHotelId?: string;

	constructor(json: any) {
		super(json);
		this.name = json.name;
		this.description = json.description;
		this.logo = Document.fromJson(json.logo);
		this.location = Location.fromJson(json.location)!;
		this.stars = json.stars;
		this.csBusHotelId = json.csBusHotelId;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.HOTEL;
	}

	static formFields(entity?: Hotel): HotelFormFields {
		return {
			...BaseEntity.formFields(entity),
			name: entity?.name,
			description: entity?.description,
			location: entity?.location,
			stars: entity?.stars,
			logo: entity?.logo,
		};
	}
}
