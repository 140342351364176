import Company from 'src/entities/companies/Company';
import CompanyInvoice from 'src/entities/invoice/company/CompanyInvoice';

export default class CompanyInvoiceFull extends CompanyInvoice {
	company: Company;

	constructor(json: any) {
		super(json);
		this.company = new Company(json.company);
	}
}
