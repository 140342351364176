import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Divider } from 'semantic-ui-react';

interface CountdownProps {
	body?: ReactNode;
	initialTime?: number;
	timeoutCallback?: () => void;
}

export const Countdown: FC<CountdownProps> = ({ body, initialTime = 10, timeoutCallback }) => {
	const [time, setTime] = useState(initialTime);
	const timer = useRef(setInterval(() => setTime(time - 1), 1000));

	useEffect(() => {
		if (time <= 1) {
			clearInterval(timer.current);
			if (timeoutCallback) timeoutCallback();
		}
		return () => clearInterval(timer.current);
	}, [time, timeoutCallback]);

	return (
		<>
			{body}
			<Divider hidden />
			<h2 style={{ textAlign: 'center' }}>{time}</h2>
			<Divider hidden />
		</>
	);
};
