import BaseEntity from 'src/entities/BaseEntity';
import EntityDescription from 'src/entities/description/EntityDescription';
import { EntityDescriptions } from 'src/entities/description';
import Money from 'src/entities/basic-types/Money';
import { CouponType } from 'src/entities/coupon/Coupon';

export default class CouponBooking extends BaseEntity {
	code: string;
	type: CouponType;
	discount: number | Money;
	relativeDiscount: number;
	absoluteDiscount: Money;

	constructor(json: any) {
		super(json);
		this.code = json.code;
		this.type = json.type;

		if (json.type === CouponType.RELATIVE) {
			this.relativeDiscount = json.relativeDiscount;
			this.discount = json.relativeDiscount;
		} else {
			this.absoluteDiscount = Money.fromJson(json.absoluteDiscount);
			this.discount = Money.fromJson(json.absoluteDiscount);
		}
	}

	toString(): string {
		return this.code;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.COUPON;
	}
}
