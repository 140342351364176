export enum PaginationItemType {
	FIRST = 'firstItem',
	PREV = 'prevItem',
	NEXT = 'nextItem',
	LAST = 'lastItem',
	PAGE = 'pageItem',
}

export default class Pagination {
	number: number;
	size: number;
	totalElements: number;
	totalPages: number;

	constructor(json: any) {
		this.number = json.number || 0;
		this.size = json.size || 0;
		this.totalElements = json.totalElements || 0;
		this.totalPages = json.totalPages || 0;
	}
}
