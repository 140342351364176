import { camelCase } from 'lodash';

/**
 * The value for the NONE variant is required for the reducer selection in index.ts
 * The other values are expected to be the projection string as required by the API (coincides with reducer selection)
 */
export enum Projection {
	NONE = 'default',
	ID = 'id',
	FULL = 'full',
	DETAILS = 'details',
	BOOKING = 'booking',
	BOOKING_DETAILS = 'booking-details',
	SEARCH = 'search',
	COMPANY = 'company',
	ID_NAME = 'id-name',
}

export enum CollectionFetchStrategy {
	FIND_ALL = 'FIND_ALL',
	FIND_BY_COMPANY = 'FIND_BY_COMPANY',
}

interface Instantiable {
	new (...args: any[]): any;
}

/**
 * Holding detailed description of an Entity.
 *
 * The projectionsTypeMap holds key value pairs of supported projections set in bootstrapProjections.
 *
 * Optional parent will be populated in createReducers if available.
 */
export default class EntityDescription {
	name: string;
	repository: string;
	reducer: string;
	action: any;
	collectionFetchStrategy: CollectionFetchStrategy;
	projectionsTypeMap: Record<string, Instantiable>;
	parent?: EntityDescription;
	children?: EntityDescription[];

	constructor(
		name: string,
		repository: string,
		collectionFetchStrategy: CollectionFetchStrategy = CollectionFetchStrategy.FIND_BY_COMPANY,
		children: EntityDescription[] = [],
	) {
		this.name = name;
		this.repository = repository;
		this.reducer = camelCase(repository);
		this.collectionFetchStrategy = collectionFetchStrategy;
		this.projectionsTypeMap = {};
		this.children = children;
	}

	setParent(parent: EntityDescription) {
		this.parent = parent;
	}
}
