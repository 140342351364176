import { ENV } from 'src/config/env';
import SearchParams from 'src/entities/booking/SearchParams';
import callApi from 'src/services/api';
import URI from 'urijs';

export const fetchFilterEntities = (searchParams: SearchParams) => {
	const url = URI(ENV.publicUrl).segment('filter-entities').addSearch(searchParams).toString();

	return callApi(url);
};
