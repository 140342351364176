import { action } from 'src/redux/actions/index';
import SearchParams from 'src/entities/booking/SearchParams';

export const FETCH_FILTER_ENTITIES = 'FETCH_FILTER_ENTITIES';
export const REQUEST_FILTER_ENTITIES = 'REQUEST_FILTER_ENTITIES';
export const SUCCESS_FETCH_FILTER_ENTITIES = 'SUCCESS_FETCH_FILTER_ENTITIES';
export const FAILURE_FILTER_ENTITIES = 'FAILURE_FILTER_ENTITIES';

export const fetchFilterEntities = (searchParams: SearchParams) => action(FETCH_FILTER_ENTITIES, { searchParams });

export const requestFilterEntities = () => action(REQUEST_FILTER_ENTITIES);
export const successFetchFilterEntities = (response: any) => action(SUCCESS_FETCH_FILTER_ENTITIES, { response });
export const failureFilterEntities = (error: any) => action(FAILURE_FILTER_ENTITIES, { error });
