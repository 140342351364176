import { BookingProgressStep, BookingStep } from 'src/entities/booking/progress/steps/BookingProgressStep';
import ContactDataStep from 'src/entities/booking/progress/steps/ContactDataStep';
import InsuranceStep from 'src/entities/booking/progress/steps/InsuranceStep';
import PassengerDataStep from 'src/entities/booking/progress/steps/PassengerDataStep';
import PaxStep from 'src/entities/booking/progress/steps/PaxStep';
import ProductStep from 'src/entities/booking/progress/steps/ProductStep';
import SummaryStep from 'src/entities/booking/progress/steps/SummaryStep';

export const bookingProgressStepFromJson = (json: any): BookingProgressStep => {
	switch (json.name) {
		case BookingStep.PAX:
			return new PaxStep(json);
		case BookingStep.TICKET:
		case BookingStep.JOURNEY:
		case BookingStep.ROOM:
		case BookingStep.GENERAL_PRODUCTS:
			return new ProductStep(json);
		case BookingStep.CONTACT_DATA:
			return new ContactDataStep(json);
		case BookingStep.PASSENGER_DATA:
			return new PassengerDataStep(json);
		case BookingStep.INSURANCE:
			return new InsuranceStep(json);
		case BookingStep.SUMMARY:
			return new SummaryStep(json);
		default:
			throw new Error(`Error creating ProductStep! Unknown booking step = ${json.name}`);
	}
};
