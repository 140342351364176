import { useMapsLibrary } from '@vis.gl/react-google-maps';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { ENV } from 'src/config/env';
import Location from 'src/entities/basic-types/Location';
import callApi, { CallApiResult, HttpMethod } from 'src/services/api';

/**
 * Use recommended "weekly" channel.
 *
 * https://developers.google.com/maps/documentation/javascript/versions
 */
export const GOOGLE_API_VERSION = 'weekly';

export function useGeolocation(active: boolean = false) {
	const geocoding = useMapsLibrary('geocoding');

	const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
	const [position, setPosition] = useState<GeolocationPosition | null>(null);
	const [loading, setLoading] = useState(false);
	const [results, setResults] = useState<google.maps.GeocoderResult[]>([]);

	useEffect(() => {
		const geo = navigator.geolocation;

		if (!geocoding || !active || !geo) return;

		setGeocoder(new geocoding.Geocoder());
		setLoading(true);

		geo.getCurrentPosition(
			position => {
				setPosition(position);
				setLoading(false);
			},
			() => setLoading(false),
		);
	}, [geocoding]);

	useEffect(() => {
		if (!geocoder || !position) return;

		setLoading(true);

		geocoder.geocode(
			{
				location: new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
			},
			(results, status) => {
				if (status === 'OK') setResults(results ?? []);
				else setResults([]);

				setLoading(false);
			},
		);
	}, [geocoder, position]);

	return {
		position,
		results,
		loading,
	};
}

export function useAutoComplete(attributionRef: React.RefObject<HTMLDivElement>) {
	const places = useMapsLibrary('places');

	const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();
	const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
	const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);

	const [loading, setLoading] = useState(false);
	const [predictionResults, setPredictionResults] = useState<google.maps.places.AutocompletePrediction[]>([]);

	useEffect(() => {
		if (!places || !attributionRef.current) return;

		setAutocompleteService(new places.AutocompleteService());
		setPlacesService(new places.PlacesService(attributionRef.current!));
		setSessionToken(new places.AutocompleteSessionToken());

		return () => setAutocompleteService(null);
	}, [places]);

	const fetchPredictions = useCallback(
		async (input: string) => {
			console.log(autocompleteService, input);
			if (!autocompleteService || input.length < 0) {
				setPredictionResults([]);
				return;
			}

			setLoading(true);

			const request = { input, sessionToken };
			const response = await autocompleteService.getPlacePredictions(request);

			setPredictionResults(response.predictions);
			setLoading(false);
		},
		[autocompleteService, sessionToken],
	);

	const fetchDetails = useCallback(
		(placeId: string, cb: (details: google.maps.places.PlaceResult | null) => void) => {
			if (!places) return;

			const detailRequestOptions = {
				placeId,
				fields: ['geometry', 'name', 'formatted_address', 'address_components'],
				sessionToken,
			};

			setLoading(true);
			placesService?.getDetails(detailRequestOptions, details => {
				cb(details);
				setPredictionResults([]);
				setSessionToken(new places.AutocompleteSessionToken());
				setLoading(false);
			});
		},
		[places, sessionToken, placesService],
	);

	return {
		loading,
		fetchPredictions,
		predictionResults,
		fetchDetails,
	};
}

/**
 * Request timezone via google timezone api for the given location and datetime.
 */
export const googleTimeZone = (location: Location, datetime: moment.Moment): Promise<string> => {
	return new Promise((resolve: any, reject: any) => {
		callApi(
			`https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat},${
				location.lng
			}&timestamp=${datetime.unix()}&key=${ENV.googleApiKey}&v=${GOOGLE_API_VERSION}`,
			HttpMethod.POST,
			undefined,
			undefined,
			true,
		).then((result: CallApiResult) => {
			const { response, error } = result;

			if (response?.timeZoneId) resolve(response.timeZoneId);
			else reject(error);
		});
	});
};
