import { filter, find, isEmpty } from 'lodash';
import EntityDescription, { Projection } from 'src/entities/description/EntityDescription';
import { EntityCollectionState, SingleEntityState, StoreState } from 'src/types';

/**
 * The default state for an entity collection
 */
export const entityCollectionDefaultState: EntityCollectionState<any> = {
	isFetching: false,
	error: undefined,
	items: [],
};

export const singleEntityDefaultState: SingleEntityState<any> = {
	isFetching: false,
	error: undefined,
	content: undefined,
};

/**
 * Returns an object containing the default state for a single entity
 */
export function singleEntityDefaultStateWithContent(content?: any): SingleEntityState<any> {
	if (content) {
		return {
			isFetching: false,
			error: undefined,
			content,
		};
	}

	return singleEntityDefaultState;
}

/* ------------------------------------*\
 * Single Entity Selectors
\*------------------------------------*/

/**
 * Selects a single entity by id from the redux store state
 */
export function getEntityById<R = any>(
	state: StoreState,
	entityDescription: EntityDescription,
	id: number,
	projection: Projection = Projection.NONE,
): SingleEntityState<R> {
	let entities;

	if (entityDescription.parent) {
		if (isEmpty(state.entities[entityDescription.parent.reducer][entityDescription.reducer]))
			return singleEntityDefaultState;

		entities = state.entities[entityDescription.parent.reducer][entityDescription.reducer][projection];
	} else {
		if (isEmpty(state.entities[entityDescription.reducer])) return singleEntityDefaultState;

		entities = state.entities[entityDescription.reducer][projection];
	}

	if (isEmpty(entities)) return singleEntityDefaultState;

	return {
		isFetching: entities.isFetching,
		error: entities.error,
		content: find(entities.items, entity => entity.id === id),
	};
}

/**
 * Selects a single entity by the given field name and value
 */
export function getEntityByFieldName<R = any>(
	state: StoreState,
	entityDescription: EntityDescription,
	fieldName: string,
	value: number | string,
	projection: Projection = Projection.FULL,
	withoutWww: boolean = false,
): SingleEntityState<R> {
	if (isEmpty(state.entities[entityDescription.reducer])) return singleEntityDefaultState;

	const entities = state.entities[entityDescription.reducer][projection];

	if (isEmpty(entities)) return singleEntityDefaultState;

	return {
		isFetching: entities.isFetching,
		error: entities.error,
		content: find(
			entities.items,
			entity =>
				entity[fieldName] &&
				(entity[fieldName] === value || (withoutWww && entity[fieldName].replace('www.', '') === value)),
		),
	};
}

/* ------------------------------------*\
 * Entity Collection Selectors
\*------------------------------------*/

/**
 * Selects all information of the desired entity from the redux store state
 */
export function getEntities<R = any>(
	state: StoreState,
	entityDescription: EntityDescription,
	projection: Projection = Projection.NONE,
): EntityCollectionState<R> {
	if (entityDescription.parent) {
		if (isEmpty(state.entities[entityDescription.parent.reducer][entityDescription.reducer]))
			return entityCollectionDefaultState;

		return state.entities[entityDescription.parent.reducer][entityDescription.reducer][projection];
	}
	if (isEmpty(state.entities[entityDescription.reducer])) return entityCollectionDefaultState;

	return state.entities[entityDescription.reducer][projection] ?? entityCollectionDefaultState;
}

/**
 * Selects all entities where the given id matches the idField for a specific EntityDescription.
 * For example:
 *      entityDescription = EntityDescriptions.BUNDLE_ITEM
 *      id = 1
 *      idField = 'product'
 *
 * This call would select all bundle items where the product id = 1
 */
export function getEntitiesByIdField<R = any>(
	state: StoreState,
	entityDescription: EntityDescription,
	idField: string,
	id: number,
	projection: Projection = Projection.FULL,
): EntityCollectionState<R> {
	if (isEmpty(state.entities[entityDescription.reducer])) return entityCollectionDefaultState;

	const entities = state.entities[entityDescription.reducer][projection];

	if (isEmpty(entities)) return entityCollectionDefaultState;

	return {
		isFetching: entities.isFetching,
		error: entities.error,
		items: filter(entities.items, entity => entity[idField] && entity[idField].id === id),
	};
}
