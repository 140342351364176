import Money from 'src/entities/basic-types/Money';
import BundleItem from 'src/entities/bundle/BundleItem';
import { productFullFromJson } from 'src/entities/factories/product-factory';
import { ProductFull, ProductAllProperties } from 'src/entities/products/ProductFull';
import Stop from 'src/entities/products/stop/Stop';

export default class BundleItemBooking extends BundleItem {
	readonly price: Money;
	product: ProductFull; // Products are not really Full Projections but Booking Projections but they are the same...
	stop?: Stop;

	constructor(json: any) {
		super(json);

		this.price = Money.fromJson(json.price);
		this.product = productFullFromJson(json.product);
		this.stop = json.stop != null ? new Stop(json.stop) : undefined;
	}

	getProductAllProperties() {
		return this.product as ProductAllProperties;
	}
}
