import Admin from 'src/entities/accounts/Admin';
import AdminFull from 'src/entities/accounts/AdminFull';
import Customer from 'src/entities/accounts/Customer';
import CustomerFull from 'src/entities/accounts/CustomerFull';
import Operator from 'src/entities/accounts/Operator';
import OperatorFull from 'src/entities/accounts/OperatorFull';
import Reseller from 'src/entities/accounts/Reseller';
import ResellerFull from 'src/entities/accounts/ResellerFull';
import Booking from 'src/entities/booking/Booking';
import BookingDetails from 'src/entities/booking/BookingDetails';
import BookingFull from 'src/entities/booking/BookingFull';
import BookingItem from 'src/entities/booking/BookingItem';
import BookingRow from 'src/entities/booking/BookingRow';
import Passenger from 'src/entities/booking/Passenger';
import Bundle from 'src/entities/bundle/Bundle';
import BundleBooking from 'src/entities/bundle/BundleBooking';
import BundleBookingDetails from 'src/entities/bundle/BundleBookingDetails';
import BundleCompany from 'src/entities/bundle/BundleCompany';
import BundleDetails from 'src/entities/bundle/BundleDetails';
import BundleFull from 'src/entities/bundle/BundleFull';
import BundleItem from 'src/entities/bundle/BundleItem';
import BundleItemFull from 'src/entities/bundle/BundleItemFull';
import Category from 'src/entities/category/Category';
import CategoryFull from 'src/entities/category/CategoryFull';
import Company from 'src/entities/companies/Company';
import CompanyFull from 'src/entities/companies/CompanyFull';
import CompanySearch from 'src/entities/companies/CompanySearch';
import Coupon from 'src/entities/coupon/Coupon';
import CouponBooking from 'src/entities/coupon/CouponBooking';
import CouponFull from 'src/entities/coupon/CouponFull';
import CustomerData from 'src/entities/customer-data/CustomerData';
import CustomerDataFull from 'src/entities/customer-data/CustomerDataFull';
import { EntityDescriptions } from 'src/entities/description';
import { Projection } from 'src/entities/description/EntityDescription';
import EventLocation from 'src/entities/event-location/EventLocation';
import EventLocationFull from 'src/entities/event-location/EventLocationFull';
import Event from 'src/entities/event/Event';
import EventBooking from 'src/entities/event/EventBooking';
import EventBookingDetails from 'src/entities/event/EventBookingDetails';
import EventDetails from 'src/entities/event/EventDetails';
import EventFull from 'src/entities/event/EventFull';
import EventSearch from 'src/entities/event/EventSearch';
import Feature from 'src/entities/feature/Feature';
import Insurance from 'src/entities/insurance/Insurance';
import Integration from 'src/entities/integration/Integration';
import IntegrationBooking from 'src/entities/integration/IntegrationBooking';
import IntegrationFull from 'src/entities/integration/IntegrationFull';
import AdminInvoice from 'src/entities/invoice/admin/AdminInvoice';
import AdminInvoiceFull from 'src/entities/invoice/admin/AdminInvoiceFull';
import CompanyInvoice from 'src/entities/invoice/company/CompanyInvoice';
import CompanyInvoiceFull from 'src/entities/invoice/company/CompanyInvoiceFull';
import CustomerInvoice from 'src/entities/invoice/customer/CustomerInvoice';
import CustomerInvoiceFull from 'src/entities/invoice/customer/CustomerInvoiceFull';
import Page from 'src/entities/page/Page';
import GeneralProduct from 'src/entities/products/GeneralProduct';
import GeneralProductFull from 'src/entities/products/GeneralProductFull';
import Journey from 'src/entities/products/Journey';
import JourneyFull from 'src/entities/products/JourneyFull';
import Room from 'src/entities/products/Room';
import RoomFull from 'src/entities/products/RoomFull';
import Ticket from 'src/entities/products/Ticket';
import TicketFull from 'src/entities/products/TicketFull';
import Hotel from 'src/entities/products/hotel/Hotel';
import HotelFull from 'src/entities/products/hotel/HotelFull';
import Stop from 'src/entities/products/stop/Stop';
import StopFull from 'src/entities/products/stop/StopFull';
import StopGroup from 'src/entities/products/stop/StopGroup';
import ReviewCategory from 'src/entities/review-category/ReviewCategory';
import ReviewFull from 'src/entities/review/ReviewFull';
import Tag from 'src/entities/tag/Tag';
import { objectTypedKeys } from 'src/helper/helper';

export function bootstrapProjections() {
	EntityDescriptions.ADMIN.projectionsTypeMap[Projection.NONE] = Admin;
	EntityDescriptions.ADMIN.projectionsTypeMap[Projection.FULL] = AdminFull;

	EntityDescriptions.ADMIN_INVOICE.projectionsTypeMap[Projection.NONE] = AdminInvoice;
	EntityDescriptions.ADMIN_INVOICE.projectionsTypeMap[Projection.FULL] = AdminInvoiceFull;

	EntityDescriptions.BOOKING.projectionsTypeMap[Projection.NONE] = Booking;
	EntityDescriptions.BOOKING.projectionsTypeMap[Projection.FULL] = BookingFull;
	EntityDescriptions.BOOKING.projectionsTypeMap[Projection.DETAILS] = BookingDetails;

	EntityDescriptions.BOOKING_ITEM.projectionsTypeMap[Projection.NONE] = BookingItem;

	EntityDescriptions.BOOKING_ROW.projectionsTypeMap[Projection.NONE] = BookingRow;

	EntityDescriptions.BUNDLE.projectionsTypeMap[Projection.NONE] = Bundle;
	EntityDescriptions.BUNDLE.projectionsTypeMap[Projection.BOOKING] = BundleBooking;
	EntityDescriptions.BUNDLE.projectionsTypeMap[Projection.BOOKING_DETAILS] = BundleBookingDetails;
	EntityDescriptions.BUNDLE.projectionsTypeMap[Projection.DETAILS] = BundleDetails;
	EntityDescriptions.BUNDLE.projectionsTypeMap[Projection.FULL] = BundleFull;
	EntityDescriptions.BUNDLE.projectionsTypeMap[Projection.COMPANY] = BundleCompany;

	EntityDescriptions.BUNDLE_ITEM.projectionsTypeMap[Projection.NONE] = BundleItem;
	EntityDescriptions.BUNDLE_ITEM.projectionsTypeMap[Projection.FULL] = BundleItemFull;

	EntityDescriptions.CATEGORY.projectionsTypeMap[Projection.NONE] = Category;
	EntityDescriptions.CATEGORY.projectionsTypeMap[Projection.FULL] = CategoryFull;

	EntityDescriptions.COMPANY.projectionsTypeMap[Projection.NONE] = Company;
	EntityDescriptions.COMPANY.projectionsTypeMap[Projection.SEARCH] = CompanySearch;
	EntityDescriptions.COMPANY.projectionsTypeMap[Projection.FULL] = CompanyFull;

	EntityDescriptions.COUPON.projectionsTypeMap[Projection.NONE] = Coupon;
	EntityDescriptions.COUPON.projectionsTypeMap[Projection.BOOKING] = CouponBooking;
	EntityDescriptions.COUPON.projectionsTypeMap[Projection.FULL] = CouponFull;

	EntityDescriptions.CUSTOMER.projectionsTypeMap[Projection.NONE] = Customer;
	EntityDescriptions.CUSTOMER.projectionsTypeMap[Projection.FULL] = CustomerFull;

	EntityDescriptions.CUSTOMER_DATA.projectionsTypeMap[Projection.NONE] = CustomerData;
	EntityDescriptions.CUSTOMER_DATA.projectionsTypeMap[Projection.FULL] = CustomerDataFull;

	EntityDescriptions.CUSTOMER_INVOICE.projectionsTypeMap[Projection.NONE] = CustomerInvoice;
	EntityDescriptions.CUSTOMER_INVOICE.projectionsTypeMap[Projection.FULL] = CustomerInvoiceFull;
	EntityDescriptions.COMPANY_INVOICE.projectionsTypeMap[Projection.NONE] = CompanyInvoice;
	EntityDescriptions.COMPANY_INVOICE.projectionsTypeMap[Projection.FULL] = CompanyInvoiceFull;

	EntityDescriptions.EVENT.projectionsTypeMap[Projection.NONE] = Event;
	EntityDescriptions.EVENT.projectionsTypeMap[Projection.BOOKING] = EventBooking;
	EntityDescriptions.EVENT.projectionsTypeMap[Projection.BOOKING_DETAILS] = EventBookingDetails;
	EntityDescriptions.EVENT.projectionsTypeMap[Projection.DETAILS] = EventDetails;
	EntityDescriptions.EVENT.projectionsTypeMap[Projection.FULL] = EventFull;
	EntityDescriptions.EVENT.projectionsTypeMap[Projection.SEARCH] = EventSearch;

	EntityDescriptions.EVENT_LOCATION.projectionsTypeMap[Projection.NONE] = EventLocation;
	EntityDescriptions.EVENT_LOCATION.projectionsTypeMap[Projection.FULL] = EventLocationFull;

	EntityDescriptions.FEATURE.projectionsTypeMap[Projection.NONE] = Feature;

	EntityDescriptions.GENERAL_PRODUCT.projectionsTypeMap[Projection.NONE] = GeneralProduct;
	EntityDescriptions.GENERAL_PRODUCT.projectionsTypeMap[Projection.FULL] = GeneralProductFull;

	EntityDescriptions.HOTEL.projectionsTypeMap[Projection.NONE] = Hotel;
	EntityDescriptions.HOTEL.projectionsTypeMap[Projection.FULL] = HotelFull;

	EntityDescriptions.INSURANCE.projectionsTypeMap[Projection.NONE] = Insurance;

	EntityDescriptions.INTEGRATION.projectionsTypeMap[Projection.NONE] = Integration;
	EntityDescriptions.INTEGRATION.projectionsTypeMap[Projection.BOOKING] = IntegrationBooking;
	EntityDescriptions.INTEGRATION.projectionsTypeMap[Projection.FULL] = IntegrationFull;

	EntityDescriptions.JOURNEY.projectionsTypeMap[Projection.NONE] = Journey;
	EntityDescriptions.JOURNEY.projectionsTypeMap[Projection.FULL] = JourneyFull;

	EntityDescriptions.OPERATOR.projectionsTypeMap[Projection.NONE] = Operator;
	EntityDescriptions.OPERATOR.projectionsTypeMap[Projection.FULL] = OperatorFull;

	EntityDescriptions.PAGE.projectionsTypeMap[Projection.NONE] = Page;

	EntityDescriptions.PASSENGER.projectionsTypeMap[Projection.NONE] = Passenger;

	EntityDescriptions.REVIEW.projectionsTypeMap[Projection.FULL] = ReviewFull;

	EntityDescriptions.REVIEW_CATEGORY.projectionsTypeMap[Projection.NONE] = ReviewCategory;

	EntityDescriptions.RESELLER.projectionsTypeMap[Projection.NONE] = Reseller;
	EntityDescriptions.RESELLER.projectionsTypeMap[Projection.FULL] = ResellerFull;

	EntityDescriptions.ROOM.projectionsTypeMap[Projection.NONE] = Room;
	EntityDescriptions.ROOM.projectionsTypeMap[Projection.FULL] = RoomFull;

	EntityDescriptions.STOP.projectionsTypeMap[Projection.NONE] = Stop;
	EntityDescriptions.STOP.projectionsTypeMap[Projection.FULL] = StopFull;

	EntityDescriptions.TAG.projectionsTypeMap[Projection.NONE] = Tag;
	EntityDescriptions.STOP_GROUP.projectionsTypeMap[Projection.NONE] = StopGroup;

	EntityDescriptions.TICKET.projectionsTypeMap[Projection.NONE] = Ticket;
	EntityDescriptions.TICKET.projectionsTypeMap[Projection.FULL] = TicketFull;

	checkProjections();
}

function checkProjections() {
	objectTypedKeys(EntityDescriptions).forEach(key => {
		const entityDescription = EntityDescriptions[key];
		Object.keys(entityDescription.projectionsTypeMap).forEach(projection => {
			const entityConstructor = entityDescription.projectionsTypeMap[projection];
			if (entityConstructor == null)
				throw new Error(
					`No projection "${projection}" defined in EntityDescription "${entityDescription.name}"`,
				);
		});
	});
}
