import GeneralProduct from 'src/entities/products/GeneralProduct';
import GeneralProductFull from 'src/entities/products/GeneralProductFull';
import Journey from 'src/entities/products/Journey';
import JourneyFull from 'src/entities/products/JourneyFull';
import Room from 'src/entities/products/Room';
import RoomFull from 'src/entities/products/RoomFull';
import Ticket from 'src/entities/products/Ticket';
import TicketFull from 'src/entities/products/TicketFull';
import Product from 'src/entities/products/Product';
import { ProductFull } from 'src/entities/products/ProductFull';

/**
 * Creates a new Product from the given json depending on the className.
 */
export const productFromJson = (json: any): Product => {
	switch (json.className) {
		case 'Ticket':
			return new Ticket(json);
		case 'Room':
			return new Room(json);
		case 'Journey':
			return new Journey(json);
		case 'GeneralProduct':
			return new GeneralProduct(json);
		default:
			throw new Error(`Error creating Product! Unknown product className = ${json.className}`);
	}
};

/**
 * Creates a new ProductFull from the given json depending on the className.
 */
export const productFullFromJson = (json: any): ProductFull => {
	switch (json.className) {
		case 'Ticket':
			return new TicketFull(json);
		case 'Room':
			return new RoomFull(json);
		case 'Journey':
			return new JourneyFull(json);
		case 'GeneralProduct':
			return new GeneralProductFull(json);
		default:
			throw new Error(`Error creating Product! Unknown product className = ${json.className}`);
	}
};
