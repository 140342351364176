import { TFunction } from 'i18next';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import Product from 'src/entities/products/Product';
import { joinToString } from 'src/helper/helper';
import { AutoFormFields } from '../BaseEntity';

export type TicketFormFields = AutoFormFields<Ticket>;

export default class Ticket extends Product {
	category: string;
	sector: string;
	block: string;
	readonly csBusTicketId?: string;

	constructor(json: any) {
		super(json);

		this.category = json.category;
		this.sector = json.sector;
		this.block = json.block;
		this.csBusTicketId = json.csBusTicketId;
	}

	getItemDescription(t: TFunction): string {
		return joinToString(' | ', this.name, this.categorySectorBlock(t));
	}

	categorySectorBlock(t: TFunction): string {
		const parts = [];

		const categoryPrefix = ''; // t('entities:ticket.category.label');
		const sectorPrefix = t('entities:ticket.sector.label');
		const blockPrefix = t('entities:ticket.block.label');

		if (this.category.length > 0) {
			if (this.category.startsWith(categoryPrefix)) parts.push(this.category);
			else parts.push(`${categoryPrefix} ${this.category}`);
		}
		if (this.sector.length > 0) {
			if (this.sector.startsWith(sectorPrefix)) parts.push(this.sector);
			else parts.push(`${sectorPrefix} ${this.sector}`);
		}
		if (this.block.length > 0) {
			if (this.block.startsWith(blockPrefix)) parts.push(this.block);
			else parts.push(`${blockPrefix} ${this.block}`);
		}

		return parts.join(', ');
	}

	fullName(t: TFunction): string {
		const categorySectorBlockLabel = this.categorySectorBlock(t);
		return categorySectorBlockLabel.length > 0 ? `${this.name} (${categorySectorBlockLabel})` : this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.TICKET;
	}

	static formFields(entity?: Ticket): TicketFormFields {
		return {
			...Product.formFields(entity),
			category: entity?.category,
			sector: entity?.sector,
			block: entity?.block,
			csBusTicketId: entity?.csBusTicketId,
		};
	}
}
