import { createMedia } from '@artsy/fresnel';
import { FC, PropsWithChildren } from 'react';

export const MEDIA_CONFIG = {
	breakpoints: {
		mobile: 320,
		tablet: 768,
		computer: 992,
		largeScreen: 1200,
		widescreen: 1920,
	},
};

export const AppMedia = createMedia(MEDIA_CONFIG);

export const Media = AppMedia.Media;

export const AppMediaProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const mediaStyles = AppMedia.createMediaStyle();

	return (
		<>
			<style>{mediaStyles}</style>
			<AppMedia.MediaContextProvider>{children}</AppMedia.MediaContextProvider>
		</>
	);
};
