import { Component } from 'react';
import { CompiledRoutes, S_COMPONENT } from 'src/routing';

export const STATIC_ROOT = 'static';

export const STATIC_ROUTES = new CompiledRoutes(STATIC_ROOT, {
	[S_COMPONENT]: null,
	':slug': {
		[S_COMPONENT]: Component,
	},
});
