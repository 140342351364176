import Account from 'src/entities/accounts/Account';
import Customer from 'src/entities/accounts/Customer';
import Company from 'src/entities/companies/Company';
import CustomerData from 'src/entities/customer-data/CustomerData';
import { AutoFormFields } from '../BaseEntity';

export type CustomerDataFullFormFields = AutoFormFields<CustomerDataFull>;
export default class CustomerDataFull extends CustomerData {
	company: Company;
	account?: Account;

	constructor(json: any) {
		super(json);
		this.company = new Company(json.company);
		this.account = json.account != null ? new Customer(json.account) : undefined;
	}

	static formFields(entity?: CustomerDataFull, company?: Company): CustomerDataFullFormFields {
		return {
			...CustomerData.formFields(entity),
			company: entity?.company.getLink() ?? company?.getLink(),
			account: entity?.account?.getLink(),
		};
	}
}
