import BundleBooking from 'src/entities/bundle/BundleBooking';
import CompanyBooking from 'src/entities/companies/CompanyBooking';
import EventBookingDetails from 'src/entities/event/EventBookingDetails';
import { fromJsonArraySortedNumericallyJoined } from 'src/helper/helper';

export default class BundleBookingDetails extends BundleBooking {
	company: CompanyBooking;
	event: EventBookingDetails;
	readonly supportsInsurance: boolean;

	readonly csBusBundleId?: string;

	constructor(json: any) {
		super(json);

		this.company = new CompanyBooking(json.company);
		this.event = new EventBookingDetails(json.event);
		this.supportsInsurance = json.supportsInsurance;

		this.csBusBundleId = fromJsonArraySortedNumericallyJoined(json.csBusBundleIds);
	}

	getOgTitle(): string {
		return `${this.company.name} - ${this.event.name} - ${this.name}`;
	}
}
