import { Action } from '@reduxjs/toolkit';
import HttpStatus from 'http-status-codes';
import { assign } from 'lodash';
import { AccountFull } from 'src/entities/accounts/AccountFull';
import { createAccountFull } from 'src/entities/factories/account-factory';
import { FAILURE_OWN_ACCOUNT, REQUEST_OWN_ACCOUNT, SUCCESS_FETCH_OWN_ACCOUNT } from 'src/redux/actions/account';
import { singleEntityDefaultState } from 'src/redux/selectors';
import { SingleEntityState } from 'src/types';

interface OwnAccountAction extends Action {
	response: any;
	error: any;
}

/**
 * Reducer that manages the state of the authenticated account (currently logged in user)
 */
function ownAccountReducer(state: SingleEntityState<AccountFull> = singleEntityDefaultState, action: OwnAccountAction) {
	switch (action.type) {
		case REQUEST_OWN_ACCOUNT:
			return assign({}, state, { isFetching: true, error: undefined });
		case SUCCESS_FETCH_OWN_ACCOUNT: {
			// not logged in
			if (action.response.status === HttpStatus.NO_CONTENT)
				return assign({}, state, { isFetching: false, content: undefined, error: undefined });
			// logged in

			return assign({}, state, {
				isFetching: false,
				content: createAccountFull(action.response),
				error: undefined,
			});
		}
		case FAILURE_OWN_ACCOUNT:
			return assign({}, state, { isFetching: false, error: action.error });
		default:
			return state;
	}
}

export default ownAccountReducer;
