import { InvoiceFormFields } from 'src/components/booking/backend/InvoiceForm';
import { action } from 'src/redux/actions';
import { Projection } from 'src/entities/description/EntityDescription';
import { PaymentMethod } from 'src/entities/payment/Gateway';

export const GENERATE_MARGINAL_TAX_INVOICE = 'GENERATE_MARGINAL_TAX_INVOICE';
export const GENERATE_REGULAR_TAX_INVOICE = 'GENERATE_REGULAR_TAX_INVOICE';
export const DELETE_PENDING_BOOKING = 'DELETE_PENDING_BOOKING';
export const ACCEPT_PENDING_BOOKING = 'ACCEPT_PENDING_BOOKING';
export const REPAY_BOOKING = 'REPAY_BOOKING';

export const generateMarginalTaxInvoice = (bookingId: number) => action(GENERATE_MARGINAL_TAX_INVOICE, { bookingId });

export const generateRegularTaxInvoice = (bookingId: number, bookingItemTaxes: InvoiceFormFields) =>
	action(GENERATE_REGULAR_TAX_INVOICE, { bookingId, bookingItemTaxes });

export const deletePendingBooking = (id: number, projection?: Projection) =>
	action(DELETE_PENDING_BOOKING, { id, projection });

export const acceptPendingBooking = (id: number, projection?: Projection) =>
	action(ACCEPT_PENDING_BOOKING, { id, projection });

export const repayBooking = (token: string, paymentMethod?: PaymentMethod, projection?: Projection) =>
	action(REPAY_BOOKING, { token, paymentMethod, projection });
