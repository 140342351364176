import { TFunction } from 'i18next';
import { emptyMenuItem, enumMenuItems, MenuItem } from 'src/components/elements/form-inputs/SelectField';
import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import Contingent from 'src/entities/basic-types/Contingent';
import Document from 'src/entities/basic-types/Document';
import Money from 'src/entities/basic-types/Money';
import { joinToString } from 'src/helper/helper';

export type ProductFormFields = AutoFormFields<Product>;

export enum TaxType {
	BESORGUNGSLEISTUNG = 'BESORGUNGSLEISTUNG',
	VERMITTLUNGSLEISTUNG = 'VERMITTLUNGSLEISTUNG',
	EIGENLEISTUNG = 'EIGENLEISTUNG',
}

export const taxTypeMenuItems = (t: TFunction) =>
	[emptyMenuItem].concat(enumMenuItems(TaxType, 'entities:product.taxType.options', t));

export default abstract class Product extends BaseEntity {
	name: string;
	shortDescription: string;
	description: string;
	logo?: Document;
	sellPrice: Money;
	vat?: number;
	taxType?: TaxType;
	readonly contingent: Contingent;

	protected constructor(json: any) {
		super(json);

		this.name = json.name;
		this.shortDescription = json.shortDescription;
		this.description = json.description;
		this.logo = Document.fromJson(json.logo);
		this.sellPrice = Money.fromJson(json.sellPrice);
		this.vat = json.vat;
		this.taxType = json.taxType;
		this.contingent = Contingent.fromJson(json.contingent);
	}

	toString(): string {
		return this.name;
	}

	getItemDescription(t: TFunction): string {
		return joinToString(' | ', this.name, this.identifier);
	}

	public getMenuItem(t: TFunction): MenuItem {
		return {
			key: this.id,
			text: this.name,
			value: this.getLink(),
			description: this.getItemDescription(t),
		};
	}

	static formFields(entity?: Product): ProductFormFields {
		return {
			...BaseEntity.formFields(entity),
			name: entity?.name,
			shortDescription: entity?.shortDescription,
			description: entity?.description,
			logo: entity?.logo,
			sellPrice: entity?.sellPrice,
			vat: entity?.vat,
			taxType: entity?.taxType,
			contingent: Contingent.formFields(entity?.contingent),
		};
	}
}
