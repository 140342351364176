import React from 'react';
import { Helmet } from 'react-helmet';
import { ENV, IS_DEVELOPMENT } from 'src/config/env';
import IntegrationBooking from 'src/entities/integration/IntegrationBooking';

interface Props {
	integration?: IntegrationBooking;
}

const AppHelmet: React.FC<Props> = ({ integration }) => {
	if (IS_DEVELOPMENT || !integration) return null;

	const seo = integration.seo;

	return (
		<Helmet>
			<meta charSet="utf-8" />
			<title>{seo.title}</title>
			<meta name="description" content={seo.description} />
			<meta name="keywords" content={seo.keywords} />
			<meta name="author" content={integration.getOwnerName()} />
			<meta name="og:url" content={ENV.mainUrl} />
			<meta name="og:title" content={seo.title} />
			<meta name="og:description" content={seo.description} />
			{seo.googleTagManagerId && (
				<script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${seo.googleTagManagerId}');`}</script>
			)}
			{seo.googleTagManagerId && (
				<noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=${seo.googleTagManagerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
			)}
		</Helmet>
	);
};

export default AppHelmet;
