import { Action, UnknownAction } from '@reduxjs/toolkit';
import HttpStatus from 'http-status-codes';
import { SagaIterator } from 'redux-saga';
import { all, call, Effect, put, select, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects';
import { AbstractEntityDescriptions, EntityDescriptions } from 'src/entities/description';
import EntityDescription, { Projection } from 'src/entities/description/EntityDescription';
import { objectTypedKeys } from 'src/helper/helper';
import { CREATE, DELETE, FETCH, FetchDescription, fetchEntities, fetchEntity, UPDATE } from 'src/redux/actions';
import { setNotification } from 'src/redux/actions/system-notifications';
import {
	changePasswordWatcher,
	changeUsernameWatcher,
	confirmChangeUsernameWatcher,
	forgotPasswordWatcher,
	generateConfirmRegisterWatcher,
	generateFetchOwnAccountWatcher,
	generateLoginWatcher,
	generateLogoutWatcher,
	generateRegisterWatcher,
	patchOwnAccountWatcher,
	resetPasswordWatcher,
} from 'src/redux/sagas/account';
import {
	generateAcceptPendingBookingWatcher,
	generateDeletePendingBookingWatcher,
	generateMarginalTaxInvoiceWatcher,
	generateRegularTaxInvoiceWatcher,
	generateRepayBookingWatcher,
} from 'src/redux/sagas/booking';
import {
	generateFetchBookingProgressWatcher,
	generateSubmitBookingWatcher,
	generateUpdateBookingProgressWatcher,
} from 'src/redux/sagas/booking-progress';
import { generateBreadcrumbsWatcher } from 'src/redux/sagas/breadcrumbs';
import {
	generateCreateBundleItemWithProductWatcher,
	generatePatchBundleItemWithProductWatcher,
} from 'src/redux/sagas/bundle-item';
import { generateCreateEventWithBundleWatcher } from 'src/redux/sagas/event';
import {
	generateRemoveQueryParametersWatcher,
	generateRouterWatcher,
	generateUpdateQueryParametersWatcher,
} from 'src/redux/sagas/routes';
import { fetchFilterEntitiesWatcher } from 'src/redux/sagas/search-filter';
import { getEntityById } from 'src/redux/selectors';
import {
	create,
	createBatch,
	deleteById,
	fetchAll,
	fetchById,
	fetchSingleEntityByEndpoint,
	patch,
	search,
} from 'src/services/abstract-repository';
import { CallApiResult, IF_MATCH_HEADER, IF_MODIFIED_SINCE_HEADER } from 'src/services/api';
import { RequestHeaders, SingleEntityState, StatusType } from 'src/types';

export interface BaseAction extends Action {
	id?: number;
	projection: Projection;
	body?: any;
}

/******************************************************************************/
/** ***************************** Subroutines **********************************/
/******************************************************************************/

/**
 * Generates a saga that fetches item(s) for the given entityDescription
 * and dispatch the appropriate actions.
 *
 * If action.id is present it will fetch a single item by id.
 * Otherwise, all entities will be fetched.
 */

export interface FetchAction extends BaseAction {
	searchMethod?: string;
	endpoint?: URI;
	publicEndpoint?: boolean;
}

export function* fetchSaga(entityDescription: EntityDescription, action: FetchAction): SagaIterator {
	if (entityDescription.children && entityDescription.children.length > 0) {
		for (const child of entityDescription.children) {
			yield put(child.action.request(action.projection));
		}
	} else yield put(entityDescription.action.request(action.projection));

	const id = action.id ?? action.body?.id ?? null;
	const searchMethod = action.searchMethod ?? null;
	const headers: RequestHeaders = {};

	let result: CallApiResult;

	// fetch single entity by id
	if (id) {
		// populate headers if entity already exists in local redux store
		const entity: SingleEntityState<any> = yield call(getEntity, entityDescription, id, action.projection);
		if (entity.content != null) headers[IF_MODIFIED_SINCE_HEADER] = entity.content.modifiedAt.toISOString();

		result = yield call(fetchById, entityDescription, id, action.projection, headers);

		if (result.error) {
			// entity did not change
			if (result.error.status === HttpStatus.NOT_MODIFIED)
				yield put(entityDescription.action.notModified(action.projection));
			// error occurred
			else {
				yield put(entityDescription.action.failure(result.error, action.projection));
				yield put(
					setNotification(StatusType.ERROR, result.error.status, result.error.method, entityDescription),
				);
			}
		} else yield put(entityDescription.action.successFetch(result.response, action.projection));

		// fetch single entity by endpoint
	} else if (action.endpoint) {
		result = yield call(fetchSingleEntityByEndpoint, action.endpoint, action.projection, headers);
		if (result.error) {
			yield put(entityDescription.action.failure(result.error, action.projection));
			yield put(setNotification(StatusType.ERROR, result.error.status, result.error.method, entityDescription));
		} else yield put(entityDescription.action.successFetch(result.response, action.projection));

		// fetch collection
	} else {
		// either fetch all entities or by the given search query
		result = searchMethod
			? yield call(search, entityDescription, searchMethod, action.projection, Boolean(action.publicEndpoint))
			: yield call(fetchAll, entityDescription, action.projection);
		if (result.error) {
			// if the entity description has children -> we dispatch the action vor every child
			if (entityDescription.children && entityDescription.children.length > 0) {
				for (const child of entityDescription.children) {
					yield put(child.action.failure(result.error, action.projection));
				}
			} else yield put(entityDescription.action.failure(result.error, action.projection));

			yield put(setNotification(StatusType.ERROR, result.error.status, result.error.method, entityDescription));
		}
		// if the entity description has children -> we dispatch the action for every child
		else if (entityDescription.children && entityDescription.children.length > 0) {
			for (const child of entityDescription.children) {
				yield put(child.action.successFetch(result.response, action.projection));
			}
		} else yield put(entityDescription.action.successFetch(result.response, action.projection));
	}
}

interface PatchAction extends BaseAction {
	patchMethod?: string;
	fetchAfterSuccess?: FetchDescription[];
}

/**
 * Generates a create or patch saga for the given entityDescription depending on the method
 * After a successful create or patch the entity will be fetched with the given projection
 */
export function* createPatchEntitySaga(
	entityDescription: EntityDescription,
	method: 'CREATE' | 'UPDATE',
	action?: PatchAction,
	version?: number,
): SagaIterator {
	const headers: RequestHeaders = {};
	if (!action) throw new Error('createPatchEntitySaga(): action is required');

	const isBodyArray = Array.isArray(action.body);

	let apiFn;
	switch (method) {
		case CREATE:
			apiFn = isBodyArray ? createBatch : create;
			break;
		case UPDATE:
			apiFn = patch;
			if (version) {
				headers[IF_MATCH_HEADER] = version.toString();
			} else if (action.body.id) {
				const entity: SingleEntityState<any> = yield call(
					getEntity,
					entityDescription,
					action.body.id,
					action.projection,
				);
				if (entity.content != null) headers[IF_MATCH_HEADER] = entity.content.version.toString();
			}
			break;
		default:
			throw new Error('createPatchEntitySaga(): Unknown method: ' + method);
	}

	yield put(entityDescription.action.request(action.projection));

	const { error, response } =
		action.patchMethod != null
			? yield call(apiFn, entityDescription, action.body, action.projection, headers, action.patchMethod)
			: yield call(apiFn, entityDescription, action.body, action.projection, headers);

	if (error) {
		yield put(entityDescription.action.failure(error, action.projection));
		yield put(
			setNotification(StatusType.ERROR, error.status, error.method, entityDescription, {
				id: action.body?.id,
				projection: action.projection,
			}),
		);
	} else {
		// if entity has been created -> set id of new entity in action in order to fetch it with a projection
		if (method === CREATE && response && !isBodyArray) action.id = response.id;

		isBodyArray
			? // batch create instantly returns the results with the correct projection
				yield put(entityDescription.action.successFetch(response, action.projection, true))
			: // single create needs to fetch the created entity with the correct projection
				yield call(fetchSaga, entityDescription, action);

		// also fetch additional entities if provided
		yield call(fetchEntitiesAfterSuccessAction, action);
	}
}

interface DeleteAction extends BaseAction {
	actionAfterSuccess?: (entity: any) => void;
}

/**
 * Generates a delete saga the deletes an entity by id.
 */
function* deleteEntitySaga(entityDescription: EntityDescription, action: DeleteAction): SagaIterator {
	if (!action.id) throw new Error('deleteEntitySaga(): id is required');
	const headers: RequestHeaders = {};

	// populate headers if entity exist in local redux store
	const entity: SingleEntityState<any> = yield call(getEntity, entityDescription, action.id, action.projection);
	if (entity.content != null) headers[IF_MATCH_HEADER] = entity.content.version.toString();

	yield put(entityDescription.action.request(action.projection));

	const { error } = yield call(deleteById, entityDescription, action.id, headers);

	if (error) {
		yield put(entityDescription.action.failure(error, action.projection));
		yield put(
			setNotification(
				StatusType.ERROR,
				error.status,
				error.method,
				entityDescription,
				{
					id: action.id,
					projection: action.projection,
				},
				error.problem != null ? error.problem.key : undefined,
				error.problem != null ? error.problem.options : undefined,
			),
		);
	} else {
		yield put(entityDescription.action.successDelete({ id: action.id }, action.projection));

		// also fetch additional entities if provided
		yield call(fetchEntitiesAfterSuccessAction, action);

		if (action.actionAfterSuccess) action.actionAfterSuccess(entity.content);
	}
}

export function* fetchEntitiesAfterSuccessAction(action: PatchAction) {
	if (action.fetchAfterSuccess)
		for (const fetch of action.fetchAfterSuccess) {
			yield call(fetchEntitiesAfterSuccess, fetch);
		}
}

/** Fetch a single entity or entities */
export function* fetchEntitiesAfterSuccess(fetchAfterSuccess: FetchDescription) {
	if (fetchAfterSuccess.id)
		yield put(fetchEntity(fetchAfterSuccess.entityDescription, fetchAfterSuccess.id, fetchAfterSuccess.projection));
	else
		yield put(
			fetchEntities(
				fetchAfterSuccess.entityDescription,
				fetchAfterSuccess.projection,
				fetchAfterSuccess.searchMethod,
			),
		);
}

/** Get an entity from the store */
function* getEntity(entityDescription: EntityDescription, id: number, projection: Projection): Generator<SelectEffect> {
	return yield select(getEntityById, entityDescription, id, projection);
}

/******************************************************************************/
/** ***************************** WATCHERS *************************************/
/******************************************************************************/

/**
 * Fetch Watcher for the given entityDescription
 */
function* generateFetchWatcherByEntity(entityDescription: EntityDescription) {
	yield takeEvery(`${entityDescription.name}_${FETCH}`, fetchSaga, entityDescription);
}

/**
 * Create Watcher for the given entityDescription
 */
function* generateCreateEntityWatcher(entityDescription: EntityDescription) {
	yield takeLatest(`${entityDescription.name}_${CREATE}`, createPatchEntitySaga, entityDescription, CREATE);
}

/**
 * Patch Watcher for the given entityDescription
 */
function* generateUpdateEntityWatcher(entityDescription: EntityDescription) {
	yield takeLatest(`${entityDescription.name}_${UPDATE}`, createPatchEntitySaga, entityDescription, UPDATE);
}

/**
 * Delete Watcher for the given entityDescription
 */
function* generateDeleteEntityWatcher(entityDescription: EntityDescription) {
	yield takeLatest(`${entityDescription.name}_${DELETE}`, deleteEntitySaga, entityDescription);
}

/**
 * Generate root Effects description that instructs the middleware
 * to run all Effects in parallel and wait for all of them to complete
 */
export default function* root() {
	yield all(combineWatchers());
}

/**
 * Combine all watcher calls into single array
 */
function combineWatchers(): Effect[] {
	const watchers: Effect[] = [];

	objectTypedKeys(EntityDescriptions).forEach(key => {
		watchers.push(call(generateFetchWatcherByEntity, EntityDescriptions[key]));
		watchers.push(call(generateCreateEntityWatcher, EntityDescriptions[key]));
		watchers.push(call(generateUpdateEntityWatcher, EntityDescriptions[key]));
		watchers.push(call(generateDeleteEntityWatcher, EntityDescriptions[key]));
	});

	objectTypedKeys(AbstractEntityDescriptions).forEach(key => {
		watchers.push(call(generateFetchWatcherByEntity, AbstractEntityDescriptions[key]));
		watchers.push(call(generateCreateEntityWatcher, AbstractEntityDescriptions[key]));
		watchers.push(call(generateUpdateEntityWatcher, AbstractEntityDescriptions[key]));
		watchers.push(call(generateDeleteEntityWatcher, AbstractEntityDescriptions[key]));
	});

	watchers.push(call(generateLoginWatcher));
	watchers.push(call(generateLogoutWatcher));
	watchers.push(call(generateRegisterWatcher));
	watchers.push(call(generateConfirmRegisterWatcher));
	watchers.push(call(generateFetchOwnAccountWatcher));
	watchers.push(call(generateRouterWatcher));
	watchers.push(call(changePasswordWatcher));
	watchers.push(call(changeUsernameWatcher));
	watchers.push(call(confirmChangeUsernameWatcher));
	watchers.push(call(forgotPasswordWatcher));
	watchers.push(call(resetPasswordWatcher));
	watchers.push(call(patchOwnAccountWatcher));
	watchers.push(call(generateUpdateQueryParametersWatcher));
	watchers.push(call(generateRemoveQueryParametersWatcher));
	watchers.push(call(generateUpdateBookingProgressWatcher));
	watchers.push(call(generateFetchBookingProgressWatcher));
	watchers.push(call(generateSubmitBookingWatcher));
	watchers.push(call(generateCreateBundleItemWithProductWatcher));
	watchers.push(call(generatePatchBundleItemWithProductWatcher));
	watchers.push(call(generateCreateEventWithBundleWatcher));
	watchers.push(call(generateMarginalTaxInvoiceWatcher));
	watchers.push(call(generateRegularTaxInvoiceWatcher));
	watchers.push(call(generateBreadcrumbsWatcher));
	watchers.push(call(fetchFilterEntitiesWatcher));
	watchers.push(call(generateDeletePendingBookingWatcher));
	watchers.push(call(generateAcceptPendingBookingWatcher));
	watchers.push(call(generateRepayBookingWatcher));

	return watchers;
}
