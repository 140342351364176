import { Action } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import SearchParams from 'src/entities/booking/SearchParams';
import {
	FETCH_FILTER_ENTITIES,
	failureFilterEntities,
	requestFilterEntities,
	successFetchFilterEntities,
} from 'src/redux/actions/search-filter';
import { fetchFilterEntities } from 'src/services/search-filter';

interface FilterEntitiesAction extends Action {
	searchParams: SearchParams;
}

function* fetchFilterEntitiesSaga(action: FilterEntitiesAction): SagaIterator {
	yield put(requestFilterEntities());

	const { error, response } = yield call(fetchFilterEntities, action.searchParams);

	yield error ? put(failureFilterEntities(error)) : put(successFetchFilterEntities(response));
}

export function* fetchFilterEntitiesWatcher() {
	yield takeLatest(FETCH_FILTER_ENTITIES, fetchFilterEntitiesSaga);
}
