import { UnknownAction } from '@reduxjs/toolkit';
import { History } from 'history';
import { assign, identity, pickBy } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { call, getContext, takeLatest } from 'redux-saga/effects';
import SearchParams from 'src/entities/booking/SearchParams';
import { ROUTE_CHANGED } from 'src/redux/actions';
import { RESET_FILTER_QUERY_PARAMETERS, UPDATE_FILTER_QUERY_PARAMETERS } from 'src/redux/actions/routes';
import { SESSION_STORAGE } from 'src/redux/persistors';
import { LoginAction, fetchAuthenticatedAccountSaga } from 'src/redux/sagas/account';
import { buildUrl } from 'src/routing';
import { SEARCH_ROUTES } from 'src/scenes/SearchRoutes';
import { ParsedUrlQuery } from 'src/types';

/**
 * Gets called after every route change -> Will fetch the authenticated account
 */
function* routeChangedSaga(action: LoginAction): SagaIterator {
	yield call(scroll, 0, 0);
	yield call(fetchAuthenticatedAccountSaga, action);
}

/**
 * Updates the current search query with the given parameters in the action.
 * If the action contains the property rewrite, a router.replace action will be dispatched, otherwise router.push
 */
function* updateQueryParameters(action: UnknownAction): SagaIterator {
	const history: History = yield getContext('history');

	// assign new params to query and remove undefined/null values (cleanup)
	const path = action.resetFilter
		? buildUrl(SEARCH_ROUTES, undefined, SearchParams.fromQuery(action.params as ParsedUrlQuery).getParams())
		: buildUrl(SEARCH_ROUTES, undefined, pickBy(assign(history.location.query, action.params), identity));

	// set current search query in session storage
	sessionStorage.setItem(SESSION_STORAGE.SEARCH_QUERY, path);

	// TODO: This does not make sense. Has been migrated from the old code. Needs further evaluation!
	if (action.isReplace) history.push(path);
	else history.replace(path);
}

/**
 * Removes all filter query parameters at the search route.
 */
function* removeFilterQueryParameters(action: UnknownAction): SagaIterator {
	const history: History = yield getContext('history');

	const searchParams = SearchParams.fromQuery(history.location.query);
	const path = buildUrl(SEARCH_ROUTES, undefined, searchParams.getParams());

	// set current search query in session storage
	sessionStorage.setItem(SESSION_STORAGE.SEARCH_QUERY, path);

	history.replace(path);
}

export function* generateRouterWatcher() {
	yield takeLatest(ROUTE_CHANGED, routeChangedSaga);
}

export function* generateUpdateQueryParametersWatcher() {
	yield takeLatest(UPDATE_FILTER_QUERY_PARAMETERS, updateQueryParameters);
}

export function* generateRemoveQueryParametersWatcher() {
	yield takeLatest(RESET_FILTER_QUERY_PARAMETERS, removeFilterQueryParameters);
}
