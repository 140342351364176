import i18next, { TFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import I18NextChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import 'moment/dist/locale/de';
import { populateCountryMenuItems } from 'src/config/countries/countries';
import Money from 'src/entities/basic-types/Money';
import { richTextIsEmpty } from 'src/helper/helper';
import { IS_DEVELOPMENT } from '../env';

/**
 * Type definition for all supported languages.
 */
export type SupportedLanguage = 'de' | 'en';

/**
 * An array of all supported languages.
 */
export const LANGUAGES: SupportedLanguage[] = ['de', 'en'];

/**
 * The language to use when the language cannot be determined, or a translation is missing.
 */
export const FALLBACK_LANGUAGE: SupportedLanguage = 'de';

/**
 * Format for translation strings of the form {{value, format}}.
 * Creates a new moment with the current language when the language changes.
 *
 * https://www.i18next.com/overview/api#format
 * https://momentjs.com/docs/#/i18n/changing-locale/
 */
const format = (value: any, formatString: string, language: string): string => {
	if (moment.isMoment(value)) return (value as moment.Moment).locale(language).format(formatString);
	return value instanceof Money ? value.format() : value;
};

const LOCALE_COOKIE = 'LOCALE';

/**
 * The default country to show first in dropdowns.
 */
const DEFAULT_COUNTRY = 'AUT';

/**
 * When the language changes:
 *
 * - save the language to a cookie
 * - change the moment locale
 * - update country menu items
 * - reload the google services with the new language
 * - update the html lang attribute
 *
 * https://www.i18next.com/overview/api#onlanguagechanged
 * https://momentjs.com/docs/#/i18n/changing-locale/
 * http://numeraljs.com/#locales
 */
export const onLanguageChanged = (locale: string): void => {
	const language: SupportedLanguage = extractLanguage(locale) as SupportedLanguage;
	console.log('Language changed: ', language);
	Cookies.set(LOCALE_COOKIE, language, { secure: true, sameSite: 'none' });
	moment.locale(language);
	populateCountryMenuItems(language, DEFAULT_COUNTRY);
	document.documentElement.lang = locale;
};

i18next
	.use(LanguageDetector)
	.use(I18NextChainedBackend)
	.init(
		// i18next Configuration
		// https://www.i18next.com/overview/configuration-options
		{
			load: 'languageOnly',
			fallbackLng: FALLBACK_LANGUAGE,

			// Load language from querystring, then cookie, then detect from browser
			// https://github.com/i18next/i18next-browser-languageDetector#detector-options
			detection: {
				order: ['querystring', 'cookie', 'navigator'],
				lookupCookie: LOCALE_COOKIE,
				lookupQuerystring: 'language',
			},

			ns: ['translations', 'validation', 'entities', 'format', 'routes'],
			defaultNS: 'translations',

			debug: IS_DEVELOPMENT,

			react: {
				useSuspense: true,
			},

			interpolation: {
				escapeValue: false, // Don't escape values; we have no user-submitted translations
				format,
			},
			// Dynamic import of resources via vite
			backend: {
				backends: [
					resourcesToBackend((lng, ns, cb) => {
						import(`../../assets/locales/${lng}/${ns}.json`)
							.then(({ default: resources }) => {
								cb(null, resources);
							})
							.catch(error => {
								// Atm we only have translation files for language codes.
								// en-AT for example won't return anything. Suppress these errors.
								if (lng.includes('-')) cb(null, {});
								else cb(error, null);
							});
					}),
				],
			},
		},
	);

i18next.on('languageChanged', onLanguageChanged);

/**
 * Extract the first language part from a locale string, e.g. returns 'en' for 'en-US'.
 */
export const extractLanguage = (locale: string): SupportedLanguage => {
	if (locale != null) {
		const language = locale.split('-')[0] as SupportedLanguage;
		if (LANGUAGES.includes(language)) return language;
	}
	return FALLBACK_LANGUAGE;
};

/**
 * Get the current language part from i18next.
 */
export const currentLanguage = (): SupportedLanguage => extractLanguage(i18next.language);

/**
 * Menu options for all supported languages.
 */
export const languageOptions = (t: TFunction) =>
	LANGUAGES.map(language => ({
		text: t(`language.${language}`),
		value: language,
	}));

/**
 * Get text for the current language from a map.
 *
 * If null or empty, fallback to the FALLBACK_LANGUAGE.
 */
export const getLocalizedText = (map: Record<string, string>): string | undefined => {
	if (map == null) return undefined;
	const text = map[currentLanguage()];
	return text == null || text === '' ? map[FALLBACK_LANGUAGE] : text;
};

/**
 * Get text for the current language from a map.
 *
 * If null or empty, or contains only empty HTML tags (e.g. <br/>) fallback to the FALLBACK_LANGUAGE.
 */
export const getLocalizedHTML = (map: Record<string, string>): string | undefined => {
	if (map == null) return undefined;
	const text = map[currentLanguage()];
	return richTextIsEmpty(text) ? map[FALLBACK_LANGUAGE] : text;
};

export default i18next;
