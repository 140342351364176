import { cloneDeepWith } from 'lodash';
import { createTransform } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import BookingProgress from 'src/entities/booking/progress/BookingProgress';
import BookingProgressRow from 'src/entities/booking/progress/BookingProgressRow';
import CombinedBookingItem from 'src/entities/booking/progress/CombinedBookingItem';
import { BookingProgressStep } from 'src/entities/booking/progress/steps/BookingProgressStep';
import ProductStep from 'src/entities/booking/progress/steps/ProductStep';
import { momentWithTimeZoneToISOString } from 'src/helper/helper';
import { SingleEntityState } from 'src/types';

/**
 * Enum of custom session storage objects
 */
export enum SESSION_STORAGE {
	SEARCH_QUERY = 'SEARCH_QUERY',
	REDIRECT_AFTER_LOGIN_URL = 'REDIRECT_AFTER_LOGIN_URL',
}

/**
 * Parses the given booking progress item for storage persistence
 */
const transformBookingProgressItem = (item: CombinedBookingItem) => {
	const bundleItem = item.bundleItem;

	return {
		...item,
		bundleItem: {
			...bundleItem,
			stopTime: bundleItem.stopTime && momentWithTimeZoneToISOString(bundleItem.stopTime),
		},
	};
};

/**
 * Parses all ProductSteps from the given bookingRow for storage persistence
 */
const transformSteps = (bookingRow: BookingProgressRow) => {
	return bookingRow.steps.map((step: BookingProgressStep) => {
		if (step instanceof ProductStep) {
			return {
				...step,
				mandatoryItems: step.mandatoryItems.map((item: CombinedBookingItem) =>
					transformBookingProgressItem(item),
				),
				optionalItems: step.optionalItems.map((item: CombinedBookingItem) =>
					transformBookingProgressItem(item),
				),
				relatedMandatoryItems: step.relatedMandatoryItems.map((item: CombinedBookingItem) =>
					transformBookingProgressItem(item),
				),
				relatedOptionalItems: step.relatedOptionalItems.map((item: CombinedBookingItem) =>
					transformBookingProgressItem(item),
				),
			};
		}

		return step;
	});
};

/**
 * Handles BookingProgress transformation from and to the storage
 */
const bookingTransform = createTransform(
	// transform state on its way to being serialized and persisted.
	(inboundState: SingleEntityState<BookingProgress>, key) => {
		if (
			!inboundState.isFetching &&
			inboundState.content?.bookingRows &&
			inboundState.content.bookingRows.length > 0
		) {
			const booking: any = cloneDeepWith(inboundState);

			booking.content.balanceUntil = inboundState.content.balanceUntil
				? momentWithTimeZoneToISOString(inboundState.content.balanceUntil)
				: undefined;

			booking.content.bookingRows = inboundState.content.bookingRows.map((bookingRow: BookingProgressRow) => {
				return {
					...bookingRow,
					steps: transformSteps(bookingRow),
					bundle: {
						...bookingRow.bundle,
						bookableUntil: momentWithTimeZoneToISOString(bookingRow.bundle.bookableUntil),
						cancellableFreeUntil:
							bookingRow.bundle.cancellableFreeUntil &&
							momentWithTimeZoneToISOString(bookingRow.bundle.cancellableFreeUntil),
						start: momentWithTimeZoneToISOString(bookingRow.bundle.start),
						end: momentWithTimeZoneToISOString(bookingRow.bundle.end),
						event: {
							...bookingRow.bundle.event,
						},
					},
				};
			});

			console.log('Persisting BookingProgress state: ', booking);

			return booking;
		}

		return inboundState;
	},
	// transform state being rehydrated
	(outboundState, key) => {
		console.log('Rehydrating BookingProgress outboundState.content:', outboundState.content);

		try {
			const content = outboundState.content ? BookingProgress.fromJson(outboundState.content) : undefined;
			console.log('Rehydrating BookingProgress state:', content);

			return {
				isFetching: outboundState.isFetching,
				error: outboundState.error,
				content,
			};
		} catch (e) {
			console.error('Rehydrating BookingProgress error:', e);
			return {
				isFetching: outboundState.isFetching,
				error: outboundState.error,
				content: undefined,
			};
		}
	},
	// define which reducers this transform gets called for.
	{ whitelist: ['booking'] },
);

const persistConfig = {
	key: 'root',
	storage: storageSession,
	whitelist: ['booking'],
	transforms: [bookingTransform],
};

export default persistConfig;
