import * as Sentry from '@sentry/react';
import { ENV, IS_DEVELOPMENT } from './env';

export function initSentry() {
	if (IS_DEVELOPMENT) return;

	const sentryRelease = ENV.version
		// replace first - with a plus (to treat as metadata instead of prerelease)
		.replace(/-(.+)$/, '+$1')
		// replace v with tnt@
		.replace(/^v?(.+)$/, 'tnt@$1');

	console.log('Init Sentry:', sentryRelease);

	// Sentry options
	// https://docs.sentry.io/platforms/javascript/guides/react/
	Sentry.init({
		dsn: ENV.sentryDsn,
		environment: ENV.profile,
		release: sentryRelease,
	});
}
