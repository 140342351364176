import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import ContactData from 'src/entities/basic-types/ContactData';
import Document from 'src/entities/basic-types/Document';
import Location from 'src/entities/basic-types/Location';
import Money, { Currency, CurrencyKey } from 'src/entities/basic-types/Money';
import ReviewScore from 'src/entities/basic-types/ReviewScore';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';

export type CompanyFormFields = Omit<AutoFormFields<Company>, 'score'>;

export default class Company extends BaseEntity {
	name: string;
	logo?: Document;
	contactData: ContactData;
	location: Location;

	locale: string;
	currency: Currency;

	generalConditions: string;
	paymentConditions: string;
	cancellationConditions: string;
	privacyPolicy: string;

	packageTourInfo: string;
	stopTimeInfo: string;
	invoiceMarginalTaxText: string;
	invoiceRegularTaxText: string;

	invoiceHeader?: Document;
	bookingConfirmationFooter?: Document;

	score?: ReviewScore;

	commission: number;
	baseFee: Money;

	csBusCompanyId?: string;
	csBusDefaultActive: boolean;
	csBusDefaultPaymentOnInvoice: boolean;
	csBusImportNotice: boolean;
	csBusStopNoTime: boolean;
	csBusBookableUntilDays: number;
	csBusDeposit?: number;
	csBusDepositDays?: number;

	constructor(json: any) {
		super(json);
		this.name = json.name;
		this.logo = Document.fromJson(json.logo);
		this.contactData = ContactData.fromJson(json.contactData);
		this.location = Location.fromJson(json.location)!;

		this.locale = json.locale;
		this.currency = Currency[json.currency as CurrencyKey];

		this.generalConditions = json.generalConditions;
		this.paymentConditions = json.paymentConditions;
		this.cancellationConditions = json.cancellationConditions;
		this.privacyPolicy = json.privacyPolicy;

		this.packageTourInfo = json.packageTourInfo;
		this.stopTimeInfo = json.stopTimeInfo;
		this.invoiceMarginalTaxText = json.invoiceMarginalTaxText;
		this.invoiceRegularTaxText = json.invoiceRegularTaxText;
		this.invoiceHeader = Document.fromJson(json.invoiceHeader);
		this.bookingConfirmationFooter = Document.fromJson(json.bookingConfirmationFooter);

		this.score = ReviewScore.fromJson(json.score);

		this.commission = json.commission;
		this.baseFee = Money.fromJson(json.baseFee);

		this.csBusCompanyId = json.csBusCompanyId;
		this.csBusDefaultActive = json.csBusDefaultActive;
		this.csBusDefaultPaymentOnInvoice = json.csBusDefaultPaymentOnInvoice;
		this.csBusImportNotice = json.csBusImportNotice;
		this.csBusStopNoTime = json.csBusStopNoTime;
		this.csBusBookableUntilDays = json.csBusBookableUntilDays;
		this.csBusDeposit = json.csBusDeposit;
		this.csBusDepositDays = json.csBusDepositDays;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.COMPANY;
	}

	getCompanyName(): string {
		return this.contactData.companyData.name || this.name;
	}

	static formFields(company?: Company): CompanyFormFields {
		return {
			...BaseEntity.formFields(company),
			name: company?.name,
			logo: company?.logo,
			contactData: ContactData.formValues(company?.contactData),
			location: company?.location,

			locale: company?.locale,
			currency: company?.currency,

			generalConditions: company?.generalConditions,
			paymentConditions: company?.paymentConditions,
			cancellationConditions: company?.cancellationConditions,
			privacyPolicy: company?.privacyPolicy,

			packageTourInfo: company?.packageTourInfo,
			stopTimeInfo: company?.stopTimeInfo,
			invoiceMarginalTaxText: company?.invoiceMarginalTaxText,
			invoiceRegularTaxText: company?.invoiceRegularTaxText,
			invoiceHeader: company?.invoiceHeader,
			bookingConfirmationFooter: company?.bookingConfirmationFooter,

			commission: company?.commission,
			baseFee: company?.baseFee,

			csBusCompanyId: company?.csBusCompanyId,
			csBusDefaultActive: company?.csBusDefaultActive,
			csBusDefaultPaymentOnInvoice: company?.csBusDefaultPaymentOnInvoice,
			csBusImportNotice: company?.csBusImportNotice,
			csBusStopNoTime: company?.csBusStopNoTime,
			csBusBookableUntilDays: company?.csBusBookableUntilDays,
			csBusDeposit: company?.csBusDeposit,
			csBusDepositDays: company?.csBusDepositDays,
		};
	}
}
