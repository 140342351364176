import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import Location from 'src/entities/basic-types/Location';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';

export default class Stop extends BaseEntity {
	name: string;
	location: Location;
	shortDescription: string;
	readonly csBusStopId?: string;

	constructor(json: any) {
		super(json);

		this.name = json.name;
		this.location = Location.fromJson(json.location)!;
		this.shortDescription = json.shortDescription;
		this.csBusStopId = json.csBusStopId;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.STOP;
	}

	static formFields(entity?: Stop): AutoFormFields<Stop> {
		return {
			id: entity?.id,
			name: entity?.name,
			location: entity?.location,
			shortDescription: entity?.shortDescription,
			csBusStopId: entity?.csBusStopId,
		};
	}
}
