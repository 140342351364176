import BasicType from 'src/entities/basic-types/BasicType';
import { AutoFormFields } from '../BaseEntity';

export default class PaymentSettings implements BasicType {
	enableOnlinePayment: boolean;
	apiKey: string;
	merchantId: string;

	constructor(json: any) {
		this.enableOnlinePayment = json.enableOnlinePayment;
		this.apiKey = json.apiKey;
		this.merchantId = json.merchantId;
	}

	toString(): string {
		return this.merchantId;
	}

	equals(other: PaymentSettings): boolean {
		return this.apiKey === other.apiKey && this.merchantId === other.merchantId;
	}

	static formFields(paymentSettings?: PaymentSettings): AutoFormFields<PaymentSettings> {
		return {
			enableOnlinePayment: paymentSettings?.enableOnlinePayment ?? false,
			apiKey: paymentSettings?.apiKey ?? '',
			merchantId: paymentSettings?.merchantId ?? '',
		};
	}
}
