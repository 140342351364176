import Company from 'src/entities/companies/Company';
import CustomerInvoice from 'src/entities/invoice/customer/CustomerInvoice';
import Booking from 'src/entities/booking/Booking';

export default class CustomerInvoiceFull extends CustomerInvoice {
	company: Company;
	booking: Booking;

	constructor(json: any) {
		super(json);
		this.company = new Company(json.company);
		this.booking = new Booking(json.booking);
	}
}
