import Category from 'src/entities/category/Category';

export default class CategoryBooking extends Category {
	readonly root?: Category;
	readonly parent?: Category;

	constructor(json: any) {
		super(json);

		this.root = json.root ? new Category(json.root) : undefined;
		this.parent = json.parent ? new Category(json.parent) : undefined;
	}

	getRootLocalizedTitle(): string | undefined {
		return this.root != null ? this.root.getLocalizedTitle() : this.getLocalizedTitle();
	}
}
