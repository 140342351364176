import Money from 'src/entities/basic-types/Money';
import Passenger from 'src/entities/booking/Passenger';
import ProductsPerPassenger from 'src/entities/booking/ProductsPerPassenger';
import {
	BookingProgressStep,
	BookingProgressStepError,
	BookingStep,
	BookingStepMap,
	GAItem,
} from 'src/entities/booking/progress/steps/BookingProgressStep';
import InsuranceStep from 'src/entities/booking/progress/steps/InsuranceStep';
import PassengerDataStep from 'src/entities/booking/progress/steps/PassengerDataStep';
import PaxStep from 'src/entities/booking/progress/steps/PaxStep';
import ProductStep from 'src/entities/booking/progress/steps/ProductStep';
import SummaryStep from 'src/entities/booking/progress/steps/SummaryStep';
import BundleBookingDetails from 'src/entities/bundle/BundleBookingDetails';
import { bookingProgressStepFromJson } from 'src/entities/factories/booking-step-factory';
import { fromJsonArray, fromJsonArrayWith } from 'src/helper/helper';

export default class BookingProgressRow {
	bundle: BundleBookingDetails;
	currentStep: BookingStep;
	steps: BookingProgressStep[];
	price: Money;
	priceDeviation: boolean;
	start: boolean;
	insurancePrice?: Money;
	productsPerPassenger: ProductsPerPassenger[];

	constructor(
		bundle: BundleBookingDetails,
		currentStep: BookingStep,
		steps: BookingProgressStep[],
		price: Money,
		priceDeviation: boolean,
		productsPerPassenger: ProductsPerPassenger[],
		insurancePrice?: Money,
	) {
		this.bundle = bundle;
		this.currentStep = currentStep;
		this.steps = steps;
		this.price = price;
		this.priceDeviation = priceDeviation;
		this.start = false;
		this.insurancePrice = insurancePrice;
		this.productsPerPassenger = productsPerPassenger;
	}

	static fromJson(json: any): BookingProgressRow {
		return new BookingProgressRow(
			new BundleBookingDetails(json.bundle),
			json.currentStep,
			fromJsonArrayWith(bookingProgressStepFromJson, json.steps),
			Money.fromJson(json.price),
			json.priceDeviation,
			json.productsPerPassenger ? fromJsonArray(ProductsPerPassenger, json.productsPerPassenger) : [],
			json.insurancePrice ? Money.fromJson(json.insurancePrice) : undefined,
		);
	}

	getGAItems(): GAItem[] {
		const all: GAItem[] = [];

		this.steps.forEach(step => {
			all.push(...step.getGAItems());
		});

		return all;
	}

	getLastCompletedStep(): BookingProgressStep | undefined {
		return this.steps
			.slice()
			.reverse()
			.find(step => step.completed);
	}

	getCurrentStep(): BookingProgressStep | undefined {
		return this.steps.find(step => step.name === this.currentStep);
	}

	getCurrentStepIndex(): number {
		return this.steps.findIndex(step => step.name === this.currentStep);
	}

	getPrevStep(): BookingProgressStep | undefined {
		const currentStepIndex = this.getCurrentStepIndex();
		return currentStepIndex > 0 ? this.steps[currentStepIndex - 1] : undefined;
	}

	getNextStep(): BookingProgressStep | undefined {
		const currentStepIndex = this.getCurrentStepIndex();
		return currentStepIndex < this.steps.length - 1 ? this.steps[currentStepIndex + 1] : undefined;
	}

	getAllProductStepsAreCompleted(): boolean {
		return this.steps
			.filter(step =>
				[BookingStep.TICKET, BookingStep.ROOM, BookingStep.JOURNEY, BookingStep.GENERAL_PRODUCTS].includes(
					step.name,
				),
			)
			.every(step => step.completed);
	}

	getSpecificStep<S extends BookingStep>(specific: S): BookingStepMap<S> | undefined {
		return this.steps.find(step => step.name === specific) as BookingStepMap<S> | undefined;
	}

	getSpecificStepIndex(specific: BookingStep): number {
		return this.steps.findIndex(step => step.name === specific);
	}

	setCurrentStep(updatedStep: BookingProgressStep): boolean {
		const currentStepIndex = this.steps.findIndex(step => step.name === this.currentStep);

		if (currentStepIndex !== -1) {
			this.steps[currentStepIndex] = updatedStep;
			return true;
		}

		return false;
	}

	resetProductSteps() {
		this.resetProductStep(this.getSpecificStepIndex(BookingStep.TICKET));
		this.resetProductStep(this.getSpecificStepIndex(BookingStep.JOURNEY));
		this.resetProductStep(this.getSpecificStepIndex(BookingStep.ROOM));
		this.resetProductStep(this.getSpecificStepIndex(BookingStep.GENERAL_PRODUCTS));
	}

	resetProductStep(stepIndex: number): boolean {
		if (stepIndex === -1) return false;

		const step = this.steps[stepIndex] as ProductStep;
		step.resetItems();
		this.steps[stepIndex] = step;

		return true;
	}

	getPax(): number {
		if (this.steps.length > 0) {
			const paxStep = this.getSpecificStep(BookingStep.PAX);

			if (paxStep && paxStep instanceof PaxStep) return paxStep.pax;
		}

		return 1;
	}

	setPax(pax: number): boolean {
		if (this.steps.length > 0) {
			const paxStep = this.getSpecificStep(BookingStep.PAX);

			if (paxStep && paxStep instanceof PaxStep) {
				paxStep.setPax(pax);
				return true;
			}
		}

		return false;
	}

	getPassengers(): Passenger[] {
		if (this.steps.length > 0) {
			const passengerDataStep = this.getSpecificStep(BookingStep.PASSENGER_DATA);
			const paxStep = this.getSpecificStep(BookingStep.PAX);

			if (passengerDataStep && passengerDataStep instanceof PassengerDataStep) {
				return passengerDataStep.passengers.map(p => Passenger.fromPaxStepPassenger(p));
			}
			if (paxStep && paxStep instanceof PaxStep) {
				return paxStep.paxStepPassengers.map(paxStepPassenger =>
					Passenger.fromPaxStepPassenger(paxStepPassenger),
				);
			}
		}
		return [];
	}

	setCheckedTerms(checked: boolean): boolean {
		if (this.steps.length > 0) {
			const summaryStep = this.getSpecificStep(BookingStep.SUMMARY);

			if (summaryStep && summaryStep instanceof SummaryStep) {
				summaryStep.checkedTerms = checked;
				return true;
			}
		}

		return false;
	}

	setBookingProgressStepError(step: BookingStep, error: BookingProgressStepError): boolean {
		if (this.steps.length > 0) {
			const errorStep = this.getSpecificStep(step);

			if (errorStep) {
				errorStep.error = error;
				return true;
			}
		}

		return false;
	}

	setInsuranceProgressError(): boolean {
		if (this.steps.length > 0) {
			const insuranceStep = this.getSpecificStep(BookingStep.INSURANCE);

			if (insuranceStep && insuranceStep instanceof InsuranceStep) {
				insuranceStep.applyInsuranceProgressError();
				return true;
			}
		}

		return false;
	}

	setCheckedPrivacy(checked: boolean): boolean {
		if (this.steps.length > 0) {
			const summaryStep = this.getSpecificStep(BookingStep.SUMMARY);

			if (summaryStep && summaryStep instanceof SummaryStep) {
				summaryStep.checkedPrivacy = checked;
				return true;
			}
		}

		return false;
	}
}
