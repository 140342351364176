import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginFormFields } from 'src/components/authentication/LoginForm';
import { AccountFull } from 'src/entities/accounts/AccountFull';
import Role from 'src/entities/accounts/Role';
import Company from 'src/entities/companies/Company';
import { singleEntityDefaultState } from 'src/redux/selectors';
import { store } from 'src/redux/store';
import { SingleEntityState, StoreState } from 'src/types';
import { login, logout } from '../actions/account';

/**
 * Selects the authenticated account from the redux-store state
 */
export const getOwnAccount = (state: StoreState): SingleEntityState<AccountFull> => {
	if (isEmpty(state.ownAccount)) return singleEntityDefaultState;

	return {
		isFetching: state.ownAccount.isFetching,
		error: state.ownAccount.error,
		content: state.ownAccount.content ?? undefined,
	};
};

/**
 * Checks if the user is logged in.
 */
export const isLoggedIn = (): boolean => {
	const ownAccount = getOwnAccount(store.getState());
	return ownAccount.content != null;
};

/**
 * Checks if the current user is logged in with one of the given roles.
 */
export const isLoggedInWithRole = (...roles: Role[]): boolean => {
	const ownAccount = getOwnAccount(store.getState());
	return ownAccount.content != null && roles.includes(ownAccount.content.role);
};

export function useAccount() {
	const account = useSelector(getOwnAccount);
	const dispatch = useDispatch();

	const isLoggedIn = useCallback(() => account.content != null, [account]);

	const isLoggedInWithRole = useCallback(
		(...roles: Role[]) => account.content != null && roles.includes(account.content.role),
		[account],
	);

	const isLoggedInWithCsBusCompany = useCallback(
		() => isLoggedInWithRole(Role.ADMIN, Role.OPERATOR) && account.content?.company?.csBusCompanyId != null,
		[account, isLoggedInWithRole],
	);

	const loginHandler = useCallback(
		(credentials: LoginFormFields, noRedirect?: boolean) => {
			dispatch(login(credentials, noRedirect));
		},
		[dispatch],
	);

	const logoutHandler = useCallback(
		(noRedirect?: boolean) => {
			dispatch(logout(noRedirect));
		},
		[dispatch],
	);

	return {
		account: account.content ?? null,
		accountError: account.error ?? null,
		accountLoading: account.isFetching,
		isLoggedIn,
		isLoggedInWithRole,
		isLoggedInWithCsBusCompany,
		login: loginHandler,
		logout: logoutHandler,
	};
}

export const isLoggedInWithRoleMatchingCompany = (role: Role, company: Company): boolean => {
	const ownAccount = getOwnAccount(store.getState());
	return (
		ownAccount.content?.company != null &&
		role === ownAccount.content.role &&
		company.id === ownAccount.content.company.id
	);
};

export const isLoggedInWithCsBusCompany = (): boolean => {
	const ownAccount = getOwnAccount(store.getState());
	return (
		ownAccount.content != null &&
		(ownAccount.content.role === Role.ADMIN || ownAccount.content.role === Role.OPERATOR) &&
		ownAccount.content.company?.csBusCompanyId != null
	);
};

export const isAdminOrOperatorMatchingCompany = (
	account: SingleEntityState<AccountFull>,
	companyId: number,
): boolean => {
	return (
		account.content?.company != null &&
		(account.content.role === Role.ADMIN ||
			(account.content.role === Role.OPERATOR && account.content.company.id === companyId))
	);
};
