import { Component } from 'react';
import Role from 'src/entities/accounts/Role';
import { CompiledRoutes, S_ACCESS, S_COMPONENT } from 'src/routing';

export const ACCOUNT_ROOT = 'account';

export const ACCOUNT_ROUTES = new CompiledRoutes(ACCOUNT_ROOT, {
	[S_COMPONENT]: null,
	[S_ACCESS]: [Role.NOT_AUTHENTICATED],
	login: {
		[S_COMPONENT]: Component,
	},
	register: {
		[S_COMPONENT]: Component,
	},
	forgot: {
		[S_COMPONENT]: Component,
	},
	reset: {
		[S_COMPONENT]: Component,
	},
	manage: {
		[S_COMPONENT]: null,
		[S_ACCESS]: [Role.AUTHENTICATED],
		profile: {
			[S_COMPONENT]: Component,
		},
		password: {
			[S_COMPONENT]: Component,
		},
		username: {
			[S_COMPONENT]: Component,
		},
		bookings: {
			[S_COMPONENT]: Component,
			[S_ACCESS]: [Role.CUSTOMER, Role.RESELLER],
			':id': {
				[S_COMPONENT]: Component,
			},
		},
		company: {
			[S_COMPONENT]: Component,
			[S_ACCESS]: [Role.OPERATOR, Role.ADMIN],
		},
	},
});
