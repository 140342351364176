import FileSaver from 'file-saver';
import { flatten, get, invoke, reduce, sortBy } from 'lodash';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { useRef } from 'react';
import sanitizeHtml from 'sanitize-html';
import { MEDIA_CONFIG } from 'src/AppMedia';
import { ENV } from 'src/config/env';
import LatLng from 'src/entities/basic-types/Location';
import { CallApiResult } from 'src/services/api';
import { InterpolationMap } from 'src/types';
import URI from 'urijs';

export const getIdFromEntityLink = (repository: string, entityLink: string): number | undefined => {
	const id = Number(entityLink.slice(`${ENV.apiUrl}${repository}/`.length));
	return Number.isNaN(id) ? undefined : id;
};

export const getIdsFromEntityLinks = (repository: string, entityLinks: string[]): number[] => {
	const ids: number[] = [];

	if (!entityLinks) return ids;

	entityLinks.forEach((link: string) => {
		const id = getIdFromEntityLink(repository, link);
		if (id) ids.push(id);
	});

	return ids;
};

/**
 * Creates an array of the given type, by using the constructor on each element in a JSON array.
 */
export function fromJsonArray<T>(constructor: { new (json: any): T }, json: any): T[] {
	return json != null && Array.isArray(json) ? json.map((element: any) => new constructor(element)) : [];
}

/**
 * Creates an array of the given type, by using the given provider on each element in a JSON array.
 */
export function fromJsonArrayWith<T>(provider: (json: any) => T | undefined, json: any): T[] {
	return json != null && Array.isArray(json) ? json.map((element: any) => provider(element)!) : [];
}

/**
 * Creates an array of strings sorted numerically.
 */
export function fromJsonArraySortedNumerically(json: any): string[] {
	return json != null && Array.isArray(json) ? (sortBy(json, i => Number.parseInt(i, 10)) as string[]) : [];
}

/**
 * Creates an array of strings sorted numerically, joined to a string, or null if empty.
 */
export function fromJsonArraySortedNumericallyJoined(json: any): string | undefined {
	const array = fromJsonArraySortedNumerically(json);
	return array.length > 0 ? array.join(',') : undefined;
}

/**
 * Returns the given value as a valid {@link Number}
 * or undefined if it cannot be converted
 */
export const getNumberOrUndefined = (value?: number | string | null): number | undefined => {
	if (value == null) return undefined;

	const num = Number(value);
	if (Number.isNaN(num)) return undefined;

	return num;
};

/**
 * Returns the given value as a valid {@link Number[]}
 * or undefined if it cannot be converted
 */
export const getNumberArrayOrUndefined = (arrayishValue?: string | string[] | null): number[] | undefined => {
	if (arrayishValue == null) return undefined;

	return Array.isArray(arrayishValue) ? arrayishValue.map(Number) : [Number(arrayishValue)];
};

const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Match strings like
 *  - 2024-06-07T22:00:00.000[Europe/Vienna]
 *  - 2024-06-07T22:00:00.000Z
 *  - 2023-11-12T10:00:00+01:00[Europe/Zagreb]
 * in two groups (date/time and timezone).
 * When the timezone is 'Z', the second group is null.
 */
const ZONED_DATE_TIME_REGX = /([0-9-]+T[0-9:.+-]+)(?:\[([^\]]+)]|Z)/;

export const momentDateFromDate = (date: Date): moment.Moment | undefined => {
	if (!moment(date, BACKEND_DATE_FORMAT, true).isValid()) return undefined;
	return moment(date);
};

export const momentDateFromQueryParameter = (query: string): moment.Moment | undefined => {
	if (!query || !moment(query, BACKEND_DATE_FORMAT, true).isValid()) return undefined;

	return moment(query);
};

export const momentDateToISOString = (date: moment.Moment | undefined | null): string | undefined => {
	if (!date || !moment.isMoment(date)) return undefined;

	return date.format(BACKEND_DATE_FORMAT);
};

export const momentWithTimeZoneFromJson = (json: string): moment.Moment => {
	const parts = json.match(ZONED_DATE_TIME_REGX);
	if (parts == null || parts.length < 2) throw new Error(`Could not parse zonedDateTime string: ${json}`);
	return moment.tz(parts[1], parts[2] || 'UTC');
};

export const momentWithTimeZoneToISOString = (value: moment.Moment | undefined, timeZone?: string): string | null => {
	if (!value || !moment.isMoment(value)) return null;

	const zone = timeZone ?? value.tz();

	return `${value.toISOString(true)}[${zone}]`;
};

export function joinToString(separator: string, ...strings: string[]): string {
	return strings
		.filter(value => value)
		.join(separator)
		.trim();
}

export function richTextIsEmpty(richText: string): boolean {
	return (
		richText == null ||
		sanitizeHtml(richText, {
			allowedTags: [],
			allowedAttributes: {},
		}) === ''
	);
}

/**
 * Creates menu items consisting of months in the range of the given dates
 */
export const createMonthMenuItems = (oldestDate: moment.Moment, newestDate: moment.Moment) => {
	const months = moment.duration(newestDate.diff(oldestDate)).asMonths();
	const monthMenuItems = [];

	for (let i = 0; i < months; i++) {
		const currentMonth = oldestDate.clone().add(i, 'month');
		monthMenuItems.push({
			value: currentMonth.format('YYYY-MM'),
			text: currentMonth.format('MMMM YYYY'),
		});
	}

	return monthMenuItems.reverse();
};

/**
 * Checks if an array of numbers contains a given id.
 * Useful to check if some item is in filterResult array.
 */
export const isFilterMatch = (matchingId: number, ids?: number[]): boolean => {
	if (!ids) return false;
	return flatten(ids).includes(matchingId);
};

export const saveResultAsFile = (result: CallApiResult, name: string) => {
	const { response, error } = result;

	if (error) console.error(`Error generating ${name}`, error);
	else
		response.blob().then((blob: any) => {
			FileSaver.saveAs(blob, name);
		});
};

export const openTargetBlankAndFocus = (url: string) => {
	const win = window.open(url, '_blank');
	if (win != null) win.focus();
};

export const interpolateString = (interpolationString: string, interpolationMap: InterpolationMap) => {
	return reduce(
		interpolationMap,
		(res: string, val: string, key: string) => {
			res = res.replace(key, val);
			return res;
		},
		interpolationString,
	);
};

export const getQueryParameter = (uri: Location | string, key: string): string | undefined => {
	return URI(uri).query(true)[key];
};

/**
 * Get a value by key from a object (like lodash `get`), with additional support for invoking functions.
 *
 * Only functions without parameters are supported.
 *
 * E.g. dataKey: `getFirstRow().pax` invokes `getFistRow()` on the value and then gets the `pax` from the result.
 */
export function getCellValue(object: any, dataKey: string): any {
	let cell = object;

	const keyParts = dataKey.split('.');

	for (const part of keyParts) {
		// Part is invokable
		if (part.endsWith('()')) {
			const fnKey = part.slice(0, -2);
			cell = invoke(cell, fnKey);
		} else {
			cell = get(cell, part);
		}
	}

	return cell;
}

/**
 * Call the `render` function `count` times and return the results in an array.
 */
export const repeatRender = (length: number, render: (index: number) => React.ReactNode): React.ReactNode[] =>
	Array.from({ length }, (_, i) => render(i));

/**
 * Format a number as ordinal in the current language, e.g. 1 as "1st" (en) or "1." (de).
 */
export const formatOrdinal = (num: number) => numeral(num).format('0o');

export const calculateDistance = (a: LatLng, b: LatLng): number => {
	// Convert to radians
	const rad = (d: number) => (d * Math.PI) / 180;

	// Radius of earth
	const R = 6371e3;

	// Conversions to radians
	const latA = rad(a.lat);
	const latB = rad(b.lat);
	const dLat = rad(b.lat - a.lat);
	const dLng = rad(b.lng - a.lng);

	// Square of half the chord length between the points
	const h = Math.sin(dLat / 2) ** 2 + Math.cos(latA) * Math.cos(latB) * Math.sin(dLng / 2) ** 2;

	// Angular Distance
	const c = 2 * Math.atan2(Math.sqrt(h), Math.sqrt(1 - h));

	// Distance scaled to earth radius
	return R * c;
};

export const isMobile = () => {
	const maxMobileWidth = MEDIA_CONFIG.breakpoints.mobile;

	if (maxMobileWidth === undefined) return false;

	return window.innerWidth <= maxMobileWidth;
};

export const objectTypedKeys = Object.keys as <T>(o: T) => Array<keyof T>;

interface IUseTimeout {
	fire: (cb: TimerHandler, ms: number) => void;
	clear: () => void;
	isActive: () => boolean;
}

export function useTimeout(): IUseTimeout {
	const timeout = useRef<number>();

	return {
		fire(cb, ms) {
			if (timeout.current) clearTimeout(timeout.current);
			timeout.current = setTimeout(cb, ms);
		},
		clear() {
			if (timeout.current) clearTimeout(timeout.current);
		},
		isActive() {
			return timeout.current != null;
		},
	};
}

interface IUseInterval {
	start: (cb: TimerHandler, ms: number) => void;
	stop: () => void;
}

export function useInterval(): IUseInterval {
	const interval = useRef<number>();

	return {
		start(cb, ms) {
			if (interval.current) clearInterval(interval.current);
			interval.current = setInterval(cb, ms);
		},
		stop() {
			if (interval.current) clearInterval(interval.current);
		},
	};
}
