import BasicType from 'src/entities/basic-types/BasicType';
import { AutoFormFields } from '../BaseEntity';

export default class SocialMedia implements BasicType {
	facebook: string;
	instagram: string;
	twitter: string;

	constructor(facebook: string, instagram: string, twitter: string) {
		this.facebook = facebook;
		this.instagram = instagram;
		this.twitter = twitter;
	}

	toString(): string {
		return `Facebook: ${this.facebook}, Instagram: ${this.instagram}, Twitter: ${this.twitter}`;
	}

	equals(other: SocialMedia): boolean {
		return this.facebook === other.facebook && this.instagram === other.instagram && this.twitter === other.twitter;
	}

	static fromJson(json: any) {
		return new SocialMedia(json.facebook, json.instagram, json.twitter);
	}

	static emptySocialMedia(): SocialMedia {
		return new SocialMedia('', '', '');
	}

	static formFields(socialMedia?: SocialMedia): AutoFormFields<SocialMedia> {
		return {
			facebook: socialMedia?.facebook ?? '',
			instagram: socialMedia?.instagram ?? '',
			twitter: socialMedia?.twitter ?? '',
		};
	}
}
