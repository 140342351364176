import { TOptions } from 'i18next';
import EntityDescription from 'src/entities/description/EntityDescription';
import { action } from 'src/redux/actions';
import { AdditionalNotificationProps, StatusType } from 'src/types';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export const clearNotification = () => action(CLEAR_NOTIFICATION);

export const setNotification = (
	notificationType: StatusType,
	statusCode: number | undefined,
	requestMethod: string,
	entityDescription?: EntityDescription,
	additionalNotificationProps?: AdditionalNotificationProps,
	key?: string,
	options?: TOptions,
) =>
	action(SET_NOTIFICATION, {
		notificationType,
		statusCode,
		requestMethod,
		entityDescription,
		additionalNotificationProps,
		key,
		options,
	});
