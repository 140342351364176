import Money from 'src/entities/basic-types/Money';
import BundleBookingDetails from 'src/entities/bundle/BundleBookingDetails';
import BaseEntity from 'src/entities/BaseEntity';
import EntityDescription from 'src/entities/description/EntityDescription';
import { EntityDescriptions } from 'src/entities/description';
import Passenger from 'src/entities/booking/Passenger';
import ReviewFull from 'src/entities/review/ReviewFull';
import { fromJsonArray } from 'src/helper/helper';
import moment from 'moment-timezone';
import CombinedBookingItem from 'src/entities/booking/progress/CombinedBookingItem';
import ProductsPerPassenger from 'src/entities/booking/ProductsPerPassenger';

export default class BookingRow extends BaseEntity {
	bundle: BundleBookingDetails;
	items: CombinedBookingItem[];
	passengers: Passenger[];
	pax: number;
	cancelled: boolean;
	readonly cancelledAt?: moment.Moment;
	changedExternally: boolean;
	readonly price: Money;
	readonly bundleBasePrice: Money;
	review?: ReviewFull;
	productsPerPassenger: ProductsPerPassenger[];
	metaFlags: Record<string, boolean>;

	constructor(json: any) {
		super(json);

		this.bundle = new BundleBookingDetails(json.bundle);
		this.items = fromJsonArray(CombinedBookingItem, json.items);
		this.passengers = fromJsonArray(Passenger, json.passengers);
		this.pax = Number(json.pax);
		this.cancelled = json.cancelled;
		this.changedExternally = json.changedExternally;
		this.cancelledAt = json.cancelledAt ? moment(json.cancelledAt) : undefined;
		this.price = Money.fromJson(json.price);
		this.bundleBasePrice = Money.fromJson(json.bundleBasePrice);
		this.review = json.review ? new ReviewFull(json.review) : undefined;
		this.productsPerPassenger = fromJsonArray(ProductsPerPassenger, json.productsPerPassenger);
		this.metaFlags = json.metaFlags ?? {};
	}

	toString(): string {
		return this.bundle.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.BOOKING_ROW;
	}

	isMarginalTax(): boolean {
		return true;
	}
}
