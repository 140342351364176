import { Component } from 'react';
import Role from 'src/entities/accounts/Role';
import { EntityDescriptions } from 'src/entities/description';
import { CompiledRoutes, S_ACCESS, S_COMPONENT, S_COMPONENT_PROPS, S_ENTITY_DESCRIPTION } from 'src/routing';

export const BACKEND_ROOT = 'backend';

// The BACKEND_ROUTES in Backend.tsx reference all backend components.
// This is bad for code splitting and can cause troubles with cyclic dependencies.
// The components are not needed to build URLs from the routes.
// So the BACKEND_ROUTES are created and exported here without components
// (React.Component as a placeholder).
// This is ugly because it's duplicated, but less ugly than importing all components all the time.
// The same applies to all other routes in src/scenes.
// TODO: find a better solution.

export const BACKEND_ROUTES = new CompiledRoutes(BACKEND_ROOT, {
	[S_COMPONENT]: null,
	[S_ACCESS]: [Role.ADMIN, Role.OPERATOR],
	// Groups global platform stuff
	administrations: {
		[S_COMPONENT]: null,
		[S_ACCESS]: [Role.ADMIN],
		platform: {
			[S_COMPONENT]: null,
			categories: {
				[S_COMPONENT]: Component,
			},
			features: {
				[S_COMPONENT]: Component,
			},
			pages: {
				[S_COMPONENT]: Component,
			},
			reviews: {
				[S_COMPONENT]: Component,
			},
		},
		accounts: {
			[S_COMPONENT]: null,
			admins: {
				[S_COMPONENT]: Component,
			},
			operators: {
				[S_COMPONENT]: Component,
			},
			resellers: {
				[S_COMPONENT]: Component,
			},
			customers: {
				[S_COMPONENT]: Component,
			},
		},
		companies: {
			[S_COMPONENT]: Component,
		},
		integrations: {
			[S_COMPONENT]: Component,
			':id': {
				[S_COMPONENT]: Component,
				[S_ENTITY_DESCRIPTION]: EntityDescriptions.INTEGRATION,
			},
		},
		invoices: {
			[S_COMPONENT]: null,
			company: {
				[S_COMPONENT]: Component,
			},
			admin: {
				[S_COMPONENT]: Component,
			},
		},
		overviews: {
			[S_COMPONENT]: null,
			bookings: {
				[S_COMPONENT]: Component,
				[S_COMPONENT_PROPS]: { isGlobal: true },
				':id': {
					[S_COMPONENT]: Component,
					[S_COMPONENT_PROPS]: { isGlobal: true },
					[S_ENTITY_DESCRIPTION]: EntityDescriptions.BOOKING,
				},
			},
			customers: {
				[S_COMPONENT]: Component,
				[S_COMPONENT_PROPS]: { isGlobal: true },
			},
		},
	},
	operations: {
		[S_COMPONENT]: null,
		[S_ACCESS]: [Role.ADMIN, Role.OPERATOR],
		platform: {
			[S_COMPONENT]: null,
			[S_ACCESS]: [Role.ADMIN],
			categories: {
				[S_COMPONENT]: Component,
			},
		},
		commons: {
			[S_COMPONENT]: null,
			locations: {
				[S_COMPONENT]: Component,
			},
			stops: {
				[S_COMPONENT]: Component,
			},
			hotels: {
				[S_COMPONENT]: Component,
			},
		},
		events: {
			[S_COMPONENT]: Component,
			':id': {
				[S_COMPONENT]: Component,
				[S_ENTITY_DESCRIPTION]: EntityDescriptions.EVENT,
			},
		},
		bundles: {
			[S_COMPONENT]: Component,
			':id': {
				[S_COMPONENT]: Component,
				[S_ENTITY_DESCRIPTION]: EntityDescriptions.BUNDLE,
				':id': {
					[S_COMPONENT]: Component,
					[S_ENTITY_DESCRIPTION]: EntityDescriptions.BUNDLE_ITEM,
				},
			},
		},
		overviews: {
			[S_COMPONENT]: null,
			bookings: {
				[S_COMPONENT]: Component,
				':id': {
					[S_COMPONENT]: Component,
					[S_ENTITY_DESCRIPTION]: EntityDescriptions.BOOKING,
				},
			},
			customers: {
				[S_COMPONENT]: Component,
			},
		},
	},
});
