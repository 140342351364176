import Admin from 'src/entities/accounts/Admin';
import Company from 'src/entities/companies/Company';
import { AutoFormFields } from '../BaseEntity';

export type AdminFullFormFields = AutoFormFields<AdminFull>;
export default class AdminFull extends Admin {
	company?: Company;

	constructor(json: any) {
		super(json);
		this.company = json.company ? new Company(json.company) : undefined;
	}

	static formFields(entity?: AdminFull): AdminFullFormFields {
		return {
			...Admin.formFields(entity),
			company: entity?.company?.getLink(),
		};
	}
}
