import { getOwnAccount, isLoggedIn } from 'src/redux/selectors/account';
import { StoreState } from 'src/types';

/**
 * Returns the link of the company for the authenticated account.
 */
export const getCompanyLinkByState = (state: StoreState): string | null => {
	if (isLoggedIn()) {
		const account = getOwnAccount(state).content!;
		return account.company?.getLink() ?? null;
	}

	return null;
};
export const getCompanyIdByState = (state: StoreState): number | null => {
	if (isLoggedIn()) {
		const account = getOwnAccount(state).content;
		return account!.company!.id;
	}

	return null;
};
