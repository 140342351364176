import BigNumber from 'bignumber.js';
import Money, { Currency } from 'src/entities/basic-types/Money';
import AgreementDescription from 'src/entities/booking/progress/steps/insurance/AgreementDescription';
import DocumentDescription from 'src/entities/booking/progress/steps/insurance/DocumentDescription';
import MessageHolder from 'src/entities/booking/progress/steps/insurance/MessageHolder';
import { fromJsonArray } from 'src/helper/helper';
import { TFunction } from 'i18next';
import { enumMenuItems } from 'src/components/elements/form-inputs/SelectField';

export enum InsuranceProduct {
	NONE = 'NONE',
	BBAKS = 'BBAKS',
	KS = 'KS',
}

const DESCRIPTION_KEY = 'PIP';

export const insuranceProductMenuItems = (t: TFunction) => enumMenuItems(InsuranceProduct, 'insurance_product', t);

export default class V4InsuranceOfferRS {
	token: string;
	productCode: string;
	productName: string;
	productDescription: string;
	varianceCode: string;
	varianceDescription: string;
	totalPremium: Money;
	totalPremiumText: string;

	documentDescription: DocumentDescription[];
	agreementDescription: AgreementDescription[];
	messageHolder: MessageHolder;

	constructor(json: any) {
		this.token = json.token;
		this.productCode = json.productCode;
		this.productName = json.productName;
		this.productDescription = json.productDescription;
		this.varianceCode = json.varianceCode;
		this.varianceDescription = json.varianceDescription;
		this.totalPremium = json.totalPremium?.currency
			? Money.fromJson(json.totalPremium)
			: new Money(new BigNumber(json.totalPremium), Currency.EUR);
		this.totalPremiumText = json.totalPremiumText;
		this.documentDescription = fromJsonArray(DocumentDescription, json.documentDescription);
		this.agreementDescription = fromJsonArray(AgreementDescription, json.agreementDescription);
		this.messageHolder = new MessageHolder(json.messageHolder);
	}

	getInterpolatedProductAgreementText(): string {
		return getDescriptionOrDefault(this.agreementDescription).agreementText;
	}

	getInterpolatedProductDescription(): string {
		return this.productDescription + getDescriptionOrDefault(this.documentDescription).text;

		// if (this.productCode === InsuranceProduct.IN252)
		//     return this.productDescription + this.documentDescription[0].text;
		//
		// const interpolationMap = {
		//     '%aio.label.default%': this.documentDescription[0].label,
		//     '%aio.url.default%': this.documentDescription[0].url,
		// };
		//
		// return interpolateString(this.productDescription, interpolationMap);
	}
}

export function getDescriptionOrDefault<T extends AgreementDescription | DocumentDescription>(description: T[]): T {
	return description.find(a => a.key === DESCRIPTION_KEY) ?? description[0];
}
