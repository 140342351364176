import BasicType from 'src/entities/basic-types/BasicType';
import { AutoFormFields } from '../BaseEntity';

export default class CompanyData implements BasicType {
	name: string;
	vatNumber: string;
	registrationNumber: string;
	gisaNumber: string;
	iban: string;
	bic: string;
	bankName: string;
	jurisdiction: string;

	constructor(
		name: string,
		vatNumber: string,
		registrationNumber: string,
		gisaNumber: string,
		iban: string,
		bic: string,
		bankName: string,
		jurisdiction: string,
	) {
		this.name = name;
		this.vatNumber = vatNumber;
		this.registrationNumber = registrationNumber;
		this.gisaNumber = gisaNumber;
		this.iban = iban;
		this.bic = bic;
		this.bankName = bankName;
		this.jurisdiction = jurisdiction;
	}

	toString(): string {
		return this.name;
	}

	equals(other: CompanyData): boolean {
		return (
			this.name === other.name &&
			this.vatNumber === other.vatNumber &&
			this.registrationNumber === other.registrationNumber &&
			this.gisaNumber === other.gisaNumber &&
			this.iban === other.iban &&
			this.bic === other.bic &&
			this.bankName === other.bankName &&
			this.jurisdiction === other.jurisdiction
		);
	}

	static fromJson(json: any) {
		return new CompanyData(
			json.name,
			json.vatNumber,
			json.registrationNumber,
			json.gisaNumber,
			json.iban,
			json.bic,
			json.bankName,
			json.jurisdiction,
		);
	}

	static emptyCompanyData() {
		return new CompanyData('', '', '', '', '', '', '', '');
	}

	static formFields(companyData?: CompanyData): AutoFormFields<CompanyData> {
		return {
			name: companyData?.name ?? '',
			vatNumber: companyData?.vatNumber ?? '',
			registrationNumber: companyData?.registrationNumber ?? '',
			gisaNumber: companyData?.gisaNumber ?? '',
			iban: companyData?.iban ?? '',
			bic: companyData?.bic ?? '',
			bankName: companyData?.bankName ?? '',
			jurisdiction: companyData?.jurisdiction ?? '',
		};
	}
}
