import { createContext } from 'react';

/**
 * Config of available dialog variants and their associated actions.
 * The order of the actions is the order they will appear in the dialog.
 */
export const DialogVariantActions = {
	confirm: ['cancel', 'ok'],
	delete: ['cancel', 'delete'],
	info: ['ok'],
	form: ['cancel', 'reset', 'submit'],
	crudform: ['cancel', 'save'],
} as const;

/**
 * Extracts union type of the variants from the config.
 */
export type DialogVariant = keyof typeof DialogVariantActions;

/**
 * Extracts union type of the actions associated to a variant from the config.
 */
export type DialogVariantAction<Variant extends DialogVariant = DialogVariant> =
	(typeof DialogVariantActions)[Variant][number];

export interface IDialogContext {
	id: string;
	close(): void;
}

export const DialogContext = createContext<IDialogContext>({
	id: '',
	close() {}, // eslint-disable-line @typescript-eslint/no-empty-function
});
