import { isArray } from 'lodash';
import Location from 'src/entities/basic-types/Location';
import { SortType } from 'src/entities/booking/ResultFilter';
import { getNumberArrayOrUndefined, getNumberOrUndefined } from 'src/helper/helper';
import { ParsedUrlQuery } from 'src/types';

export enum SearchType {
	Events = 'Events',
	Event = 'Event',
	Location = 'Location',
	Category = 'Category',
}
export default class SearchParams {
	search: string;
	searchType: SearchType;
	categories: number[];
	address?: string;
	lat?: number;
	lng?: number;
	sort?: SortType;

	constructor(
		search: string,
		searchType: SearchType,
		categories: number[],
		location?: Location | null,
		sort?: SortType,
	) {
		this.search = search;
		this.searchType = searchType;
		this.categories = categories;
		this.sort = sort ?? SortType.Recommended;

		if (location != null) {
			this.address = location.address;
			this.lat = location.lat;
			this.lng = location.lng;
		}
	}

	/**
	 * Whether a location has been selected for the current search
	 */
	hasLocation(): boolean {
		return this.lat !== undefined && this.lng !== undefined;
	}

	/**
	 * Extracts parameters from the given query and populates a new SearchParams object
	 */
	static fromQuery(query?: ParsedUrlQuery): SearchParams {
		if (query == null) return new SearchParams('', SearchType.Events, []);

		const search = query.search != null ? (query.search as string) : '';
		const categories = getNumberArrayOrUndefined(query.categories as string);

		const address = query.address != null ? (query.address as string) : undefined;

		let lat;
		let lng;
		if (!isArray(query.lat) && !isArray(query.lng)) {
			lat = getNumberOrUndefined(query.lat);
			lng = getNumberOrUndefined(query.lng);
		}

		let location;
		if (address && lat && lng) {
			location = new Location(address, lat, lng);
		}

		const sort = query.sort ? (query.sort as SortType) : SortType.Recommended;
		const searchType = query.searchType ? (query.searchType as SearchType) : SearchType.Events;

		return new SearchParams(search, searchType, categories ?? [], location, sort);
	}

	getLocation(): Location | null {
		if (this.address && this.lat && this.lng) return new Location(this.address, this.lat, this.lng);
		return null;
	}

	setLocation(location: Location | null) {
		this.address = location ? location.address : undefined;
		this.lat = location ? location.lat : undefined;
		this.lng = location ? location.lng : undefined;
	}

	toString(): string {
		return `search=${this.search}, searchType=${this.searchType}, categories=${this.categories},
        address=${this.address ?? '*'},
        lat=${this.lat ?? '*'},
        lng=${this.lng ?? '*'}`;
	}

	getParams(): Record<string, unknown> {
		return {
			search: this.search,
			searchType: this.searchType,
			categories: this.categories,
			address: this.address,
			lat: this.lat,
			lng: this.lng,
			sort: this.sort,
		};
	}
}
