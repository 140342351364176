import moment from 'moment-timezone';
import Money from 'src/entities/basic-types/Money';
import Booking from 'src/entities/booking/Booking';
import BookingProgressRow from 'src/entities/booking/progress/BookingProgressRow';
import CouponBooking from 'src/entities/coupon/CouponBooking';
import { fromJsonArrayWith, momentWithTimeZoneFromJson } from 'src/helper/helper';
import { GAItem } from './steps/BookingProgressStep';

export default class BookingProgress {
	bookingRows: BookingProgressRow[];
	coupon?: CouponBooking;
	readonly price: Money;
	readonly resellerPrice?: Money;
	couponPrice?: Money;
	cheapestPrice: Money;
	readonly depositPrice?: Money;
	readonly insurancePrice?: Money;
	readonly balanceUntil?: moment.Moment;
	readonly resellerDiscount?: Money;
	readonly cheapestPriceWithInsurance: Money;
	couponDiscount?: Money;
	token?: string;

	constructor(
		bookingRows: BookingProgressRow[],
		coupon: CouponBooking | undefined,
		price: Money,
		couponPrice: Money | undefined,
		resellerPrice: Money | undefined,
		cheapestPrice: Money,
		depositPrice: Money | undefined,
		insurancePrice: Money | undefined,
		balanceUntil: moment.Moment | undefined,
		token: string | undefined,
	) {
		this.bookingRows = bookingRows;
		this.coupon = coupon;
		this.price = price;
		this.couponPrice = couponPrice;
		this.resellerPrice = resellerPrice;
		this.cheapestPrice = cheapestPrice;
		this.depositPrice = depositPrice;
		this.insurancePrice = insurancePrice;
		this.balanceUntil = balanceUntil;

		this.resellerDiscount = Booking.calculateResellerDiscount(price, resellerPrice);
		this.couponDiscount = Booking.calculateCouponDiscount(price, resellerPrice, couponPrice);
		this.cheapestPriceWithInsurance = Booking.calculatePriceWithInsurance(cheapestPrice, insurancePrice);

		this.token = token;
	}

	getGAEcommerceData() {
		const items: GAItem[] = [];

		this.bookingRows.forEach(row => {
			items.push(...row.getGAItems());
		});

		return {
			value: this.cheapestPrice.amount.toNumber(),
			currency: this.cheapestPrice.currency,
			items,
		};
	}

	reset(): void {
		const firstRow = this.getFirstRow();
		firstRow.resetProductSteps();
		firstRow.setPax(2);
		this.token = undefined;
	}

	getFirstRow(): BookingProgressRow {
		return this.bookingRows[0];
	}

	setFirstRow(bookingRow: BookingProgressRow) {
		this.bookingRows[0] = bookingRow;
	}

	static fromJson(json: any): BookingProgress {
		return new BookingProgress(
			fromJsonArrayWith(BookingProgressRow.fromJson, json.bookingRows),
			json.coupon ? new CouponBooking(json.coupon) : undefined,
			Money.fromJson(json.price),
			json.couponPrice ? Money.fromJson(json.couponPrice) : undefined,
			json.resellerPrice ? Money.fromJson(json.resellerPrice) : undefined,
			Money.fromJson(json.cheapestPrice),
			json.depositPrice ? Money.fromJson(json.depositPrice) : undefined,
			json.insurancePrice ? Money.fromJson(json.insurancePrice) : undefined,
			json.balanceUntil ? momentWithTimeZoneFromJson(json.balanceUntil) : undefined,
			json.token,
		);
	}
}
