import { TFunction } from 'i18next';
import { emptyMenuItem, enumMenuItems } from 'src/components/elements/form-inputs/SelectField';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import Product from 'src/entities/products/Product';
import { AutoFormFields } from '../BaseEntity';

export type GeneralProductFormFields = AutoFormFields<GeneralProduct>;

export enum ProductCategory {
	TICKET = 'TICKET',
	ROOM = 'ROOM',
	JOURNEY = 'JOURNEY',
}

export const productCategoryMenuItems = (t: TFunction) =>
	[emptyMenuItem].concat(enumMenuItems(ProductCategory, 'entities:generalProduct.relatedCategory.options', t));

export default class GeneralProduct extends Product {
	relatedCategory?: ProductCategory;

	constructor(json: any) {
		super(json);
		this.relatedCategory = json.relatedCategory;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.GENERAL_PRODUCT;
	}

	static formFields(entity?: GeneralProduct): GeneralProductFormFields {
		return {
			...Product.formFields(entity),
			relatedCategory: entity?.relatedCategory,
		};
	}
}
