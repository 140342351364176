import { TFunction } from 'i18next';
import { isString } from 'lodash';
import React from 'react';
import { DropdownProps, Form } from 'semantic-ui-react';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import {
	getValidationLabelProps,
	ValidationLabelProps,
} from 'src/components/elements/form-inputs/ValidationLabelProps';

export interface MenuItem {
	key: number | string;
	text: string;
	value: number | string | undefined;
	description?: string;
	flag?: string;
}

export const emptyMenuItem: MenuItem = {
	key: -1,
	text: '-',
	value: undefined,
};

export const emptyStringMenuItem: MenuItem = {
	key: -1,
	text: '-',
	value: '',
};

export const SelectField = (field: any) => {
	const { input, label, meta, hint, ...rest } = field;
	const validationLabelProps: ValidationLabelProps = getValidationLabelProps(meta, label);
	const handleOnChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
		input.onChange(data.value);
		input.onBlur(data.value);
	};

	return (
		<Form.Dropdown
			label={
				<label>
					{validationLabelProps.label}
					{hint}
				</label>
			}
			error={validationLabelProps.isError}
			value={input.value}
			search={searchFunction}
			selection
			onChange={handleOnChange}
			multiple={input.multiple}
			{...rest}
		/>
	);
};

/**
 * Filters the options by the given value.
 * This search also includes searching through menu item descriptions (not only text)
 */
const searchFunction = (options: DropdownItemProps[], value: string): DropdownItemProps[] => {
	const lowerCaseValue = value.toLowerCase();

	return options.filter((item: DropdownItemProps) => {
		const text = item.text as string;
		const description = item.description as string;

		const a = isString(text) ? text.toLowerCase().includes(lowerCaseValue) : false;
		const b = isString(description) ? description.toLowerCase().includes(lowerCaseValue) : false;

		return a || b;
	});
};

/**
 * Populate array of menu items for enums.
 */
export const enumMenuItems = (enumClass: any, translationKey: string, t: TFunction): MenuItem[] =>
	Object.keys(enumClass).map((key, index) => ({
		key: index,
		value: enumClass[key],
		text: t(`${translationKey}.${key.toLowerCase()}`),
	}));
