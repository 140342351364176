import { TFunction } from 'i18next';
import moment from 'moment-timezone';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Grid, Header, Icon, List, Popup, Rating, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import { MenuItem } from 'src/components/elements/form-inputs/SelectField';
import { countryMenuItems } from 'src/config/countries/countries';
import BundleContingent from 'src/entities/basic-types/BundleContingent';
import Contingent from 'src/entities/basic-types/Contingent';
import Document, { DocumentType } from 'src/entities/basic-types/Document';
import { Gender } from 'src/entities/basic-types/Gender';
import Location from 'src/entities/basic-types/Location';
import Money, { Currency, CurrencyKey, CurrencySymbol } from 'src/entities/basic-types/Money';
import BundleBooked from 'src/entities/bundle/BundleBooked';
import BundleCancelled from 'src/entities/bundle/BundleCancelled';
import { CouponType, isCouponType } from 'src/entities/coupon/Coupon';
import EventLocation from 'src/entities/event-location/EventLocation';
import { InsuranceState } from 'src/entities/insurance/Insurance';
import Invoice from 'src/entities/invoice/Invoice';
import Product from 'src/entities/products/Product';
import StopGroup from 'src/entities/products/stop/StopGroup';
import ReviewFull from 'src/entities/review/ReviewFull';
import { openTargetBlankAndFocus } from 'src/helper/helper';

interface SugarCoaterProps {
	value: any;
	context?: string;
	additionalContext?: string;
	cellOverflow?: boolean;
	onClick?: (cell: any) => void;
}

export const SugarCoater: FC<SugarCoaterProps> = ({ value, context, additionalContext, onClick, cellOverflow }) => {
	const { t } = useTranslation();

	if (value == null) return '-';
	if (contextMatch(context, 'url')) return renderUrl(value);
	if (contextMatch(context, 'gender')) return renderGender(value, t);
	if (contextMatch(context, 'country')) return renderCountry(value);
	if (contextMatch(context, 'currency')) return renderCurrency(value);
	if (contextMatch(context, 'vat')) return renderPercent(value);
	if (contextMatch(context, 'commission')) return renderPercent(value);
	if (contextMatch(context, 'factor')) return renderPercent(value);
	if (contextMatch(context, 'taxType')) return renderTaxType(value, t);
	if (contextMatch(context, 'direction')) return renderDirection(value, t);
	if (contextMatch(context, 'transport')) return renderTransport(value, t);
	if (contextMatch(context, 'relatedCategory')) return renderRelatedCategory(value, t);
	if (contextMatch(context, 'bookableUntil')) return renderMomentPreviousDay(value, t);
	if (contextMatch(context, 'cancellableFreeUntil')) return renderMomentPreviousDay(value, t);
	if (contextMatch(context, 'icon')) return renderIcon(value);
	if (contextMatch(context, 'groups')) return renderStopGroups(value, t);
	if (contextMatch(context, 'getNeedsPassengerInfo()')) return renderNeedsPassengerInfo(value, t);
	if (contextMatch(context, 'insuranceProduct')) return renderInsuranceProduct(value, t);
	if (contextMatch(context, 'getInsuranceState()') || contextMatch(context, 'insuranceState'))
		return renderInsuranceState(value, t);
	if (contextMatch(context, 'bundleBooked')) return renderBundleBooked(value, t);
	if (contextMatch(context, 'bundleCancelled')) return renderBundleCancelled(value, t);
	if (contextMatch(context, 'booked')) return renderBooked(value, t);
	if (contextMatch(context, 'cancelled') && typeof value === 'number') return renderCancelled(value, t);
	if (typeof value === 'boolean') return renderBoolean(value, t, onClick);
	if (moment.isMoment(value)) return renderMoment(value, t, additionalContext);
	if (isMomentPair(value)) return renderMomentPair(value, t);
	if (value instanceof Document) return renderDocument(value);
	if (value instanceof Money && contextMatch(context, 'unstyled')) return renderMoney(value, t, false);
	if (value instanceof Money) return renderMoney(value, t);
	if (value instanceof Contingent) return renderContingent(value, t);
	if (value instanceof BundleContingent) return renderBundleContingent(value, t);
	if (value instanceof Location) return renderLocation(value, t);
	if (value instanceof EventLocation) return renderEventLocation(value, t);
	if (value instanceof Product) return renderProduct(value, t);
	if (value instanceof ReviewFull) return renderReview(value, t);
	if (isCouponType(value)) return renderCouponType(value, t);
	if (contextMatch(context, 'discount')) return renderPercent(value);
	if (cellOverflow) return <div className="cell-overflow">{value.toString()}</div>;
	return value.toString();
};

const isMomentPair = (value: any): boolean =>
	Array.isArray(value) &&
	value.length === 2 &&
	(value[0] == null || moment.isMoment(value[0])) &&
	(value[1] == null || moment.isMoment(value[1]));

const contextMatch = (context: string | undefined, key: string): boolean => {
	if (context == null) return false;

	return context === key || context.endsWith('.' + key);
};

const renderUrl = (value: string) => {
	const url = value.startsWith('http') ? value : `https://${value}`;
	const onClick = (event: React.SyntheticEvent) => {
		openTargetBlankAndFocus(url);
		event.preventDefault();
		event.stopPropagation();
	};
	return <a onClick={onClick}>{value}</a>;
};

const renderCountry = (value: string) => {
	const country = countryMenuItems.find((countryMenuItem: MenuItem) => countryMenuItem.value === value);
	return country ? country.text : '-';
};

const renderGender = (value: Gender, t: TFunction) => (value ? t(`gender.${value.toLowerCase()}`) : '-');

const renderCouponType = (value: CouponType, t: TFunction) => (value ? t(`coupon_type.${value.toLowerCase()}`) : '-');

const renderBoolean = (
	value: boolean,
	t: TFunction,
	onClick?: (cell: any) => void,
	popupLabel?: string,
): React.ReactNode => {
	const checkBox = onClick ? (
		<Checkbox checked={value} toggle onClick={onClickBoolean(onClick)} />
	) : (
		<Icon name={value ? 'checkmark box' : 'square outline'} />
	);

	const popupValue = t(`boolean.${value}`);
	const popupContent = popupLabel ? `${popupLabel}: ${popupValue}` : popupValue;
	return <Popup position="top center" trigger={checkBox} content={popupContent} />;
};

const renderIcon = (value: string): React.ReactNode => <Icon name={value as SemanticICONS} />;

const onClickBoolean =
	(onClick: (cell: any) => void) => (event: React.MouseEvent<HTMLInputElement>, data: CheckboxProps) => {
		onClick(data.checked);
	};

const renderMoment = (value: moment.Moment | undefined, t: TFunction, additionalContext?: string): React.ReactNode => {
	if (value == null) return '-';

	// if moment input on creation was only a date -> format date
	if (value.creationData().format === 'YYYY-MM-DD') return noBreakText(t('format:date', { date: value }));

	const timeZone = value.tz();
	const dateWithTimeInTooltip = timeZone !== undefined && additionalContext === 'dateWithTimeInTooltip';
	const formattedDateTime = noBreakText(
		dateWithTimeInTooltip ? t('format:date', { date: value }) : t('format:dateTime', { dateTime: value }),
	);
	if (timeZone !== undefined) {
		const formattedZonedDateTime = t('format:zonedDateTime', { zonedDateTime: value });
		return (
			<Popup
				trigger={<span style={{ textDecoration: 'underline dotted' }}>{formattedDateTime}</span>}
				content={formattedZonedDateTime}
			/>
		);
	}
	return formattedDateTime;
};

const renderMomentPair = (
	value: [moment.Moment | undefined, moment.Moment | undefined],
	t: TFunction,
): React.ReactNode => {
	const start = value[0];
	const end = value[1];
	return (
		<>
			{start != null && renderMoment(start, t)}
			&nbsp;-&nbsp;
			{end != null && renderMoment(end, t)}
		</>
	);
};

export const renderMomentPreviousDay = (value: moment.Moment | undefined, t: TFunction): React.ReactNode => {
	if (value == null) return '-';

	const previousDay = value.clone().subtract(1, 'days').startOf('day');
	const formattedDateTime = noBreakText(t('format:date', { date: previousDay }));
	const timeZone = previousDay.tz();
	if (timeZone !== undefined) {
		const formattedZonedDateTime = t('format:zonedDate', { zonedDate: previousDay });
		return (
			<Popup
				trigger={<span style={{ textDecoration: 'underline dotted' }}>{formattedDateTime}</span>}
				content={formattedZonedDateTime}
			/>
		);
	}
	return formattedDateTime;
};

const renderMoney = (value: Money, t: TFunction, colorize: boolean = true): React.ReactNode => {
	if (value.isZero()) return '-';

	const formattedMoney = noBreakText(value.format());

	return colorize && value.isNegative() ? colorText(formattedMoney, 'green') : formattedMoney;
};

export const renderDocument = (value: Document, invoice?: Invoice): React.ReactNode => {
	const url = invoice != null ? invoice.getInvoiceUrl() : value.getSrc();
	const onClick = () => openTargetBlankAndFocus(url);

	return value.type === DocumentType.PDF ? (
		<Icon name="file pdf" color="red" link onClick={onClick} />
	) : (
		<Icon name="file" onClick={onClick} />
	);
};

const renderContingent = (contingent: Contingent, t: TFunction): React.ReactNode => (
	<>
		{colorText(contingent.total, 'black', t('entities:product.contingent.total.label'))}
		{colorText(' (', 'grey')}
		{colorText(contingent.bookable, 'green', t('entities:product.contingent.bookable.label'))}
		{colorText(')', 'grey')}
	</>
);

const renderBundleContingent = (contingent: BundleContingent, t: TFunction): React.ReactNode => (
	<>
		{colorText(contingent.total ?? '∞', 'teal', t('entities:bundle.contingent.total.label'))}
		{colorText(' / ', 'grey')}
		{colorText(contingent.external ?? '∞', 'violet', t('entities:bundle.contingent.external.label'))}
		{colorText(' (', 'grey')}
		{colorText(contingent.bookableProducts, 'black', t('entities:bundle.bookableProducts'))}
		{colorText(')', 'grey')}
	</>
);

const renderBundleBooked = (bundleBooked: BundleBooked, t: TFunction): React.ReactNode => (
	<>
		{colorText(bundleBooked.bookings, 'green', t('bookings'))}
		{colorText(' (', 'grey')}
		{colorText(bundleBooked.pax, 'black', t('pax'))}
		{colorText(')', 'grey')}
	</>
);

const renderBundleCancelled = (bundleCancelled: BundleCancelled, t: TFunction): React.ReactNode => (
	<>
		{colorText(bundleCancelled.cancelledBookings, 'orange', t('cancelled_bookings'))}
		{colorText(' (', 'grey')}
		{colorText(bundleCancelled.cancelledPax, 'black', t('cancelled_pax'))}
		{colorText(')', 'grey')}
	</>
);

const renderBooked = (booked: number, t: TFunction): React.ReactNode => colorText(booked, 'green');

const renderCancelled = (cancelled: number, t: TFunction): React.ReactNode => colorText(cancelled, 'orange');

const renderLocation = (location: Location, t: TFunction): React.ReactNode => {
	const icon = <Icon name="map marker alternate" />;
	const content = (
		<div>
			<p>
				{t('location.coordinates')}
				:&nbsp;
				{location.lat.toFixed(6)}
				;&nbsp;
				{location.lng.toFixed(6)}
			</p>
			<p>
				{t('location.time_zone')}
				:&nbsp;
				{location.timeZone}
			</p>
		</div>
	);
	return (
		<>
			{location.address}
			&nbsp;
			<Popup position="top center" trigger={icon} content={content} wide />
		</>
	);
};

const renderStopGroups = (stopGroups: StopGroup[], t: TFunction): React.ReactNode => {
	const icon = <Icon name="tags" />;
	const content = (
		<div>
			<Header as="h4">
				<Icon name="tags" />
				<Header.Content>{t('entities:stop.groups.label')}</Header.Content>
			</Header>
			<List bulleted>
				{stopGroups.map((stopGroup: StopGroup) => (
					<List.Item key={stopGroup.id}>{stopGroup.name}</List.Item>
				))}
			</List>
		</div>
	);
	return (
		<>
			{stopGroups.length}
			&nbsp;
			<Popup position="top center" trigger={icon} content={content} wide />
		</>
	);
};

const renderNeedsPassengerInfo = (
	value: {
		needsPassengerName: boolean;
		needsPassengerBirth: boolean;
		needsPassengerPass: boolean;
		needsPassengerGroup: boolean;
	},
	t: TFunction,
): React.ReactNode => (
	<>
		{renderBoolean(value.needsPassengerName, t, undefined, t('entities:bundle.needsPassengerName.label'))}
		{renderBoolean(value.needsPassengerBirth, t, undefined, t('entities:bundle.needsPassengerBirth.label'))}
		{renderBoolean(value.needsPassengerPass, t, undefined, t('entities:bundle.needsPassengerPass.label'))}
		{renderBoolean(value.needsPassengerGroup, t, undefined, t('entities:bundle.needsPassengerGroup.label'))}
	</>
);

const renderEventLocation = (eventLocation: EventLocation, t: TFunction): React.ReactNode => {
	const location = eventLocation.location;
	const icon = <Icon name="map marker alternate" />;
	const content = (
		<div>
			<p>
				{t('location.address')}
				:&nbsp;
				{location.address}
			</p>
			<p>
				{t('location.coordinates')}
				:&nbsp;
				{location.lat.toFixed(6)}
				;&nbsp;
				{location.lng.toFixed(6)}
			</p>
			<p>
				{t('location.time_zone')}
				:&nbsp;
				{location.timeZone}
			</p>
		</div>
	);
	return (
		<>
			{eventLocation.name}
			&nbsp;
			<Popup position="top center" trigger={icon} content={content} wide />
		</>
	);
};

const renderProduct = (product: Product, t: TFunction): React.ReactNode => {
	const description = product.getItemDescription(t);
	const content = <div>{description}</div>;
	if (description !== '') {
		const icon = <Icon name="info circle" />;
		return (
			<>
				<Popup position="top center" trigger={icon} content={content} />
				&nbsp;
				{product.toString()}
			</>
		);
	}
	return product.toString();
};

const renderReview = (review: ReviewFull, t: TFunction): React.ReactNode => {
	const icon = <Icon name="check circle" color={review.active ? 'green' : 'red'} />;
	const content = review.active ? t('booking.review.active.true') : t('booking.review.active.false');
	return (
		<Grid className="horizontally compact" columns={2}>
			<Grid.Row>
				<Grid.Column textAlign="left">
					<Rating icon="star" rating={review.stars()} maxRating={5} size="tiny" disabled />
				</Grid.Column>
				<Grid.Column textAlign="right">
					<Popup position="top center" trigger={icon} content={content} />
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

const renderPercent = (value: any): string => {
	return value == null || value === '0' || value === 0 ? '-' : value + '\u00A0%';
};

const renderCurrency = (currency: Currency): string => CurrencySymbol[currency.toString() as CurrencyKey];

const renderTaxType = (value: string, t: TFunction): string =>
	t('entities:product.taxType.options.' + value.toLowerCase());

const renderDirection = (value: string, t: TFunction): string =>
	t('entities:journey.direction.options.' + value.toLowerCase());

export const renderTransport = (value: string, t: TFunction): string =>
	t('entities:journey.transport.options.' + value.toLowerCase());

const renderRelatedCategory = (value: string, t: TFunction): string =>
	t('entities:generalProduct.relatedCategory.options.' + value.toLowerCase());

const colorText = (
	text: string | number | React.ReactNode,
	color: SemanticCOLORS,
	popupText: string = '',
): React.ReactNode => {
	const textlet = <span className={`text color ${color}`}>{text}</span>;
	return popupText !== '' ? <Popup position="top center" trigger={textlet} content={popupText} /> : textlet;
};

export const noBreakText = (text: string | number | React.ReactNode): React.ReactNode => (
	<span style={{ whiteSpace: 'nowrap' }}>{text}</span>
);

const getInsuranceStateIcon = (state: InsuranceState) => {
	switch (state) {
		case InsuranceState.NO_INSURANCE:
			return <Icon name="close" color="red" />;
		case InsuranceState.PENDING_PAYMENT:
			return <Icon name="exclamation triangle" color="orange" />;
		case InsuranceState.INVALID:
			return <Icon name="exclamation triangle" color="red" />;
		default:
			return <Icon name="check circle" color="green" />;
	}
};

const renderInsuranceState = (value: InsuranceState, t: TFunction) => {
	const icon = getInsuranceStateIcon(value);
	const content = t(`booking.insurance.state.${value.toLowerCase()}`);
	return <Popup position="top center" trigger={icon} content={content} />;
};

const renderInsuranceProduct = (value: InsuranceState, t: TFunction): string =>
	t('insurance_product.' + value.toLowerCase());
