import EventLocation from 'src/entities/event-location/EventLocation';
import { AutoFormFields } from '../BaseEntity';

export type EventLocationFullFormFields = AutoFormFields<EventLocationFull>;
export default class EventLocationFull extends EventLocation {
	constructor(json: any) {
		super(json);
	}

	static formFields(entity?: EventLocation): EventLocationFullFormFields {
		return EventLocation.formFields(entity);
	}
}
