import moment from 'moment-timezone';
import SearchParams from 'src/entities/booking/SearchParams';
import {
	getNumberArrayOrUndefined,
	getNumberOrUndefined,
	momentDateFromQueryParameter,
	momentDateToISOString,
} from 'src/helper/helper';
import { ParsedUrlQuery } from 'src/types';

// Keep strings lowercase so we can use it to interpolate translations
// Or make sure to use toLower (does i18n even care about case?)
export enum SortType {
	Recommended = 'recommended',
	Price = 'price',
	Date = 'date',
	Distance = 'distance',
}

/**
 * Returns all SortType keys depending on the given search params
 */
export const getSortTypeKeys = (searchParams: SearchParams) => {
	const sortTypeArray = Object.keys(SortType) as Array<keyof typeof SortType>;
	if (searchParams.hasLocation()) return sortTypeArray;

	return sortTypeArray.filter(sortIndex => SortType[sortIndex] !== SortType.Distance);
};

interface SanitizedResultFilter {
	minPrice?: number;
	maxPrice?: number;
	travelDurationInDays?: number;
	earliestDeparture?: string;
	latestReturn?: string;
	companies?: number[];
	cata?: number[];
	catb?: number[];
	features?: number[];
	sort?: SortType;
	page?: number;
}

export type ResultFilter = {
	minPrice?: number;
	maxPrice?: number;
	earliestDeparture?: moment.Moment;
	latestReturn?: moment.Moment;
	companies?: number[];
	cata?: number[];
	catb?: number[];
	features?: number[];
	travelDurationInDays?: number;
	page: number;
};

function emptyResultFilter(): ResultFilter {
	return {
		minPrice: undefined,
		maxPrice: undefined,
		travelDurationInDays: undefined,
		earliestDeparture: undefined,
		latestReturn: undefined,
		companies: undefined,
		cata: undefined,
		catb: undefined,
		features: undefined,
		page: 0,
	};
}

export function fromQueryResultFilter(query: ParsedUrlQuery): ResultFilter {
	const filter = emptyResultFilter();

	if (!query) return filter;

	// Filter Values
	filter.minPrice = getNumberOrUndefined(query.minPrice as string);
	filter.maxPrice = getNumberOrUndefined(query.maxPrice as string);
	filter.travelDurationInDays = getNumberOrUndefined(query.travelDurationInDays as string);
	filter.earliestDeparture = momentDateFromQueryParameter(query.earliestDeparture as string);

	// latest return is handled as a date -> therefore we adjust the time to the and of the day
	const latestReturn = momentDateFromQueryParameter(query.latestReturn as string);
	filter.latestReturn = latestReturn ? latestReturn.endOf('day') : undefined;

	// Multivalue Filters
	filter.companies = getNumberArrayOrUndefined(query.companies);
	filter.cata = getNumberArrayOrUndefined(query.cata);
	filter.catb = getNumberArrayOrUndefined(query.catb);
	filter.features = getNumberArrayOrUndefined(query.features);

	filter.page = getNumberOrUndefined(query.page as string) ?? 0;

	return filter;
}

export function sanitizedResultFilter(resultFilter: ResultFilter): SanitizedResultFilter {
	return {
		minPrice: resultFilter.minPrice,
		maxPrice: resultFilter.maxPrice,
		travelDurationInDays: resultFilter.travelDurationInDays,
		companies: resultFilter.companies,
		earliestDeparture: momentDateToISOString(resultFilter.earliestDeparture),
		latestReturn: momentDateToISOString(resultFilter.latestReturn),
		cata: resultFilter.cata,
		catb: resultFilter.catb,
		features: resultFilter.features,
		page: resultFilter.page,
	};
}
