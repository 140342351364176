import { AccountFull } from 'src/entities/accounts/AccountFull';
import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import Category from 'src/entities/category/Category';

export type CategoryFullFormFields = AutoFormFields<CategoryFull> & {
	active?: boolean;
};

export default class CategoryFull extends Category {
	readonly root?: Category;
	parent?: Category;

	constructor(json: any) {
		super(json);

		this.root = json.root ? new Category(json.root) : undefined;
		this.parent = json.parent ? new Category(json.parent) : undefined;
	}

	static formFields(entity?: CategoryFull): CategoryFullFormFields {
		return {
			...Category.formFields(entity),
			active: entity?.active,
			parent: entity?.parent?.getLink(),
		};
	}
}
