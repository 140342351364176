import EntityDescription, { CollectionFetchStrategy } from 'src/entities/description/EntityDescription';
import { objectTypedKeys } from 'src/helper/helper';
import { createEntityActions } from 'src/redux/actions';

/**
 * EntityDescriptions holding information about their name, reducer, repository and actions
 *
 * Every EntityDescription defined here will produce an appropriate reducer and all CRUD actions.
 */
export const EntityDescriptions = {
	COMPANY: new EntityDescription('COMPANY', 'companies', CollectionFetchStrategy.FIND_ALL),
	ADMIN: new EntityDescription('ADMIN', 'admins', CollectionFetchStrategy.FIND_ALL),
	OPERATOR: new EntityDescription('OPERATOR', 'operators', CollectionFetchStrategy.FIND_ALL),
	RESELLER: new EntityDescription('RESELLER', 'resellers', CollectionFetchStrategy.FIND_ALL),
	CUSTOMER: new EntityDescription('CUSTOMER', 'customers', CollectionFetchStrategy.FIND_ALL),
	INTEGRATION: new EntityDescription('INTEGRATION', 'integrations', CollectionFetchStrategy.FIND_ALL),
	PAGE: new EntityDescription('PAGE', 'pages', CollectionFetchStrategy.FIND_ALL),
	CATEGORY: new EntityDescription('CATEGORY', 'categories', CollectionFetchStrategy.FIND_ALL),
	REVIEW_CATEGORY: new EntityDescription('REVIEW_CATEGORY', 'review-categories', CollectionFetchStrategy.FIND_ALL),
	FEATURE: new EntityDescription('FEATURE', 'features', CollectionFetchStrategy.FIND_ALL),
	PASSENGER: new EntityDescription('PASSENGER', 'passengers', CollectionFetchStrategy.FIND_ALL),

	HOTEL: new EntityDescription('HOTEL', 'hotels'),
	STOP: new EntityDescription('STOP', 'stops'),
	TICKET: new EntityDescription('TICKET', 'tickets'),
	ROOM: new EntityDescription('ROOM', 'rooms'),
	JOURNEY: new EntityDescription('JOURNEY', 'journeys'),
	GENERAL_PRODUCT: new EntityDescription('GENERAL_PRODUCT', 'general-products'),
	EVENT_LOCATION: new EntityDescription('EVENT_LOCATION', 'event-locations'),
	EVENT: new EntityDescription('EVENT', 'events'),
	BUNDLE: new EntityDescription('BUNDLE', 'bundles'),
	BUNDLE_ITEM: new EntityDescription('BUNDLE_ITEM', 'bundle-items'),
	TAG: new EntityDescription('TAG', 'tags'),
	STOP_GROUP: new EntityDescription('STOP_GROUP', 'stop-groups'),
	CUSTOMER_DATA: new EntityDescription('CUSTOMER_DATA', 'customer-data'),
	BOOKING: new EntityDescription('BOOKING', 'bookings'),
	BOOKING_ROW: new EntityDescription('BOOKING_ROW', 'booking-rows'),
	BOOKING_ITEM: new EntityDescription('BOOKING_ITEM', 'booking-items'),
	COUPON: new EntityDescription('COUPON', 'coupons'),
	REVIEW: new EntityDescription('REVIEW', 'reviews'),
	CUSTOMER_INVOICE: new EntityDescription('CUSTOMER_INVOICE', 'customer-invoices'),
	COMPANY_INVOICE: new EntityDescription('COMPANY_INVOICE', 'company-invoices'),
	ADMIN_INVOICE: new EntityDescription('ADMIN_INVOICE', 'admin-invoices'),
	INSURANCE: new EntityDescription('INSURANCE', 'INSURANCE'),
};

/**
 * AbstractEntityDescriptions holding information about their name, reducer, repository and actions
 * The backed entity by this description is not instantiable.
 *
 * The defined children represent the instantiable Entities
 *
 * Every AbstractEntityDescriptions defined here will produces
 * an appropriate reducer and all CRUD actions
 */
export const AbstractEntityDescriptions = {
	PRODUCT: new EntityDescription('PRODUCT', 'products', CollectionFetchStrategy.FIND_BY_COMPANY, [
		EntityDescriptions.TICKET,
		EntityDescriptions.ROOM,
		EntityDescriptions.JOURNEY,
		EntityDescriptions.GENERAL_PRODUCT,
	]),
	ACCOUNT: new EntityDescription('ACCOUNT', 'accounts', CollectionFetchStrategy.FIND_BY_COMPANY, [
		EntityDescriptions.ADMIN,
		EntityDescriptions.OPERATOR,
		EntityDescriptions.RESELLER,
		EntityDescriptions.CUSTOMER,
	]),
	INVOICE: new EntityDescription('INVOICE', 'invoices', CollectionFetchStrategy.FIND_BY_COMPANY, [
		EntityDescriptions.CUSTOMER_INVOICE,
		EntityDescriptions.COMPANY_INVOICE,
		EntityDescriptions.ADMIN_INVOICE,
	]),
};

/**
 * Enhance EntityDescriptions with additional properties,
 * such as common api actions
 */
export function bootstrapEntities() {
	objectTypedKeys(EntityDescriptions).forEach(key => {
		createEntityActions(EntityDescriptions[key]);
	});

	objectTypedKeys(AbstractEntityDescriptions).forEach(key => {
		createEntityActions(AbstractEntityDescriptions[key]);
	});
}
