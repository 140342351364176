import { ErrorBoundary } from '@sentry/react';
import { APIProvider } from '@vis.gl/react-google-maps';
import { FC, Suspense, useEffect } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import 'src/config';
import i18next from 'src/config/i18next/i18next';
import { patchSagaContext, persistor, store } from 'src/redux/store';
import { AppMediaProvider } from './AppMedia';
import { ErrorBoundaryHandler } from './components/error/ErrorBoundaryHandler';
import { ENV } from './config/env';
import { GOOGLE_API_VERSION } from './services/google';

export const AppShell: FC = ({ children }) => {
	return (
		<Suspense
			fallback={
				<div className="scene-loader">
					<div className="lds-dual-ring" />
				</div>
			}>
			<AppMediaProvider>
				<ErrorBoundary fallback={ErrorBoundaryHandler}>
					<I18nextProvider i18n={i18next}>
						<GoogleSetup>
							<BrowserRouter>
								<StoreSetup>{children}</StoreSetup>
							</BrowserRouter>
						</GoogleSetup>
					</I18nextProvider>
				</ErrorBoundary>
			</AppMediaProvider>
		</Suspense>
	);
};

const StoreSetup: FC = ({ children }) => {
	const history = useHistory();

	useEffect(() => {
		if (history) patchSagaContext(history);
	}, [history]);

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				{children}
			</PersistGate>
		</Provider>
	);
};

const GoogleSetup: FC = ({ children }) => {
	const { i18n } = useTranslation();

	return (
		<APIProvider apiKey={ENV.googleApiKey} version={GOOGLE_API_VERSION} language={i18n.language}>
			{children}
		</APIProvider>
	);
};
