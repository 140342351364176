import moment from 'moment-timezone';
import { getMomentDateValue } from 'src/components/elements/form-inputs/DateTimeInput';
import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import { Gender } from 'src/entities/basic-types/Gender';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import { joinToString } from 'src/helper/helper';

export interface PaxStepPassenger {
	uuid: string;
	firstName: string;
	lastName: string;
	gender: Gender;
	child: boolean;
}

export type PaxStepPassengerFormFields = AutoFormFields<PaxStepPassenger>;

export type PassengerFormFields = AutoFormFields<Passenger>;

export default class Passenger extends BaseEntity implements PaxStepPassenger {
	uuid: string;
	firstName: string;
	lastName: string;
	gender: Gender;
	child: boolean;

	birthdate?: moment.Moment;
	birthPlace: string;
	passportNumber: string;
	nationality: string;
	passportIssuingAuthority: string;
	passportIssuedOn?: moment.Moment;
	passportValidity?: moment.Moment;
	passengerGroupCode: string;
	cancelled: boolean;

	constructor(json: any) {
		super(json);

		this.uuid = json.uuid;
		this.firstName = json.firstName;
		this.lastName = json.lastName;
		this.gender = json.gender === '' ? undefined : json.gender;
		this.child = json.child || false;

		this.birthdate = getMomentDateValue(json.birthdate);
		this.birthPlace = json.birthPlace;
		this.passportNumber = json.passportNumber;
		this.nationality = json.nationality;
		this.passportIssuingAuthority = json.passportIssuingAuthority;
		this.passportIssuedOn = getMomentDateValue(json.passportIssuedOn);
		this.passportValidity = getMomentDateValue(json.passportValidity);
		this.passengerGroupCode = json.passengerGroupCode;
		this.cancelled = json.cancelled;
	}

	fullName(): string {
		return joinToString(' ', this.firstName, this.lastName);
	}

	toString(): string {
		return this.fullName();
	}

	equals(other: Passenger): boolean {
		return this.uuid === other.uuid;
	}

	static fromPaxStepPassenger(paxStepPassenger: PassengerFormFields): Passenger {
		return new Passenger({
			uuid: paxStepPassenger.uuid,
			gender: paxStepPassenger.gender,
			firstName: paxStepPassenger.firstName,
			lastName: paxStepPassenger.lastName,
			child: paxStepPassenger.child,
			passportNumber: '',
			nationality: '',
			passportIssuingAuthority: '',
			birthPlace: '',
			passengerGroupCode: '',
		});
	}

	static getFormFields(passenger?: Passenger): PassengerFormFields {
		return {
			...(passenger instanceof BaseEntity ? BaseEntity.formFields(passenger) : {}),
			uuid: passenger?.uuid,
			gender: passenger?.gender,
			firstName: passenger?.firstName,
			lastName: passenger?.lastName,
			birthdate: passenger?.birthdate,
			birthPlace: passenger?.birthPlace,
			passportNumber: passenger?.passportNumber,
			nationality: passenger?.nationality,
			passportIssuingAuthority: passenger?.passportIssuingAuthority,
			passportIssuedOn: passenger?.passportIssuedOn,
			passportValidity: passenger?.passportValidity,
			child: passenger?.child,
			passengerGroupCode: passenger?.passengerGroupCode,
		};
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.PASSENGER;
	}
}
