import { getLocalizedText } from 'src/config/i18next/i18next';
import { percentToStars } from 'src/entities/basic-types/ReviewScore';

export default class ReviewEntry {
	reviewCategory: { title: Record<string, string>; order: number };
	rating: number;

	constructor(json: any) {
		this.reviewCategory = json.reviewCategory;
		this.rating = json.rating;
	}

	getLocalizedTitle(): string | undefined {
		return getLocalizedText(this.reviewCategory.title);
	}

	stars() {
		return percentToStars(this.rating);
	}
}
