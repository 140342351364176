import Coupon from 'src/entities/coupon/Coupon';
import Integration from 'src/entities/integration/Integration';
import { AutoFormFields } from '../BaseEntity';

export type CouponFullFormFields = AutoFormFields<CouponFull>;

export default class CouponFull extends Coupon {
	integration: Integration;

	constructor(json: any) {
		super(json);
		this.integration = new Integration(json.integration);
	}

	static formFields(entity?: CouponFull): CouponFullFormFields {
		return {
			...Coupon.formFields(entity),
			integration: entity?.integration.getLink(),
		};
	}
}
