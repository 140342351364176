import { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

/**
 * This Component is only processing if the user came from an integration due missing 3rd party cookies
 * A Cookie will be set, while displaying a loader. Afterwards we redirect back to the integration URL
 */
class RequestCookies extends Component<any, any> {
	componentDidMount() {
		const integrationUrl = this.getIntegrationUrl();

		if (integrationUrl) {
			document.cookie = `${THIRD_PARTY_TEST_COOKIE}=1;secure;SameSite=None;max-age=31536000`;
			setTimeout(() => {
				window.location.replace(integrationUrl);
			}, 500);
		}
	}

	render() {
		const integrationUrl = this.getIntegrationUrl();

		if (integrationUrl)
			return (
				<Dimmer active={true} inverted page>
					<Loader size="large" />
				</Dimmer>
			);

		return null;
	}

	private getIntegrationUrl = () => {
		const { location } = this.props;
		return location.query ? decodeURIComponent(location.query.integrationUrl) : undefined;
	};
}

export default RequestCookies;

export const THIRD_PARTY_TEST_COOKIE = 'THIRD_PARTY_TEST';
