import FormData from 'form-data';
import fetch from 'isomorphic-fetch';
import { ENV } from 'src/config/env';
import { getIntegrationId, getIntegrationUrlAuthority } from 'src/ConnectedApp';
import { APIError } from 'src/entities/error/APIError';
import { saveResultAsFile } from 'src/helper/helper';
import { RequestHeaders } from 'src/types';
import URI from 'urijs';
import UUID from 'uuidjs';

const JSON_HEADERS: RequestHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

const CSRF_HEADER = 'X-CSRF-TOKEN';

export const INTEGRATION_HEADER = 'X-TNT-Integration';
export const INTEGRATION_ID_HEADER = 'X-TNT-Integration-ID';

export const IF_MODIFIED_SINCE_HEADER = 'If-Modified-Since';
export const IF_MATCH_HEADER = 'If-Match';

export enum HttpMethod {
	GET = 'GET',
	POST = 'POST',
	PATCH = 'PATCH',
	PUT = 'PUT',
	DELETE = 'DELETE',
}

export interface CallApiResult {
	response?: any;
	error?: APIError;
}

/**
 * Executes API calls and returns a promise with the response in the appropriate format.
 *
 * Supports JSON and FormData bodies.
 *
 * The additionalHeaders will be added to the request headers.
 *
 * Cookies are included in CORS requests (credentials 'include').
 *
 * If externalRequest is true, no headers and cookies will be set for the request.
 */
export default function callApi(
	endpoint: string,
	method: HttpMethod = HttpMethod.GET,
	body?: any,
	additionalHeaders?: RequestHeaders,
	externalRequest: boolean = false,
): Promise<CallApiResult> {
	if (!endpoint) throw new Error('Missing endpoint');

	// Add the additionalHeaders
	// Also add JSON headers, unless the headers contain an accept header or the body is FormData,
	const isFormData = body instanceof FormData;
	const includesAcceptHeaders = Object.keys(additionalHeaders ?? {}).some(key => key.toLowerCase() === 'accept');
	const headers =
		isFormData || includesAcceptHeaders ? { ...additionalHeaders } : { ...JSON_HEADERS, ...additionalHeaders };

	// If there is a body, and it isn't FormData, convert the body to a JSON string
	if (body != null && !(body instanceof FormData)) body = JSON.stringify(body);

	// CSRF protection: generate random UUID and submit it in a custom header
	if (method !== HttpMethod.GET) {
		headers[CSRF_HEADER] = UUID.generate();
	}

	// Always send the INTEGRATION_HEADER to identify the integration
	headers[INTEGRATION_HEADER] = getIntegrationUrlAuthority();

	// Optionally send the INTEGRATION_ID_HEADER to identify the integration when the URL authority is not unique
	const integrationId = getIntegrationId();
	if (integrationId) headers[INTEGRATION_ID_HEADER] = integrationId;

	// Use credentials 'include' to send cookies in CORS requests
	const credentials: RequestCredentials = 'include';

	const path = URI(endpoint).pathname();

	// Omit headers and credentials for external requests
	const init = externalRequest ? { method, body } : { credentials, method, headers, body };

	return fetch(endpoint, init)
		.then(response => {
			if (response.ok) {
				// Response has JSON content type, return body parsed as JSON
				if (hasContentTypeJSON(response)) {
					return response.json().then(json => {
						console.log(`${method} ${path} success:`, response.status, 'json:', json);
						return json;
					});
				}
				// Response has text content type, return body parsed as text
				if (hasContentTypeText(response)) {
					return response.text().then(text => {
						console.log(`${method} ${path} success:`, response.status, 'text:', text);
						return text;
					});
				}
				// Otherwise return the response object

				console.log(`${method} ${path} success:`, response.status);
				return response;
			}

			// Response has content type application/problem+json, return error with body parsed as JSON
			if (hasContentTypeProblemJSON(response)) {
				return response.json().then(json => {
					console.warn(`${method} ${path} problem:`, response.status, 'json:', json);
					return Promise.reject({ message: response.statusText, status: response.status, problem: json });
				});
			}

			// Other error
			console.warn(`${method} ${path} error:`, response.status);
			return Promise.reject({ message: response.statusText, status: response.status });
		})
		.then(
			response => ({ response }),
			error => ({
				error: APIError.fromJson({
					message: error.message || 'Undefined Error occurred!',
					status: error.status,
					problem: error.problem,
					method,
				}),
			}),
		);
}

/**
 * Checks if the response has one of the given content types.
 */
const hasContentType = (response: Response, ...contentTypes: string[]): boolean => {
	const contentType = response.headers.get('Content-Type');
	return typeof contentType === 'string' && contentTypes.some(value => contentType.includes(value));
};

/**
 * Checks if the response has a JSON content type.
 */
export const hasContentTypeJSON = (response: Response): boolean =>
	hasContentType(response, 'application/json', 'application/hal+json');

/**
 * Checks if the response has application/problem+json content type.
 */
export const hasContentTypeProblemJSON = (response: Response): boolean =>
	hasContentType(response, 'application/problem+json');

/**
 * Checks if the response has a plain text content type.
 */
const hasContentTypeText = (response: Response): boolean => hasContentType(response, 'text/plain');

export function downloadFile(endpoint: string, fileName: string, type: 'pdf' | 'csv') {
	const url = `${ENV.apiUrl}${endpoint
		.split('/')
		.filter(part => part !== '')
		.join('/')}`;

	let headers;

	if (type === 'csv') headers = { accept: 'text/csv' };

	callApi(url, HttpMethod.GET, undefined, headers)
		.then(response => {
			saveResultAsFile(response, `${fileName}.${type}`);
		})
		.catch(error => {
			console.error(`Error downloading file from ${url}`, error);
		});
}
