import Account from 'src/entities/accounts/Account';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import { AutoFormFields } from '../BaseEntity';

export type AdminFormFields = AutoFormFields<Admin>;
export default class Admin extends Account {
	constructor(json: any) {
		super(json);
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.ADMIN;
	}

	static formFields(entity?: Admin): AdminFormFields {
		return {
			...Account.formFields(entity),
		};
	}
}
