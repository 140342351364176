import { ENV } from 'src/config/env';
import BasicType from 'src/entities/basic-types/BasicType';

export type SizeSuffix = 'sm' | 'md' | 'lg' | '';

interface SizeWidth {
	size: SizeSuffix;
	width: number;
}

const sizeWidthMap: SizeWidth[] = [
	{ size: 'sm', width: 256 },
	{ size: 'md', width: 512 },
	{ size: 'lg', width: 1024 },
	{ size: '', width: 2084 },
];

export function getSizes() {
	return sizeWidthMap
		.map(sizeWidth => {
			const first = sizeWidth.size !== '' ? `(max-width: ${sizeWidth.width}px) ` : '';
			return `${first}${sizeWidth.width}px`;
		})
		.join(',');
}

export enum DocumentType {
	PDF = 'PDF',
	PNG = 'PNG',
	JPG = 'JPG',
}

type DocumentDirectory = 'PUBLIC' | 'PRIVATE';

export default class Document implements BasicType {
	readonly uuid: string;
	readonly type: DocumentType;
	readonly name: string;
	notice: string;
	readonly extension: string;
	readonly width?: number;
	readonly height?: number;
	readonly directory: DocumentDirectory;

	constructor(
		uuid: string,
		type: DocumentType,
		name: string,
		notice: string,
		extension: string,
		directory: DocumentDirectory,
		width?: number,
		height?: number,
	) {
		this.uuid = uuid;
		this.type = type;
		this.name = name;
		this.notice = notice;
		this.extension = extension;
		this.directory = directory;

		// Image Props
		this.width = width;
		this.height = height;
	}

	toString(): string {
		return this.name;
	}

	equals(other: Document): boolean {
		return this.uuid === other.uuid;
	}

	getSrc(suffix: SizeSuffix = ''): string {
		return `${ENV.publicUrl}documents/${this.uuid}${suffix !== '' ? `.${suffix}` : ''}.${this.extension}`;
	}

	getSrcSet() {
		return sizeWidthMap.map(sizeWidth => `${this.getSrc(sizeWidth.size)} ${sizeWidth.width}w`).join(',');
	}

	getDisplayName() {
		return `${this.name}.${this.extension}`;
	}

	get isImage(): boolean {
		return this.type === DocumentType.PNG || this.type === DocumentType.JPG;
	}

	static fromJson(json: any): Document | undefined {
		return json != null
			? new Document(
					json.uuid,
					json.type,
					json.name,
					json.notice,
					json.extension,
					json.directory,
					json.width,
					json.height,
				)
			: undefined;
	}

	static getSrc(document: Document, suffix: SizeSuffix = ''): string {
		return `${ENV.publicUrl}documents/${document.uuid}${suffix !== '' ? `.${suffix}` : ''}.${document.extension}`;
	}
}
