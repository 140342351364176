import AdminInvoice from 'src/entities/invoice/admin/AdminInvoice';
import Integration from 'src/entities/integration/Integration';

export default class AdminInvoiceFull extends AdminInvoice {
	integration: Integration;

	constructor(json: any) {
		super(json);
		this.integration = new Integration(json.integration);
	}
}
