import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import ContactData from 'src/entities/basic-types/ContactData';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';

export type CustomerDataFormFields = AutoFormFields<CustomerData>;
export default class CustomerData extends BaseEntity {
	contactData: ContactData;
	newsletter: boolean;
	name: string;

	constructor(json: any) {
		super(json);
		this.contactData = ContactData.fromJson(json.contactData);
		this.newsletter = json.newsletter;
		this.name = this.contactData.fullName();
	}

	toString(): string {
		return this.createdAt.toString();
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.CUSTOMER_DATA;
	}

	static formFields(entity?: CustomerData): CustomerDataFormFields {
		return {
			...BaseEntity.formFields(entity),
			contactData: ContactData.formValues(entity?.contactData),
			newsletter: entity?.newsletter,
		};
	}
}
