import { useSelector } from 'react-redux';
import { getIntegrationUrlAuthority } from 'src/ConnectedApp';
import { EntityDescriptions } from 'src/entities/description';
import { Projection } from 'src/entities/description/EntityDescription';
import IntegrationBooking from 'src/entities/integration/IntegrationBooking';
import { getEntityByFieldName } from 'src/redux/selectors';
import { store } from 'src/redux/store';
import { SingleEntityState, StoreState } from 'src/types';

export const getIntegration = (state?: StoreState): SingleEntityState<IntegrationBooking> => {
	state ??= store.getState();
	const urlAuthority = getIntegrationUrlAuthority();
	// remove www from urlAuthority
	const urlAuthorityWithoutWww = urlAuthority.replace('www.', '');
	const integration = getEntityByFieldName(
		state!,
		EntityDescriptions.INTEGRATION,
		'urlAuthority',
		urlAuthorityWithoutWww,
		Projection.BOOKING,
		true,
	);
	// console.log("Integration by urlAuthority", integration, urlAuthorityWithoutWww);
	return integration;
};

export function useIntegration() {
	const integration = useSelector((state: StoreState) =>
		getEntityByFieldName<IntegrationBooking>(
			state,
			EntityDescriptions.INTEGRATION,
			'urlAuthority',
			getIntegrationUrlAuthority().replace('www.', ''),
			Projection.BOOKING,
			true,
		),
	);

	return integration.content;
}
