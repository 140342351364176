import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import Document from 'src/entities/basic-types/Document';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import EventFull from 'src/entities/event/EventFull';

export default class Event extends BaseEntity {
	name: string;
	hideTime: boolean;
	subtitle: string;
	description: string;
	shortDescription: string;
	logo?: Document;
	promoted: boolean;
	topEvent: boolean;
	ranking: number;
	readonly csBusEventId?: string;

	constructor(json: any) {
		super(json);

		this.name = json.name;
		this.hideTime = json.hideTime;
		this.subtitle = json.subtitle;
		this.description = json.description;
		this.shortDescription = json.shortDescription;
		this.logo = Document.fromJson(json.logo);
		this.promoted = json.promoted;
		this.topEvent = json.topEvent;
		this.ranking = json.ranking;
		this.csBusEventId = json.csBusEventId;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.EVENT;
	}

	static formFields(entity?: Event): AutoFormFields<Event> {
		return {
			...BaseEntity.formFields(entity),
			name: entity?.name,
			subtitle: entity?.subtitle,
			shortDescription: entity?.shortDescription,
			hideTime: entity?.hideTime,
			logo: entity?.logo,
			description: entity?.description,
			topEvent: entity?.topEvent,
			promoted: entity?.promoted,
			ranking: entity?.ranking,
		};
	}
}
