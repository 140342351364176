import { BigNumber } from 'bignumber.js';
import moment from 'moment-timezone';
import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import BundleContingent from 'src/entities/basic-types/BundleContingent';
import Money, { Currency } from 'src/entities/basic-types/Money';
import { InsuranceProduct } from 'src/entities/booking/progress/steps/insurance/V4InsuranceOfferRS';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import { momentWithTimeZoneFromJson } from 'src/helper/helper';

export default class Bundle extends BaseEntity {
	name: string;
	description: string;
	slug: string;
	itinerary: string;
	contingent: BundleContingent;

	start: moment.Moment;
	end: moment.Moment;
	durationInDays: number;
	past: boolean;

	basePrice: Money;
	discountPrice?: Money;
	surcharge: Money;
	marginalTax: boolean;
	paymentOnInvoice: boolean;
	deposit?: number;
	depositDays?: number;
	depositNow: boolean;
	depositAllowed: boolean;

	bookableProducts: number;
	insuranceProduct: InsuranceProduct;

	bookableUntil: moment.Moment;
	bookableDuration: moment.Duration;
	bookableNow: boolean;
	cancellableFreeUntil?: moment.Moment;

	needsPassengerName: boolean;
	needsPassengerBirth: boolean;
	needsPassengerPass: boolean;
	needsPassengerGroup: boolean;
	allPaxSameStop: boolean;

	importantInformation: string;
	includedServices: string;
	excludedServices: string;
	packageTourInfo: string;

	busTicketBookingConfirmation: boolean;
	parkingSpaces: boolean;
	packageTour: boolean;

	constructor(json: any) {
		super(json);

		this.name = json.name;
		this.description = json.description;
		this.slug = json.slug;
		this.itinerary = json.itinerary;
		this.contingent =
			json.contingent != null
				? BundleContingent.fromJson(json.contingent, json.bookableProducts)
				: new BundleContingent(null, null, json.bookableProducts);

		this.start = momentWithTimeZoneFromJson(json.start);
		this.end = momentWithTimeZoneFromJson(json.end);
		this.durationInDays = json.durationInDays;
		this.past = json.past;

		this.basePrice = Money.fromJson(json.basePrice);
		this.discountPrice = json.discountPrice ? Money.fromJson(json.discountPrice) : undefined;
		this.surcharge = Money.fromJson(json.surcharge);
		this.marginalTax = true;
		this.paymentOnInvoice = json.paymentOnInvoice;
		this.deposit = json.deposit;
		this.depositDays = json.depositDays;
		this.depositNow = json.depositNow;
		this.depositAllowed = json.depositAllowed;

		this.bookableProducts = json.bookableProducts;
		this.insuranceProduct = json.insuranceProduct;

		this.bookableUntil = momentWithTimeZoneFromJson(json.bookableUntil);
		this.bookableDuration = moment.duration(json.bookableDuration);
		this.bookableNow = json.bookableNow;
		this.cancellableFreeUntil = json.cancellableFreeUntil
			? momentWithTimeZoneFromJson(json.cancellableFreeUntil)
			: undefined;

		this.needsPassengerName = json.needsPassengerName;
		this.needsPassengerBirth = json.needsPassengerBirth;
		this.needsPassengerPass = json.needsPassengerPass;
		this.needsPassengerGroup = json.needsPassengerGroup;
		this.allPaxSameStop = json.allPaxSameStop;

		this.importantInformation = json.importantInformation;
		this.includedServices = json.includedServices;
		this.excludedServices = json.excludedServices;
		this.packageTourInfo = json.packageTourInfo;

		this.busTicketBookingConfirmation = json.busTicketBookingConfirmation;
		this.parkingSpaces = json.parkingSpaces;
		this.packageTour = json.packageTour || false;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.BUNDLE;
	}

	getCheapestPrice(): Money {
		return this.discountPrice ?? this.basePrice;
	}

	/**
	 * The actual amount that can currently be booked.
	 *
	 * If the cheapest price is negative, return 0.
	 *
	 * The value is the minimum of the bundle contingent
	 * (either external or total depending on whether we are on an external integration right now)
	 * and the number of bookable products.
	 *
	 * The number of bookable products is calculated in the backend.
	 */
	getBookable(externalIntegrationContingent: boolean): number {
		if (this.getCheapestPrice().isNegative()) return 0;
		const bundleBookable = externalIntegrationContingent ? this.contingent.external : this.contingent.total;
		return bundleBookable != null ? Math.min(bundleBookable, this.bookableProducts) : this.bookableProducts;
	}

	getNeedsPassengerInfo(): {
		needsPassengerName: boolean;
		needsPassengerBirth: boolean;
		needsPassengerPass: boolean;
		needsPassengerGroup: boolean;
	} {
		return {
			needsPassengerName: this.needsPassengerName,
			needsPassengerBirth: this.needsPassengerBirth,
			needsPassengerPass: this.needsPassengerPass,
			needsPassengerGroup: this.needsPassengerGroup,
		};
	}

	getStartEndPair(): [moment.Moment, moment.Moment] {
		return [this.start, this.end];
	}

	static formFields(entity?: Bundle, currency?: Currency): AutoFormFields<Bundle> {
		return {
			...BaseEntity.formFields(entity),

			name: entity?.name,
			description: entity?.description,
			slug: entity?.slug,
			itinerary: entity?.itinerary,
			contingent: entity?.contingent ?? new BundleContingent(null, null, 0),

			start: entity?.start ?? moment().minutes(0).add(1, 'hours'), // now rounded up to the next hour
			end: entity?.start ?? moment().minutes(0).add(1, 'day').add(1, 'hours'), // now rounded up to the next hour, tomorrow
			durationInDays: entity?.durationInDays,
			past: entity?.past,

			basePrice: entity?.basePrice,
			discountPrice: entity?.discountPrice,
			surcharge: entity?.surcharge ?? new Money(new BigNumber(0), currency ?? 'EUR'),
			marginalTax: entity?.marginalTax,
			paymentOnInvoice: entity?.paymentOnInvoice,
			deposit: entity?.deposit,
			depositDays: entity?.depositDays,
			depositNow: entity?.depositNow,
			depositAllowed: entity?.depositAllowed,

			bookableProducts: entity?.bookableProducts,
			insuranceProduct: entity?.insuranceProduct ?? InsuranceProduct.BBAKS,

			bookableUntil: entity?.bookableUntil ?? moment().subtract(1, 'days').startOf('day'), // yesterday at start of day
			bookableDuration: entity?.bookableDuration,
			bookableNow: entity?.bookableNow,
			cancellableFreeUntil: entity?.cancellableFreeUntil,

			needsPassengerName: entity?.needsPassengerName,
			needsPassengerBirth: entity?.needsPassengerBirth,
			needsPassengerPass: entity?.needsPassengerPass,
			needsPassengerGroup: entity?.needsPassengerGroup,
			allPaxSameStop: entity?.allPaxSameStop,

			importantInformation: entity?.importantInformation,
			includedServices: entity?.includedServices,
			excludedServices: entity?.excludedServices,
			packageTourInfo: entity?.packageTourInfo,

			busTicketBookingConfirmation: entity?.busTicketBookingConfirmation,
			parkingSpaces: entity?.parkingSpaces,
			packageTour: entity?.packageTour,
		};
	}
}
