import { isDefined } from 'src/components/booking/frontend/booking-progress/steps/product-steps/utils';
import Customer from 'src/entities/accounts/Customer';
import Reseller from 'src/entities/accounts/Reseller';
import Role from 'src/entities/accounts/Role';
import Booking from 'src/entities/booking/Booking';
import BookingRow from 'src/entities/booking/BookingRow';
import Company from 'src/entities/companies/Company';
import CouponBooking from 'src/entities/coupon/CouponBooking';
import CustomerData from 'src/entities/customer-data/CustomerData';
import { createAccount } from 'src/entities/factories/account-factory';
import IdName from 'src/entities/IdName';
import Insurance, { InsuranceState } from 'src/entities/insurance/Insurance';
import CustomerInvoice from 'src/entities/invoice/customer/CustomerInvoice';
import { fromJsonArray } from 'src/helper/helper';

export default class BookingDetails extends Booking {
	integration?: IdName;
	account?: Customer | Reseller;
	coupon?: CouponBooking;
	invoice?: CustomerInvoice;
	bookingRows: BookingRow[];
	insurance?: Insurance;
	customerData: CustomerData;
	cancelled: boolean;
	changedExternally: boolean;

	constructor(json: any) {
		super(json);

		this.integration = json.integration ? new IdName(json.integration) : undefined;
		this.account = json.account ? createAccount(json.account) : undefined;
		this.coupon = json.coupon ? new CouponBooking(json.coupon) : undefined;
		this.invoice = json.invoice ? new CustomerInvoice(json.invoice) : undefined;
		this.bookingRows = fromJsonArray(BookingRow, json.bookingRows);
		this.insurance = json.insurance ? new Insurance(json.insurance) : undefined;
		this.customerData = new CustomerData(json.customerData);
		this.cancelled = json.cancelled;
		this.changedExternally = json.changedExternally;
	}

	getFirstRow(): BookingRow {
		return this.bookingRows[0];
	}

	getCompany(): Company {
		return this.getFirstRow().bundle.company;
	}

	needsRegistrationConfirmation(): boolean {
		return this.account !== undefined && this.account.role === Role.CUSTOMER && !this.account.registrationConfirmed;
	}

	isMetaFlagsEditable = (): boolean => !this.cancelled && !this.past;

	isBookingReviewable = (): boolean =>
		!this.cancelled && this.past && !this.paymentPending && this.getFirstRow().review == null;

	hasValidInsurance = (): boolean => this.insurance?.validInsurance ?? false;

	getInsuranceState = (): InsuranceState => {
		if (!this.insurance) return InsuranceState.NO_INSURANCE;
		return this.insurance.getInsuranceState();
	};
}
