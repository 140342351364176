import BaseEntity from 'src/entities/BaseEntity';
import EntityDescription from 'src/entities/description/EntityDescription';
import { EntityDescriptions } from 'src/entities/description';
import ReviewEntry from 'src/entities/review/ReviewEntry';
import { fromJsonArray } from 'src/helper/helper';
import { percentToStars } from 'src/entities/basic-types/ReviewScore';

export default class ReviewFull extends BaseEntity {
	comment: string;
	entries: ReviewEntry[];
	readonly total: number;

	constructor(json: any) {
		super(json);

		this.comment = json.comment;
		this.entries = fromJsonArray(ReviewEntry, json.entries);
		this.total = json.total;
	}

	toString(): string {
		return String(this.total);
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.REVIEW_CATEGORY;
	}

	stars(): number {
		return percentToStars(this.total);
	}
}
