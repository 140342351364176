import EntityDescription from 'src/entities/description/EntityDescription';
import { EntityDescriptions } from 'src/entities/description';
import Invoice from 'src/entities/invoice/Invoice';
import moment from 'moment-timezone';
import Money from 'src/entities/basic-types/Money';

export default class CompanyInvoice extends Invoice {
	periodFrom: moment.Moment;
	periodTill: moment.Moment;
	commission: number;
	baseFee: Money;

	constructor(json: any) {
		super(json);

		this.periodFrom = moment(json.periodFrom);
		this.periodTill = moment(json.periodTill);
		this.commission = json.commission;
		this.baseFee = Money.fromJson(json.baseFee);
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.COMPANY_INVOICE;
	}
}
