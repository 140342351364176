import Customer from 'src/entities/accounts/Customer';
import Company from 'src/entities/companies/Company';
import { AutoFormFields } from '../BaseEntity';

export type CustomerFullFormFields = AutoFormFields<CustomerFull>;
export default class CustomerFull extends Customer {
	company?: Company; // always undefined

	constructor(json: any) {
		super(json);
	}

	static formFields(entity?: CustomerFull): CustomerFullFormFields {
		return {
			...Customer.formFields(entity),
		};
	}
}
