import { Action } from '@reduxjs/toolkit';
import { assign } from 'lodash';
import FilterEntities from 'src/entities/booking/FilterEntities';
import {
	FAILURE_FILTER_ENTITIES,
	REQUEST_FILTER_ENTITIES,
	SUCCESS_FETCH_FILTER_ENTITIES,
} from 'src/redux/actions/search-filter';
import { singleEntityDefaultStateWithContent } from 'src/redux/selectors';
import { SingleEntityState } from 'src/types';

export interface SearchFilterState {
	content: FilterEntities;
	isFetching: boolean;
	error: boolean;
}

const initialFilterEntities: FilterEntities = {
	companies: [],
	features: [],
	aCategories: [],
	bCategories: [],
};

interface FilterCategory {
	id: number;
	title: Record<string, string>;
}

interface Response {
	filterCompanies: FilterCategory[];
	filterFeatures: FilterCategory[];
	filter1Categories: FilterCategory[];
	filter2Categories: FilterCategory[];
}

interface SearchFilterAction extends Action {
	response: Response;
	error: any;
}

/**
 * Reducer that contains all filter entities (Available companies, features, categories for the current search)
 */
export function searchFilterReducer(
	state: SingleEntityState<FilterEntities> = singleEntityDefaultStateWithContent(initialFilterEntities),
	action: SearchFilterAction,
) {
	switch (action.type) {
		case REQUEST_FILTER_ENTITIES:
			return assign({}, state, { isFetching: true, error: undefined });

		case SUCCESS_FETCH_FILTER_ENTITIES:
			const { filterCompanies, filterFeatures, filter1Categories, filter2Categories } = action.response;
			return assign({}, state, {
				isFetching: false,
				content: {
					companies: filterCompanies,
					features: filterFeatures,
					aCategories: filter1Categories,
					bCategories: filter2Categories,
				},
				error: undefined,
			});

		case FAILURE_FILTER_ENTITIES:
			return assign({}, state, { isFetching: false, error: action.error });

		default:
			return state;
	}
}
