import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import Location from 'src/entities/basic-types/Location';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';

export default class EventLocation extends BaseEntity {
	name: string;
	location: Location;
	description: string;

	constructor(json: any) {
		super(json);

		this.name = json.name;
		this.location = Location.fromJson(json.location)!;
		this.description = json.description;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.EVENT_LOCATION;
	}

	static formFields(entity?: EventLocation): AutoFormFields<EventLocation> {
		return {
			...BaseEntity.formFields(entity),
			name: entity?.name,
			location: entity?.location,
			description: entity?.description,
		};
	}
}
