import BaseEntity from 'src/entities/BaseEntity';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import { InsuranceProduct } from 'src/entities/booking/progress/steps/insurance/V4InsuranceOfferRS';
import Money from 'src/entities/basic-types/Money';
import DocumentDescription from 'src/entities/booking/progress/steps/insurance/DocumentDescription';

export default class Insurance extends BaseEntity {
	token: string;
	productCode: InsuranceProduct;
	productName: string;
	productDescription: string;
	documentDescription: DocumentDescription;
	varianceCode: string;
	confirmationNumber: string;
	confirmationDocumentURL: string;
	totalPremium: Money;
	readonly paymentPending: boolean;
	readonly validInsurance: boolean;

	constructor(json: any) {
		super(json);

		this.token = json.token;
		this.productCode = json.productCode;
		this.productName = json.productName;
		this.productDescription = json.productDescription;
		this.varianceCode = json.varianceCode;
		this.confirmationNumber = json.confirmationNumber;
		this.confirmationDocumentURL = json.confirmationDocumentURL;
		this.documentDescription = new DocumentDescription(json.documentDescription);
		this.totalPremium = Money.fromJson(json.totalPremium);
		this.paymentPending = json.paymentPending;
		this.validInsurance = json.validInsurance;
	}

	toString(): string {
		return this.productName;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.INSURANCE;
	}

	getInterpolatedProductDescription(): string {
		return this.productDescription + this.documentDescription.text;
		// if (this.productCode === InsuranceProduct.KS) return this.productDescription + this.documentDescription.text;
		//
		// const interpolationMap = {
		//     '%aio.label.default%': this.documentDescription.label,
		//     '%aio.url.default%': this.documentDescription.url,
		// };
		//
		// return interpolateString(this.productDescription, interpolationMap);
	}

	getInsuranceState = (): InsuranceState => {
		if (!this.validInsurance) return InsuranceState.INVALID;
		if (this.paymentPending) return InsuranceState.PENDING_PAYMENT;
		return InsuranceState.OK;
	};
}

export enum InsuranceState {
	NO_INSURANCE = 'NO_INSURANCE',
	INVALID = 'INVALID',
	PENDING_PAYMENT = 'PENDING_PAYMENT',
	OK = 'OK',
}
