import { find } from 'lodash';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription, { Projection } from 'src/entities/description/EntityDescription';

/**
 * Creates a new instance of the given entity depending on the projection
 */
export function createEntity(json: any, repository: string, projection: Projection = Projection.NONE): any {
	const entityDescription = find(EntityDescriptions, (it: EntityDescription) => it.repository === repository);

	if (!entityDescription) throw new Error(`Unknown entity repository: ${repository}`);

	const entityConstructor = entityDescription.projectionsTypeMap[projection];
	if (entityConstructor == null)
		throw new Error(`No projection "${projection}" defined in EntityDescription "${entityDescription.name}"`);
	return new entityConstructor(json);
}
