import { useCallback, useEffect, useState } from 'react';

export interface UseDisclosure {
	state: {
		open: boolean;
	};
	open: () => void;
	close: () => void;
	toggle: () => void;
}

export function useDisclosure(initial = false, once = false): UseDisclosure {
	const [open, setOpen] = useState(initial);
	const [locked, setLocked] = useState(false);

	const handleOpen = useCallback(() => {
		if (locked) return;
		setOpen(true);
	}, [locked]);

	const handleClose = useCallback(() => {
		if (once) setLocked(true);
		setOpen(false);
	}, [once]);

	const handleToggle = useCallback(() => {
		if (open) handleClose();
		else handleOpen();
	}, [handleClose, open, handleOpen]);

	// React to changes of `initial`
	useEffect(() => {
		if (initial && !locked) handleOpen();
	}, [handleOpen, initial, locked]);

	return {
		state: {
			open,
		},
		open: handleOpen,
		close: handleClose,
		toggle: handleToggle,
	};
}
