export default class Message {
	code: string;
	codeText: string;
	parameter: string[];
	text: string;

	constructor(json: any) {
		this.code = json.code;
		this.codeText = json.codeText;
		this.parameter = json.parameter;
		this.text = json.text;
	}
}
