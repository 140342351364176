import Account from 'src/entities/accounts/Account';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import { AutoFormFields } from '../BaseEntity';

export type ResellerFormFields = AutoFormFields<Reseller>;

export default class Reseller extends Account {
	commission: number;

	constructor(json: any) {
		super(json);
		this.commission = json.commission;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.RESELLER;
	}

	static formFields(entity?: Reseller): ResellerFormFields {
		return {
			...Account.formFields(entity),
			commission: entity?.commission,
		};
	}
}
