import { assign } from 'lodash';
import Passenger from 'src/entities/booking/Passenger';
import CombinedBookingItem from 'src/entities/booking/progress/CombinedBookingItem';
import { BookingProgressStep, GAItem } from 'src/entities/booking/progress/steps/BookingProgressStep';
import { fromJsonArray } from 'src/helper/helper';

export default class ProductStep extends BookingProgressStep {
	readonly count: number;
	optionalItems: CombinedBookingItem[];
	mandatoryItems: CombinedBookingItem[];
	relatedOptionalItems: CombinedBookingItem[];
	relatedMandatoryItems: CombinedBookingItem[];
	readonly freePassengers: Record<string, Passenger[]>;

	constructor(json: any) {
		super(json);

		this.count = json.count;
		this.mandatoryItems = fromJsonArray(CombinedBookingItem, json.mandatoryItems);
		this.optionalItems = fromJsonArray(CombinedBookingItem, json.optionalItems);
		this.relatedOptionalItems = fromJsonArray(CombinedBookingItem, json.relatedOptionalItems);
		this.relatedMandatoryItems = fromJsonArray(CombinedBookingItem, json.relatedMandatoryItems);
		this.freePassengers = {};
		Object.keys(json.freePassengers).forEach(key => {
			this.freePassengers[key] = fromJsonArray(Passenger, json.freePassengers[key]);
		});
	}

	resetItems() {
		this.optionalItems = this.optionalItems.map(resetItem);
		this.mandatoryItems = this.mandatoryItems.map(resetItem);
		this.relatedOptionalItems = this.relatedOptionalItems.map(resetItem);
		this.relatedMandatoryItems = this.relatedMandatoryItems.map(resetItem);
	}

	getGAItems(): GAItem[] {
		const all = [...this.mandatoryItems, ...this.optionalItems, ...this.relatedOptionalItems];

		return all
			.filter(item => item.count > 0)
			.map(item => ({
				itemId: item.id.toString(),
				itemName: `[${this.name}] ${item.bundleItem.product.name}`,
				price: item.sumSurcharge.amount.toNumber(),
				currency: item.sumSurcharge.currency,
				quantity: item.count,
			}));
	}
}

const resetItem = (item: CombinedBookingItem): CombinedBookingItem => {
	const itemWithCount0 = assign<CombinedBookingItem>(item);
	itemWithCount0.passengers = [];
	return itemWithCount0;
};
