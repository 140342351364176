import { BigNumber } from 'bignumber.js';
import moment from 'moment-timezone';
import { SemanticICONS } from 'semantic-ui-react';
import BasicType from 'src/entities/basic-types/BasicType';
import { objectTypedKeys } from 'src/helper/helper';

// Keys as delivered by backend
export enum Currency {
	EUR = 'EUR',
	CHF = 'CHF',
}

export type CurrencyKey = keyof typeof Currency;

export enum CurrencySymbol {
	EUR = '€',
	CHF = 'Fr.',
}

interface Option {
	key: string;
	value: string;
	text: string;
}

export const CURRENCY_OPTIONS: Option[] = objectTypedKeys(Currency).map(key => ({
	key: Currency[key],
	value: Currency[key],
	text: CurrencySymbol[key],
}));

const currencyIcon = (currency: Currency): SemanticICONS => {
	switch (currency) {
		case Currency.EUR:
			return 'eur';
		case Currency.CHF:
			return 'money';
		default:
			console.error('Unsupported currency');
			return 'eur';
	}
};

export default class Money implements BasicType {
	amount: BigNumber;
	currency: Currency;

	constructor(amount: BigNumber, currency: CurrencyKey) {
		this.amount = amount;
		this.currency = Currency[currency];
	}

	currencySymbol(): string {
		return CurrencySymbol[this.currency];
	}

	currencyIcon(): SemanticICONS {
		return currencyIcon(this.currency);
	}

	/**
	 * Format the money with Intl.NumberFormat.
	 */
	format(): string {
		return new Intl.NumberFormat(moment.locale(), {
			style: 'currency',
			currency: this.currency,
		}).format(this.amount.toNumber());
	}

	toString(): string {
		return this.format();
	}

	equals(other: Money): boolean {
		return this.amount === other.amount && this.currency === other.currency;
	}

	asAbs(): Money {
		return new Money(this.amount.abs(), this.currency);
	}

	isZero(): boolean {
		return this.amount.isZero();
	}

	isNegative(): boolean {
		// bignumber returns true for isNegative for -0, so check that is non-zero as well
		return this.amount.isNegative() && !this.amount.isZero();
	}

	isPositive(): boolean {
		// bignumber returns true for isPositive for +0, so check that is non-zero as well
		return this.amount.isPositive() && !this.amount.isZero();
	}

	static fromJson(json: any): Money {
		return new Money(new BigNumber(json.amount), json.currency);
	}

	static sort(priceA: Money, priceB: Money): number {
		return priceA.amount.comparedTo(priceB.amount);
	}
}
