import { LocationDescriptor } from 'history';
import { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
	Button,
	ButtonProps,
	Popup,
	SemanticCOLORS,
	SemanticFLOATS,
	SemanticICONS,
	SemanticSIZES,
} from 'semantic-ui-react';

export type ButtonAction =
	| 'ok'
	| 'recede'
	| 'proceed'
	| 'cancel'
	| 'close'
	| 'save'
	| 'upload'
	| 'export'
	| 'print'
	| 'delete'
	| 'edit'
	| 'add'
	| 'copy'
	| 'reset'
	| 'search'
	| 'success'
	| 'details'
	| 'refresh'
	| 'minus'
	| 'plus'
	| 'external';
type Emphasis = 'normal' | 'basic' | 'link';
type Variant = 'icon' | 'text' | 'both';

const ACTION_BUTTON_CONF: {
	[A in ButtonAction]: {
		icon: SemanticICONS;
		color: SemanticCOLORS;
	};
} = {
	ok: { icon: 'check', color: 'blue' },
	recede: { icon: 'angle double left', color: 'blue' },
	proceed: { icon: 'angle double right', color: 'yellow' },
	cancel: { icon: 'cancel', color: 'grey' },
	close: { icon: 'cancel', color: 'grey' },

	save: { icon: 'save', color: 'green' },
	upload: { icon: 'upload', color: 'green' },
	export: { icon: 'download', color: 'green' },
	print: { icon: 'print', color: 'blue' },
	delete: { icon: 'trash', color: 'red' },
	edit: { icon: 'edit', color: 'yellow' },
	add: { icon: 'add', color: 'green' },
	copy: { icon: 'copy', color: 'orange' },
	reset: { icon: 'delete', color: 'grey' },

	minus: { icon: 'minus', color: 'grey' },
	plus: { icon: 'plus', color: 'grey' },

	search: { icon: 'search', color: 'teal' },
	success: { icon: 'check', color: 'green' },
	details: { icon: 'file alternate', color: 'blue' },
	refresh: { icon: 'refresh', color: 'grey' },
	external: { icon: 'external', color: 'teal' },
};

export interface ActionButtonProps {
	fluid?: boolean;
	floated?: SemanticFLOATS;
	buttonProps?: ButtonProps;
	action?: ButtonAction;
	overrideLabel?: string;
	overrideColor?: SemanticCOLORS;
	overrideIcon?: SemanticICONS;
	emphasis?: Emphasis;
	variant?: Variant;
	size?: SemanticSIZES;
	onClick?: (event: SyntheticEvent, data: ButtonProps) => void;
	to?: LocationDescriptor;
	replace?: boolean;
	disabled?: boolean;
	circular?: boolean;
	doSubmit?: boolean | string;
	leftIcon?: boolean;
	tooltip?: string;
	compact?: boolean | 'very';
	loading?: boolean;
	disablePopup?: boolean;
}

export const ActionButton: FC<ActionButtonProps> = ({
	action = 'ok',
	doSubmit,
	size = 'mini',
	variant = 'both',
	emphasis,
	fluid,
	buttonProps,
	floated,
	disabled = false,
	overrideLabel,
	overrideColor,
	overrideIcon,
	circular = false,
	leftIcon,
	tooltip,
	compact,
	loading,
	disablePopup,
	onClick,
	to,
	replace = false,
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const content = overrideLabel ?? t(action as string);
	const color = overrideColor ?? ACTION_BUTTON_CONF[action!].color;
	const icon = overrideIcon ?? ACTION_BUTTON_CONF[action!].icon;

	const trackingClass = `trk-abt-${action as string}`;

	const handleClick = (event: SyntheticEvent, data: ButtonProps) => {
		if (onClick) onClick(event, data);
		if (to && replace) history.replace(to);
		if (to && !replace) history.push(to);
	};

	const button = (
		<Button
			type={doSubmit ? 'submit' : 'button'}
			form={doSubmit ?? undefined}
			color={color}
			labelPosition={emphasis !== 'link' && variant === 'both' ? (leftIcon ? 'left' : 'right') : undefined}
			{...buttonProps}
			fluid={fluid}
			floated={floated}
			className={`${emphasis === 'link' ? 'tertiary' : ''} ${trackingClass} ${
				compact === 'very' ? 'very compact' : ''
			}`}
			basic={emphasis === 'basic'}
			size={size}
			icon={variant !== 'text' ? icon : undefined}
			content={variant !== 'icon' ? content : undefined}
			onClick={handleClick}
			disabled={disabled}
			circular={circular}
			compact={compact === true}
			loading={loading}
		/>
	);

	if ((!tooltip && variant !== 'icon') || disablePopup) return button;

	return <Popup trigger={button} content={tooltip ?? content} position="top center" />;
};
