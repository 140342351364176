import Page from 'src/entities/page/Page';
import BaseEntity from '../BaseEntity';

export default class IntegrationPage {
	page: Page;
	header: boolean;
	footer: boolean;

	constructor(json: any) {
		this.page = new Page(json.page);
		this.header = json.header;
		this.footer = json.footer;
	}
}
