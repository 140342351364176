import { TFunction } from 'i18next';
import { ComponentType } from 'react';
import Role from 'src/entities/accounts/Role';
import EntityDescription from 'src/entities/description/EntityDescription';
import URI from 'urijs';

export const S_PART = Symbol();
export const S_SAN_PART = Symbol();
export const S_COMPONENT = Symbol();
export const S_COMPONENT_PROPS = Symbol();
export const S_ACCESS = Symbol();
export const S_TRANS_PATH = Symbol();
export const S_ABS_PATH = Symbol();
export const S_ENTITY_DESCRIPTION = Symbol();

export interface Routes {
	[s: string]: Routes;
	[S_COMPONENT]: ComponentType | null;
	[S_COMPONENT_PROPS]?: object;
	[S_ACCESS]?: Role[];
	[S_ENTITY_DESCRIPTION]?: EntityDescription;
}

const ID_PART = ':id';

export class CompiledRoutes {
	[s: string]: CompiledRoutes;

	[S_PART]: string;
	[S_SAN_PART]: string;
	[S_TRANS_PATH]: string;
	[S_ABS_PATH]: string;
	[S_COMPONENT]: ComponentType | null;
	[S_COMPONENT_PROPS]: object | undefined;
	[S_ACCESS]: Role[];
	[S_ENTITY_DESCRIPTION]: EntityDescription | undefined;

	constructor(thisPart: string, routes: Routes, parent?: CompiledRoutes) {
		validatePart(thisPart);

		this[S_PART] = thisPart;
		this[S_COMPONENT] = routes[S_COMPONENT];
		this[S_COMPONENT_PROPS] = routes[S_COMPONENT_PROPS];

		// Inherit Access Roles from Parent if none are defined
		if (routes[S_ACCESS] !== undefined) {
			this[S_ACCESS] = routes[S_ACCESS]!;
		} else if (parent !== undefined) {
			this[S_ACCESS] = parent[S_ACCESS]!;
		} else {
			this[S_ACCESS] = [];
		}

		const entityDescription = routes[S_ENTITY_DESCRIPTION];
		this[S_ENTITY_DESCRIPTION] = entityDescription;

		// Build the translation Path of this route.
		const sanPart = sanitizePart(thisPart);
		this[S_SAN_PART] = sanPart;
		this[S_TRANS_PATH] = parent ? [parent[S_TRANS_PATH], sanPart].join('.') : `routes:${sanPart}`;

		// If there is an entityDescription defined we assume that the current part contains ID_PART
		// The complete url might contain multiple ID_PART parts. To avoid clashes we simply
		// use the entityDescription.name as the placeholder.
		// build-routes will propagate a property called contextId to the route component containing the relevant id.
		let thisEntityPart = thisPart;

		if (entityDescription) {
			thisEntityPart = thisPart.replace(ID_PART, `:${entityDescription.name}`);
		}

		// Build the absolute Path of this route.
		this[S_ABS_PATH] = parent ? [parent[S_ABS_PATH], thisEntityPart].join('/') : `/${thisEntityPart}`;

		// Iterate all members and create a new CompiledRoute
		Object.getOwnPropertyNames(routes).forEach(part => {
			this[sanitizePart(part)] = new CompiledRoutes(part, routes[part], this);
		});
	}
}

const validatePart = (part: string): void => {
	if (part.split(':').length > 2)
		throw new Error(`Part "${part}" contains multiple placeholder; Only one placeholder per part is allowed`);
	if (part === 'link')
		throw new Error(`Part "${part}" is a reserved name used for translation links; Please choose another name`);
};

export const sanitizePart = (part: string): string => {
	return part.startsWith(':') ? part.slice(1) : part.replace(':', '_');
};

export const buildUrl = (
	target: CompiledRoutes,
	contextIds?: Record<string, string | number> | string | number,
	queryParams?: Record<string, unknown> | null,
): string => {
	const path = target[S_ABS_PATH];

	let proto = path;

	// Interpolate the first url placeholder with the provided value
	if (typeof contextIds === 'string' || typeof contextIds === 'number') {
		const s = path.indexOf(':');
		let e = path.indexOf('/', s);
		if (e < 0) e = path.length;

		proto = path.replace(path.slice(s, e), contextIds.toString());

		if (proto.includes(':'))
			throw new Error(
				`Target link declares more than one interpolation target: "${path}"; Only one value has been provided.`,
			);
	}
	// Interpolate the url placeholders like :entityDescription or :slug with the provided values
	else if (typeof contextIds === 'object') {
		proto = path
			.split('/')
			.map(part => {
				const x = part.split(':');

				if (x.length === 1) return part;

				const contextId = contextIds[x[1]];
				if (contextId == null)
					throw new Error(`Can't interpolate part "${part}"; No matching value has been provided;`);
				return [x[0], contextId].join('');
			})
			.join('/');
	}

	const uri = new URI(proto);

	if (queryParams) uri.addQuery(queryParams);

	return uri.toString();
};

interface TranslationProps {
	id: number;
	resolve: string;
}

export const buildTrans = (t: TFunction, target: CompiledRoutes, translationProps?: TranslationProps): string => {
	return target[S_TRANS_PATH] === 'routes:'
		? t('routes:landing.link', { ...translationProps })
		: t(`${target[S_TRANS_PATH]}.link`, { ...translationProps });
};

/**
 * Helper function to generate toml keys for routes translation
 */
const TAB = '  ';

export const yamlUpRoutes = (routes: CompiledRoutes, depth: number = 0): string => {
	const isPlaceholderPart = routes[S_PART].split(':').length === 2;

	const offset = TAB.repeat(depth);
	const nextOffset = TAB.repeat(depth + 1);
	const key = routes[S_SAN_PART];
	const val = isPlaceholderPart ? `${key} [{{ ${key} }}]${key === 'id' ? ' - {{ resolve }}' : ''}` : key;

	let out = `${offset}${key}:\n${nextOffset}link: "${val}"\n`;
	out += Object.getOwnPropertyNames(routes)
		.map(next => yamlUpRoutes(routes[next], depth + 1))
		.join('');
	return out;
};
