import 'iframe-resizer/js/iframeResizer.contentWindow.min.js';

export const setupIframeResizer = () => {
	window.iFrameResizer = {
		readyCallback() {
			console.log('Send requestParentLocation message to parent');
			window.parentIFrame.sendMessage({ requestParentLocation: true });
			configureModalPlacement();
		},
		messageCallback(messageData: string) {
			console.log('Received parentLocation from parent');
			window.parentLocation = messageData;
		},
	};
};

export function configureModalPlacement() {
	if (window.parentIFrame == null) return;

	window.parentIFrame.getPageInfo(page => {
		document.querySelectorAll('.ui.modal').forEach((modal: HTMLElement) => {
			const modalContent = modal.querySelector('.content') as HTMLElement | null;
			if (modalContent != null) modalContent.style.maxHeight = '500px';

			const marginTop = Math.min(
				Math.max(page.scrollTop - page.offsetTop, 0),
				page.iframeHeight - modal.offsetHeight,
			);
			const marginBottom = Math.max(page.iframeHeight - marginTop - page.clientHeight, 0);

			modal.style.marginTop = `${marginTop}px`;
			modal.style.marginBottom = `${marginBottom}px`;
		});
	});
}
