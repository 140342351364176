import { getLocalizedText } from 'src/config/i18next/i18next';
import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import Document from 'src/entities/basic-types/Document';
import CategoryFull from 'src/entities/category/CategoryFull';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';

export default class Category extends BaseEntity {
	name: string;
	title: Record<string, string>;
	visible: boolean;
	logo?: Document;
	special: string;

	constructor(json: any) {
		super(json);

		this.name = json.name;
		this.title = json.title;
		this.visible = json.visible;
		this.logo = Document.fromJson(json.logo);
		this.special = json.special;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.CATEGORY;
	}

	getLocalizedTitle(): string | undefined {
		return getLocalizedText(this.title);
	}

	getSpecialCSSClassName(): string | undefined {
		return this.special ? `special-category-${this.special}` : undefined;
	}

	static formFields(entity?: CategoryFull): AutoFormFields<Category> {
		return {
			...BaseEntity.formFields(entity),
			name: entity?.name,
			visible: entity?.visible,
			special: entity?.special,
			title: entity?.title,
		};
	}
}
