import { ENV } from 'src/config/env';
import { getLocalizedHTML, getLocalizedText } from 'src/config/i18next/i18next';
import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import URI from 'urijs';

export type PageFormFields = AutoFormFields<Page>;

export default class Page extends BaseEntity {
	name: string;
	slug: string;
	title: Record<string, string>;
	content: Record<string, string>;
	order: number;

	constructor(json: any) {
		super(json);

		this.name = json.name;
		this.slug = json.slug;
		this.title = json.title;
		this.content = json.content;
		this.order = json.order;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.PAGE;
	}

	getLocalizedTitle(): string | undefined {
		return getLocalizedText(this.title);
	}

	getLocalizedContent(): string | undefined {
		return getLocalizedHTML(this.content);
	}

	getStaticPageLink(): string {
		return URI(ENV.mainUrl).segment(['static', this.slug]).toString();
	}

	static formFields(entity?: Page): PageFormFields {
		return {
			...BaseEntity.formFields(entity),
			name: entity?.name,
			slug: entity?.slug,
			title: entity?.title ?? {},
			content: entity?.content ?? {},
			order: entity?.order,
		};
	}
}
