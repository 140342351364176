import { Component } from 'react';
import Role from 'src/entities/accounts/Role';
import { CompiledRoutes, S_ACCESS, S_COMPONENT } from 'src/routing';

export const PRINT_ROOT = 'print';

export const PRINT_ROUTES = new CompiledRoutes(PRINT_ROOT, {
	[S_COMPONENT]: null,
	[S_ACCESS]: [Role.NOT_AUTHENTICATED, Role.CUSTOMER, Role.RESELLER],
	confirmation: {
		[S_COMPONENT]: Component,
	},
});
