import { SemanticICONS } from 'semantic-ui-react';
import { ActionButton, ActionButtonProps } from '../../action-handler/ActionButton';
import { useDialog } from '../hooks/use-dialog';
import { type DialogVariant, type DialogVariantAction } from '../types';

/**
 * Map of action button props for each dialog variant action
 */
const DialogVariantActionButtonProps: Record<DialogVariantAction, Partial<ActionButtonProps>> = {
	cancel: {
		action: 'cancel',
	},
	reset: {
		action: 'reset',
	},
	submit: {
		action: 'ok',
	},
	ok: {
		action: 'ok',
	},
	delete: {
		action: 'delete',
	},
	save: {
		action: 'save',
	},
};

/**
 * Check if action is a form action and can be passed as a button type
 */
function isFormAction<Variant extends DialogVariant>(name: DialogVariantAction<Variant>): name is 'submit' | 'reset' {
	return ['submit', 'reset', 'save'].includes(name);
}

export interface DialogActionProps<Variant extends DialogVariant = DialogVariant> {
	readonly name: DialogVariantAction<Variant>;
	readonly label?: string;
	readonly icon?: SemanticICONS;
	readonly on?: () => void;
}

export const DialogAction = <Variant extends DialogVariant>({ name, label, icon, on }: DialogActionProps<Variant>) => {
	const { id, close } = useDialog();

	const handleAction = () => {
		on?.();
		if (['ok', 'cancel', 'delete'].includes(name)) close();
	};

	return (
		<ActionButton
			onClick={handleAction}
			{...DialogVariantActionButtonProps[name]}
			{...(isFormAction(name) && {
				doSubmit: id,
			})}
			overrideLabel={label}
			overrideIcon={icon}
		/>
	);
};
