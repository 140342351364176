enum Role {
	ADMIN = 'ADMIN',
	OPERATOR = 'OPERATOR',
	CUSTOMER = 'CUSTOMER',
	RESELLER = 'RESELLER',

	AUTHENTICATED = 'AUTHENTICATED', // must be authenticated with any role
	NOT_AUTHENTICATED = 'NOT_AUTHENTICATED', // must not be authenticated (unless used in combination with other roles)
}

export default Role;
