export default class AgreementDescription {
	key: string;
	mandatory: boolean;
	agreementText: string;
	agreementTextHtml: string;

	constructor(json: any) {
		this.key = json.key;
		this.mandatory = json.mandatory;
		this.agreementText = json.agreementText;
		this.agreementTextHtml = json.agreementTextHtml;
	}
}
