import EntityDescription from 'src/entities/description/EntityDescription';
import { EntityDescriptions } from 'src/entities/description';
import Invoice from 'src/entities/invoice/Invoice';

export enum CustomerInvoiceType {
	MARGINAL = 'MARGINAL',
	REGULAR = 'REGULAR',
}

export default class CustomerInvoice extends Invoice {
	type: CustomerInvoiceType;

	constructor(json: any) {
		super(json);

		this.type = json.type;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.CUSTOMER_INVOICE;
	}
}
