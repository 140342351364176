import { flattenDeep, isEqual, unionWith } from 'lodash';
import { MenuItem } from 'src/components/elements/form-inputs/SelectField';
import { AutoFormFields } from 'src/entities/BaseEntity';
import Stop from 'src/entities/products/stop/Stop';
import StopGroup from 'src/entities/products/stop/StopGroup';
import { fromJsonArray } from 'src/helper/helper';

export type StopFullFormFields = AutoFormFields<StopFull> & {
	company?: string;
	newItemName: string;
};
export default class StopFull extends Stop {
	groups: StopGroup[];

	constructor(json: any) {
		super(json);
		this.groups = fromJsonArray(StopGroup, json.groups);
	}

	/**
	 * Returns unique stop group menu items from the given stops
	 */
	static getStopGroupMenuItems(stops: StopFull[]): MenuItem[] {
		const uniqueStopGroups = unionWith(flattenDeep(stops.map((stop: StopFull) => stop.groups)), isEqual);

		return [...uniqueStopGroups].map((group: StopGroup) => ({
			key: group.id,
			value: group.id,
			text: group.name,
		}));
	}

	static formFields(entity?: StopFull): StopFullFormFields {
		return {
			...Stop.formFields(entity),
			groups: entity?.groups.map(group => group.getLink()) ?? [],
			newItemName: '',
		};
	}
}
