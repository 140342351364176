import Document from 'src/entities/basic-types/Document';
import PaymentSettings from 'src/entities/basic-types/PaymentSettings';
import Category from 'src/entities/category/Category';
import Company from 'src/entities/companies/Company';
import EventSearch from 'src/entities/event/EventSearch';
import Integration from 'src/entities/integration/Integration';
import IntegrationPage from 'src/entities/integration/IntegrationPage';
import Page from 'src/entities/page/Page';
import { fromJsonArray, fromJsonArrayWith } from 'src/helper/helper';
import { AutoFormFields } from '../BaseEntity';

export type IntegrationFullFormFields = Omit<AutoFormFields<IntegrationFull>, 'pages'> & {
	headerPages: string[];
	footerPages: string[];
};

export default class IntegrationFull extends Integration {
	config: Map<string, string>; // TODO: This seems to be a dead property (can we remove it?. Also if it should be a Map we need to instanciate one)
	mainIntegration: boolean;
	companies: Company[];
	readonly documents: Document[];
	mainFilterCategory: Category;
	filter1Category?: Category;
	filter2Category?: Category;
	pages: IntegrationPage[];
	privacyPage?: Page;
	topEvents: EventSearch[];
	paymentSettings: PaymentSettings;
	automaticallySendBills: boolean;
	sendReviewMails: boolean;

	constructor(json: any) {
		super(json);

		this.config = json.config;
		this.mainIntegration = json.mainIntegration;
		this.companies = fromJsonArray(Company, json.companies);
		this.documents = fromJsonArrayWith(Document.fromJson, json.documents);
		this.mainFilterCategory = new Category(json.mainFilterCategory);
		this.filter1Category = json.filter1Category ? new Category(json.filter1Category) : undefined;
		this.filter2Category = json.filter2Category ? new Category(json.filter2Category) : undefined;
		this.pages = fromJsonArray(IntegrationPage, json.pages);
		this.privacyPage = json.privacyPage ? new Page(json.privacyPage) : undefined;
		this.topEvents = fromJsonArray(EventSearch, json.topEvents);
		this.paymentSettings = new PaymentSettings(json.paymentSettings);
		this.automaticallySendBills = json.automaticallySendBills;
		this.sendReviewMails = json.sendReviewMails;
	}

	static formFields(entity?: IntegrationFull): IntegrationFullFormFields {
		return {
			...Integration.formFields(entity),
			// config: entity?.config ?? new Map<string, string>(),
			mainIntegration: entity?.mainIntegration ?? false,
			companies: entity?.companies.map(company => company.getLink()) ?? [],
			documents: entity?.documents ?? [],
			mainFilterCategory: entity?.mainFilterCategory.getLink(),
			filter1Category: entity?.filter1Category?.getLink(),
			filter2Category: entity?.filter2Category?.getLink(),
			headerPages: entity?.pages.filter(page => page.header).map(page => page.page.getLink()) ?? [],
			footerPages: entity?.pages.filter(page => page.footer).map(page => page.page.getLink()) ?? [],
			privacyPage: entity?.privacyPage?.getLink(),
			topEvents: entity?.topEvents.map(event => event.getLink()) ?? [],
			paymentSettings: PaymentSettings.formFields(entity?.paymentSettings), // TODO: maybe have this undefined if not present?
			automaticallySendBills: entity?.automaticallySendBills ?? false,
			sendReviewMails: entity?.sendReviewMails ?? false,
		};
	}
}
