import { Country } from 'src/config/countries/countries';
import countries from 'src/config/countries/countries.json';
import BasicType from 'src/entities/basic-types/BasicType';
import { joinToString } from 'src/helper/helper';
import { AutoFormFields } from '../BaseEntity';

export default class Address implements BasicType {
	country: string;
	city: string;
	postCode: string;
	street: string;

	constructor(country: string, city: string, postCode: string, street: string) {
		this.country = country;
		this.city = city;
		this.postCode = postCode;
		this.street = street;
	}

	countryName(): string {
		const country = countries.find((c: Country) => c.alpha3Code === this.country);
		return country != null ? country.name : this.country;
	}

	postCodeCity(): string {
		return joinToString(' ', this.postCode, this.city);
	}

	fullAddress(): string {
		return joinToString(', ', this.street, this.postCodeCity(), this.countryName());
	}

	toString(): string {
		return this.fullAddress();
	}

	equals(other: Address): boolean {
		return (
			this.country === other.country &&
			this.city === other.city &&
			this.postCode === other.postCode &&
			this.street === other.street
		);
	}

	static fromJson(json: any) {
		return new Address(json.country, json.city, json.postCode, json.street);
	}

	static emptyAddress() {
		return new Address('', '', '', '');
	}

	static fromAddressComponents(addressComponents: google.maps.GeocoderAddressComponent[]): Address {
		const address = new Address('', '', '', '');

		if (addressComponents.length === 0) return address;

		const country = addressComponents.find(component => component.types.includes('country'));
		const city = addressComponents.find(component => component.types.includes('locality'));
		const postCode = addressComponents.find(component => component.types.includes('postal_code'));
		const streetAddress = addressComponents.find(component => component.types.includes('route'));
		const streetNumber = addressComponents.find(component => component.types.includes('street_number'));

		address.country = country ? country.short_name : '';
		address.city = city ? city.long_name : '';
		address.postCode = postCode ? postCode.long_name : '';
		address.street = streetAddress
			? streetAddress.long_name + ' ' + (streetNumber ? streetNumber.long_name : '')
			: streetNumber
				? streetNumber.long_name
				: '';

		return address;
	}

	static formFields(address?: Address): AutoFormFields<Address> {
		return {
			country: address?.country ?? '',
			city: address?.city ?? '',
			postCode: address?.postCode ?? '',
			street: address?.street ?? '',
		};
	}
}
