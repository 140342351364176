import ContactData from 'src/entities/basic-types/ContactData';
import Money from 'src/entities/basic-types/Money';
import BaseEntity from 'src/entities/BaseEntity';
import EntityDescription from 'src/entities/description/EntityDescription';
import { EntityDescriptions } from 'src/entities/description';
import moment from 'moment-timezone';
import { fromJsonArray, momentWithTimeZoneFromJson } from 'src/helper/helper';
import PaymentLog, { getLatestPaymentLog } from 'src/entities/basic-types/PaymentLog';

export default class Booking extends BaseEntity {
	readonly token: string;
	readonly paymentPending: boolean;
	readonly contactData: ContactData;
	readonly comment: string;

	readonly past: boolean;
	readonly price: Money;
	readonly cheapestPrice: Money;
	readonly couponPrice?: Money;
	readonly resellerPrice?: Money;
	readonly resellerDiscount?: Money;
	readonly couponDiscount?: Money;
	readonly deposit?: number;
	readonly depositPrice?: Money;
	readonly insurancePrice?: Money;
	readonly cheapestPriceWithInsurance: Money;
	readonly balanceUntil?: moment.Moment;
	readonly paymentLogs?: PaymentLog[];

	constructor(json: any) {
		super(json);
		this.token = json.token;
		this.contactData = ContactData.fromJson(json.contactData);
		this.comment = json.comment;
		this.past = json.past;

		const price = Money.fromJson(json.price);
		const resellerPrice = json.resellerPrice ? Money.fromJson(json.resellerPrice) : undefined;
		const couponPrice = json.couponPrice ? Money.fromJson(json.couponPrice) : undefined;
		const insurancePrice = json.insurancePrice ? Money.fromJson(json.insurancePrice) : undefined;
		const cheapestPrice = Money.fromJson(json.cheapestPrice);

		this.price = price;
		this.cheapestPrice = cheapestPrice;
		this.couponPrice = couponPrice;
		this.resellerPrice = resellerPrice;
		this.insurancePrice = insurancePrice;

		this.resellerDiscount = Booking.calculateResellerDiscount(price, resellerPrice);
		this.couponDiscount = Booking.calculateCouponDiscount(price, resellerPrice, couponPrice);
		this.cheapestPriceWithInsurance = Booking.calculatePriceWithInsurance(cheapestPrice, insurancePrice);

		this.deposit = json.deposit;
		this.depositPrice = json.depositPrice ? Money.fromJson(json.depositPrice) : undefined;
		this.balanceUntil = json.balanceUntil ? momentWithTimeZoneFromJson(json.balanceUntil) : undefined;
		this.paymentLogs = fromJsonArray(PaymentLog, json.paymentLogs);
		this.paymentPending = json.paymentPending;
	}

	static calculateResellerDiscount(price: Money, resellerPrice?: Money): Money | undefined {
		return resellerPrice ? new Money(resellerPrice.amount.minus(price.amount), price.currency) : undefined;
	}

	static calculateCouponDiscount(price: Money, resellerPrice?: Money, couponPrice?: Money): Money | undefined {
		if (!couponPrice) return undefined;

		if (resellerPrice) return new Money(couponPrice.amount.minus(resellerPrice.amount), resellerPrice.currency);

		return new Money(couponPrice.amount.minus(price.amount), price.currency);
	}

	static calculatePriceWithInsurance(price: Money, insurancePrice?: Money): Money {
		return insurancePrice ? new Money(price.amount.plus(insurancePrice.amount), price.currency) : price;
	}

	toString(): string {
		return this.identifier;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.BOOKING;
	}

	getLatestPaymentLog(): PaymentLog | undefined {
		return getLatestPaymentLog(this.paymentLogs);
	}
}
