import { Action } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Projection } from 'src/entities/description/EntityDescription';
import { RESOLVE_BREADCRUMBS, putBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { Breadcrumb } from 'src/routing/BreadcrumbWrapper';
import { fetchById } from 'src/services/abstract-repository';

/******************************************************************************/
/** ***************************** Subroutines **********************************/
/******************************************************************************/

interface BreadcrumbsAction extends Action {
	breadcrumbs?: Breadcrumb[];
}

/**
 * Generates a saga that manages the breadcrumb resolving process
 */
function* resolveBreadcrumbsSaga(action: BreadcrumbsAction): SagaIterator {
	const breadcrumbs = action.breadcrumbs ?? [];
	const resolvedBreadcrumbs: Breadcrumb[] = [];

	for (const bc of breadcrumbs) {
		if (bc.entityDescription && bc.id !== undefined) {
			const result = yield call(fetchById, bc.entityDescription, bc.id, Projection.ID_NAME);
			if (result.error) resolvedBreadcrumbs.push(bc);
			else
				resolvedBreadcrumbs.push({
					...bc,
					transProps: {
						id: bc.id,
						resolve: result.response.name,
					},
				});
		} else resolvedBreadcrumbs.push(bc);
	}

	yield put(putBreadcrumbs(resolvedBreadcrumbs));
}

/******************************************************************************/
/** ***************************** WATCHERS *************************************/
/******************************************************************************/

/**
 * Login Watcher
 */
export function* generateBreadcrumbsWatcher() {
	yield takeLatest(RESOLVE_BREADCRUMBS, resolveBreadcrumbsSaga);
}
