import { getLocalizedText } from 'src/config/i18next/i18next';
import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';

export type FeatureFormFields = AutoFormFields<Feature>;
export default class Feature extends BaseEntity {
	name: string;
	title: Record<string, string>;
	icon: string;
	special: string;

	constructor(json: any) {
		super(json);

		this.name = json.name;
		this.title = json.title;
		this.icon = json.icon;
		this.special = json.special;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.FEATURE;
	}

	getLocalizedTitle(): string | undefined {
		return getLocalizedText(this.title);
	}

	getSpecialCSSClassName(): string | undefined {
		return this.special ? `special-feature-${this.special}` : undefined;
	}

	static formFields(entity?: Feature): FeatureFormFields {
		return {
			...BaseEntity.formFields(entity),
			name: entity?.name,
			title: entity?.title ?? {},
			icon: entity?.icon,
			special: entity?.special,
		};
	}
}
