import { UserDataFormType } from 'src/components/booking/frontend/booking-progress/steps/user-data/UserDataStepButtons';
import ContactData from 'src/entities/basic-types/ContactData';
import { BookingProgressStep } from 'src/entities/booking/progress/steps/BookingProgressStep';

export default class ContactDataStep extends BookingProgressStep {
	readonly contactData?: ContactData;
	readonly username?: string;
	readonly password?: string;
	readonly userType: UserDataFormType;
	readonly insurance: boolean;

	constructor(json: any) {
		super(json);

		this.contactData = json.contactData ? ContactData.fromJson(json.contactData) : undefined;
		this.username = json.username;
		this.password = json.password;
		this.userType = json.userType;
		this.insurance = json.insurance || false;
	}
}
