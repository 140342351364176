import BaseEntity from 'src/entities/BaseEntity';
import Money from 'src/entities/basic-types/Money';
import Passenger from 'src/entities/booking/Passenger';
import BundleItemBooking from 'src/entities/bundle/BundleItemBooking';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import { fromJsonArray } from 'src/helper/helper';

export default class BookingItem extends BaseEntity {
	price: Money;
	bundleItem: BundleItemBooking;
	passengers: Passenger[];
	cancelled: boolean;

	constructor(json: any) {
		super(json);

		this.price = Money.fromJson(json.price);
		this.bundleItem = new BundleItemBooking(json.bundleItem);
		this.passengers = fromJsonArray(Passenger, json.passengers);
		this.cancelled = json.cancelled;
	}

	toString(): string {
		return this.bundleItem.product.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.BOOKING_ITEM;
	}
}
