import Document from 'src/entities/basic-types/Document';
import Event from 'src/entities/event/Event';
import EventLocationBooking from 'src/entities/event-location/EventLocationBooking';
import { fromJsonArrayWith } from 'src/helper/helper';

export default class EventBookingDetails extends Event {
	eventLocation: EventLocationBooking;
	readonly documents: Document[];

	constructor(json: any) {
		super(json);

		this.eventLocation = new EventLocationBooking(json.eventLocation);
		this.documents = fromJsonArrayWith(Document.fromJson, json.documents);
	}
}
