import Document from 'src/entities/basic-types/Document';
import Journey from 'src/entities/products/Journey';
import { fromJsonArrayWith } from 'src/helper/helper';
import { AutoFormFields } from '../BaseEntity';

export type JourneyFullFormFields = AutoFormFields<JourneyFull>;

export default class JourneyFull extends Journey {
	readonly documents: Document[];
	readonly csBusJourneyId?: string;

	constructor(json: any) {
		super(json);

		this.documents = fromJsonArrayWith(Document.fromJson, json.documents);
		this.csBusJourneyId = json.csBusJourneyId;
	}

	static formFields(entity?: JourneyFull): JourneyFullFormFields {
		return {
			...Journey.formFields(entity),
			documents: entity?.documents ?? [],
			csBusJourneyId: entity?.csBusJourneyId,
		};
	}
}
