import Document from 'src/entities/basic-types/Document';
import CategoryBooking from 'src/entities/category/CategoryBooking';
import Integration from 'src/entities/integration/Integration';
import IntegrationPage from 'src/entities/integration/IntegrationPage';
import Page from 'src/entities/page/Page';
import { fromJsonArray, fromJsonArrayWith } from 'src/helper/helper';
import Gateway from 'src/entities/payment/Gateway';

export default class IntegrationBooking extends Integration {
	readonly documents: Document[];
	mainFilterCategory: CategoryBooking;
	filter1Category?: CategoryBooking;
	filter2Category?: CategoryBooking;
	pages: IntegrationPage[];
	privacyPage?: Page;
	readonly companiesCount: number;
	readonly paymentGateways: Gateway[];

	constructor(json: any) {
		super(json);

		this.documents = fromJsonArrayWith(Document.fromJson, json.documents);
		this.mainFilterCategory = new CategoryBooking(json.mainFilterCategory);
		this.filter1Category = json.filter1Category ? new CategoryBooking(json.filter1Category) : undefined;
		this.filter2Category = json.filter2Category ? new CategoryBooking(json.filter2Category) : undefined;
		this.pages = fromJsonArray(IntegrationPage, json.pages);
		this.privacyPage = json.privacyPage ? new Page(json.privacyPage) : undefined;
		this.companiesCount = json.companiesCount;
		this.paymentGateways = fromJsonArray(Gateway, json.paymentGateways);
	}

	toString(): string {
		return this.name;
	}
}
