import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AccountFull } from 'src/entities/accounts/AccountFull';
import BundleFull from 'src/entities/bundle/BundleFull';
import { EntityDescriptions } from 'src/entities/description';
import { Projection } from 'src/entities/description/EntityDescription';
import Event from 'src/entities/event/Event';
import { momentWithTimeZoneToISOString } from 'src/helper/helper';
import { CREATE_EVENT_WITH_DEFAULT_BUNDLE } from 'src/redux/actions/event';
import { BaseAction, fetchSaga } from 'src/redux/sagas';
import { getOwnAccount } from 'src/redux/selectors/account';
import { getCompanyLinkByState } from 'src/redux/selectors/company';
import { create } from 'src/services/abstract-repository';
import { CallApiResult } from 'src/services/api';
import { SingleEntityState } from 'src/types';

interface EventAction extends BaseAction {
	data: any;
}

/**
 * Saga that creates an event with a default bundle
 */
function* createEventWithBundleSaga(action: EventAction): SagaIterator {
	yield put(EntityDescriptions.EVENT.action.request(action.projection));

	const timeZone = action.data.timeZone;

	// create event
	let result: CallApiResult = yield call(create, EntityDescriptions.EVENT, action.data, action.projection);

	if (result.error) {
		yield put(EntityDescriptions.EVENT.action.failure(result.error, action.projection));
	} else {
		yield put(EntityDescriptions.BUNDLE.action.request(Projection.NONE));

		const event = new Event(result.response);
		const account: SingleEntityState<AccountFull> = yield select(getOwnAccount);

		const defaultBundle: any = BundleFull.formFields(undefined, account.content?.company?.currency);

		// create default bundle from event and company of logged in user
		defaultBundle.name = event.name;
		defaultBundle.durationInDays = 1;
		defaultBundle.company = yield select(getCompanyLinkByState);
		defaultBundle.event = event.getLink();

		// Convert to ISO strings
		defaultBundle.start = momentWithTimeZoneToISOString(defaultBundle.start, timeZone);
		defaultBundle.end = momentWithTimeZoneToISOString(defaultBundle.end, timeZone);
		defaultBundle.bookableUntil = momentWithTimeZoneToISOString(defaultBundle.bookableUntil, timeZone);

		yield put(EntityDescriptions.BUNDLE.action.request(Projection.NONE));

		// create bundle with previous event
		result = yield call(create, EntityDescriptions.BUNDLE, defaultBundle, Projection.NONE);

		yield result.error
			? put(EntityDescriptions.BUNDLE.action.failure(result.error, Projection.NONE))
			: put(EntityDescriptions.BUNDLE.action.successFetch(result.response, Projection.NONE));

		// re-fetch created event
		action.id = event.id;
		yield call(fetchSaga, EntityDescriptions.EVENT, action);
	}
}

export function* generateCreateEventWithBundleWatcher() {
	yield takeLatest(CREATE_EVENT_WITH_DEFAULT_BUNDLE, createEventWithBundleSaga);
}
