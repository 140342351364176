import Account from 'src/entities/accounts/Account';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import { AutoFormFields } from '../BaseEntity';

export type OperatorFormFields = AutoFormFields<Operator>;

export default class Operator extends Account {
	constructor(json: any) {
		super(json);
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.OPERATOR;
	}

	static formFields(entity?: Operator): OperatorFormFields {
		return {
			...Account.formFields(entity),
		};
	}
}
