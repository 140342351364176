import Money from 'src/entities/basic-types/Money';
import Passenger from 'src/entities/booking/Passenger';
import BundleItemBooking from 'src/entities/bundle/BundleItemBooking';
import { fromJsonArray, fromJsonArrayWith } from 'src/helper/helper';

export default class CombinedBookingItem {
	readonly id: number;

	readonly surcharge: Money;
	readonly price: Money;

	readonly sumSurcharge: Money;
	readonly sumPrice: Money;

	readonly bundleItem: BundleItemBooking;
	passengers: Passenger[][];

	readonly count: number;
	readonly passengerCount: number;

	readonly cancelled: boolean;

	constructor(json: any) {
		this.id = json.bundleItem.id;

		this.surcharge = Money.fromJson(json.surcharge);
		this.price = Money.fromJson(json.price);

		this.sumSurcharge = Money.fromJson(json.sumSurcharge);
		this.sumPrice = Money.fromJson(json.sumPrice);

		this.bundleItem = new BundleItemBooking(json.bundleItem);
		this.passengers = fromJsonArrayWith(passenger => fromJsonArray(Passenger, passenger), json.passengers);

		this.count = json.count;
		this.passengerCount = json.passengerCount;

		this.cancelled = json.cancelled;
	}

	passengersFlat(): Passenger[] {
		return this.passengers.flat();
	}

	passengerIdentifiers(): string {
		return this.passengersFlat()
			.map(passenger => passenger.identifier)
			.join(', ');
	}

	passengerIdentifiersAndFullNames(): string {
		return this.passengersFlat()
			.map(passenger => `${passenger.identifier} (${passenger.fullName()})`)
			.join(', ');
	}
}
