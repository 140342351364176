import moment from 'moment-timezone';
import { Form } from 'semantic-ui-react';
import { DatePicker } from 'src/components/elements/datetime/DatePicker';
import { DateTimePicker } from 'src/components/elements/datetime/DateTimePicker';
import {
	getValidationLabelProps,
	ValidationLabelProps,
} from 'src/components/elements/form-inputs/ValidationLabelProps';

export const DateTimeInput = (field: any) => {
	const { input, label, meta, required, ...rest } = field;

	const validationLabelProps: ValidationLabelProps = getValidationLabelProps(meta, label);

	return (
		<Form.Field error={validationLabelProps.isError} required={required}>
			{validationLabelProps.label}
			<DateTimePicker
				value={getMomentValue(input.value)}
				onChange={input.onChange}
				onBlur={input.onBlur}
				{...rest}
			/>
		</Form.Field>
	);
};

export const DateInput = (field: any) => {
	const { input, label, meta, required, ...rest } = field;

	const validationLabelProps: ValidationLabelProps = getValidationLabelProps(meta, label);

	return (
		<Form.Field error={validationLabelProps.isError} required={required}>
			{validationLabelProps.label}
			<DatePicker
				value={getMomentDateValue(input.value)}
				onChange={input.onChange}
				onBlur={input.onBlur}
				{...rest}
			/>
		</Form.Field>
	);
};

/**
 * Creates a valid moment of the given value.
 * If the given value is null or undefined, undefined will be returned.
 * If the given value is a moment it will be returned with a timezone offset calculated in it.
 * Otherwise, the function tries to return a moment with the given value.
 * If this also fails, undefined will be returned.
 */
export const getMomentValue = (value: any): moment.Moment | undefined => {
	if (!value) return undefined;
	let momentValue = value;
	if (!moment.isMoment(momentValue)) momentValue = moment(momentValue);
	if (!momentValue.isValid()) return undefined;

	return momentValue;
};

/**
 * Creates a valid moment of the given value, for a date (without time).
 *
 * The selected value is in local time (GMT+2), because we are not specifying the specific hours, it defaults to 0:00 (midnight).
 * Ex. Apr 20 2002 00:00:00 GMT+0200
 * At the point of calling the API, the selected value will be converted to UTC, which is 1 day behind (in this case Apr 19).
 * For this reason, we want to calculate the timezone offset and add it to the moment value
 *
 * This is a bug with react-datepicker (from v2.0.0 they abandon moment.js and use date-fns which should solve this problem as well)
 * https://github.com/Hacker0x01/react-datepicker/issues/1018
 */
export const getMomentDateValue = (value: any): moment.Moment | undefined => {
	if (!value) return undefined;
	let momentValue = value;
	if (!moment.isMoment(momentValue)) momentValue = moment(momentValue);
	if (!momentValue.isValid()) return undefined;

	const date = momentValue.toDate();
	const dateWithOffset = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

	return moment(dateWithOffset);
};
