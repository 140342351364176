import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon, Message } from 'semantic-ui-react';
import CookieWrapper from 'src/CookieWrapper';
import AppHelmet from 'src/components/elements/helmet/AppHelmet';
import { EntityDescriptions } from 'src/entities/description';
import { Projection } from 'src/entities/description/EntityDescription';
import { Problem } from 'src/entities/error/Problem';
import { getQueryParameter, useInterval, useTimeout } from 'src/helper/helper';
import { fetchEntityByEndpoint } from 'src/redux/actions';
import { fetchOwnAccount } from 'src/redux/actions/account';
import { getThemedAppProps } from 'src/themes/theme_properties';
import URI from 'urijs';
import { ENV } from './config/env';
import Role from './entities/accounts/Role';
import { Theme } from './entities/integration/Integration';
import IntegrationBooking from './entities/integration/IntegrationBooking';
import { setTheme } from './redux/actions/theme';
import { useAccount } from './redux/selectors/account';
import { getIntegration } from './redux/selectors/integration';
import { ACCOUNT_ROOT } from './scenes/AccountRoutes';
import { BACKEND_ROOT } from './scenes/BackendRoutes';

/**
 * Hook to load the style for the given theme
 * from ./semantic/{theme}/index.useable.less
 */
function useStyleLoader() {
	const styleElement = useRef<HTMLStyleElement>();

	const [loaded, setLoaded] = useState<Theme | null>(null);
	const [loading, setLoading] = useState(false);

	const load = useCallback(
		(theme: Theme, onLoad?: (theme: Theme) => void) => {
			setLoading(true);

			import(`./semantic/${theme.toLowerCase()}/index.useable.less?inline`)
				.then(({ default: css }) => {
					styleElement.current?.remove();

					const styleEl = document.createElement('style');
					styleEl.id = 'theme-style';
					styleEl.textContent = css;
					document.head.append(styleEl);
					styleElement.current = styleEl;

					setLoaded(theme);
					setLoading(false);

					onLoad?.(theme);
				})
				.catch(error => {
					console.error('Error in load style:', error);
					setLoading(false);
				});
		},
		[loaded],
	);

	return { loaded, load, loading };
}

/**
 * Hook to load the theme for the given integration.
 * Applies the integration settings and loads the associated style.
 * Depending on the route (backend/account#asAdminOperator) it will load the backend styles.
 */
function useThemeLoader(integration?: IntegrationBooking) {
	const { isLoggedInWithRole } = useAccount();
	const location = useLocation();
	const dispatch = useDispatch();
	const { i18n } = useTranslation();
	const styleLoader = useStyleLoader();

	const [loadedIntegration, setLoadedIntegration] = useState<number>();

	// The theme we should load
	const themeToLoad = useMemo(() => {
		const msp = location.pathname.split('/')[1];

		return msp === BACKEND_ROOT || (msp === ACCOUNT_ROOT && isLoggedInWithRole(Role.ADMIN, Role.OPERATOR))
			? Theme.BACKEND
			: integration?.theme;
	}, [integration, location.pathname, isLoggedInWithRole]);

	useEffect(() => {
		if (integration == null || loadedIntegration === integration.id || themeToLoad == null) return;

		document.title = integration.name;

		const { forceLanguage } = getThemedAppProps(themeToLoad);
		if (forceLanguage) i18n.changeLanguage(forceLanguage);

		setLoadedIntegration(integration.id);
	}, [i18n.changeLanguage, integration, themeToLoad, loadedIntegration]);

	useEffect(() => {
		if (themeToLoad == null || themeToLoad === styleLoader.loaded || styleLoader.loading) return;

		styleLoader.load(themeToLoad, theme => dispatch(setTheme(theme)));
	}, [styleLoader, dispatch, themeToLoad]);

	return {
		loading: styleLoader.loading,
	};
}

/**
 * Hook to check if the parent is ready.
 * This is used for iframe integrations where we need to pass in the parent location
 * to the internal router.
 */
function useParentReady() {
	const isParentReady = () => (isInIframeContext() ? window.parentLocation != null : true);

	const [ready, setReady] = useState(isParentReady());
	const [timedOut, setTimedOut] = useState(false);

	const checkInterval = useInterval();
	const abortTimeout = useTimeout();

	const check = () => {
		if (isParentReady()) {
			checkInterval.stop();
			abortTimeout.clear();
			setReady(true);
		}
	};

	const abortCheck = () => {
		checkInterval.stop();
		setTimedOut(true);
	};

	useEffect(() => {
		if (ready) return;

		checkInterval.start(check, 10);
		abortTimeout.fire(abortCheck, 3000);
		return () => {
			checkInterval.stop();
			abortTimeout.clear();
		};
	});

	return { ready, timedOut };
}

const ConnectedApp: FC = ({ children }) => {
	const [error, setError] = useState<Problem | undefined>();

	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const parent = useParentReady();

	const integration = useSelector(getIntegration);
	const themeLoader = useThemeLoader(integration.content);

	// Wait for the parent and fetch integration/account data
	useEffect(() => {
		if (parent.ready) {
			redirectIframeFromParentParams();

			const fetchIntegrationURL = URI(ENV.publicUrl).segment('integration');

			dispatch(fetchEntityByEndpoint(EntityDescriptions.INTEGRATION, fetchIntegrationURL, Projection.BOOKING));
			dispatch(fetchOwnAccount());
		} else if (parent.timedOut) setError(Problem.parentLocationProblem(t));
	}, [parent.ready, parent.timedOut]);

	// Configure app with integration settings
	useEffect(() => {
		if (integration.error) {
			const problem = integration.error.problem
				? Problem.fromJson(integration.error.problem)
				: Problem.unexpectedIntegrationProblem(t);
			setError(problem);
		}
	}, [integration]);

	history.listen(location => {
		scrollToTopLeft();

		if ('parentIFrame' in window) {
			window.parentIFrame.sendMessage(location);
		}
	});

	const redirectIframeFromParentParams = () => {
		const hostParams = window.parentLocation ? new URL(window.parentLocation).searchParams : new URLSearchParams();
		const appParams = new URLSearchParams(history.location.search);

		appParams.delete('page');
		appParams.delete('tntpage');

		let pathName = history.location.pathname;

		for (const [key, value] of hostParams) {
			switch (key) {
				case 'page':
					if (!hostParams.has('tntpage')) pathName = value;
					break;
				case 'tntpage':
					pathName = value;
					break;
				case 'search':
					if (value === '') appParams.delete(key);
					else appParams.set(key, value);
					break;
				default:
					appParams.set(key, value);
			}
		}

		const queryString = appParams.toString();
		history.replace([pathName, queryString].filter(x => x !== '').join('?'));
	};

	if (error) {
		const errorKey = error.key ?? 'error.api_unavailable';
		return (
			<Message icon negative size="huge">
				<Icon name="warning sign" />
				<Message.Content>
					<Message.Header>{t(`${errorKey}.header`)}</Message.Header>
					<p>{t(`${errorKey}.body`, error.options)}</p>
				</Message.Content>
			</Message>
		);
	}

	if (themeLoader.loading || !parent.ready || integration.content == null) return null;

	return (
		<>
			<AppHelmet integration={integration.content} />
			<CookieWrapper>{children}</CookieWrapper>
		</>
	);
};

export function getIntegrationUrlAuthority(): string {
	const parentLocation = window.parentLocation;
	const queryParameter = getQueryParameter(window.location, 'integrationUrlAuthority');

	if (parentLocation) return URI(parentLocation).authority();

	return queryParameter ?? URI(window.location).authority();
}

export function getIntegrationId(): string | undefined {
	return getQueryParameter(window.parentLocation ?? '', 'integrationId');
}

export function isInIframeContext(): boolean {
	return parent !== window;
}

export function scrollToTopLeft() {
	window.scrollTo(0, 0);

	if ('parentIFrame' in window) {
		window.parentIFrame.scrollToOffset(0, -90);
	}
}

export default ConnectedApp;
