import { getLocalizedText } from 'src/config/i18next/i18next';
import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';

export type ReviewCategoryFormFields = AutoFormFields<ReviewCategory>;

export default class ReviewCategory extends BaseEntity {
	name: string;
	title: Record<string, string>;
	factor: number;
	order: number;

	constructor(json: any) {
		super(json);

		this.name = json.name;
		this.title = json.title;
		this.factor = json.factor;
		this.order = json.order;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.REVIEW_CATEGORY;
	}

	getLocalizedTitle(): string | undefined {
		return getLocalizedText(this.title);
	}

	static formFields(entity?: ReviewCategory): ReviewCategoryFormFields {
		return {
			...BaseEntity.formFields(entity),
			name: entity?.name,
			title: entity?.title,
			factor: entity?.factor ?? 100,
			order: entity?.order ?? 0,
		};
	}
}
