import FormData from 'form-data';
import { ChangePasswordFormFields } from 'src/components/account/my-profile/change-password/ChangePasswordForm';
import { ChangeUsernameFormFields } from 'src/components/account/my-profile/change-username/ChangeUsernameForm';
import { LoginFormFields } from 'src/components/authentication/LoginForm';
import { ForgotPasswordFormFields } from 'src/components/authentication/password/ForgotPasswordForm';
import { RegisterFormFields } from 'src/components/authentication/RegisterForm';
import { ENV } from 'src/config/env';
import callApi, { CallApiResult, HttpMethod } from 'src/services/api';

/**
 * Executes an login request with the given data
 */
export const login = (data: LoginFormFields): Promise<CallApiResult> => {
	const formData = new FormData();
	formData.append('username', data.username);
	formData.append('password', data.password);

	return callApi(`${ENV.publicUrl}login`, HttpMethod.POST, formData);
};

/**
 * Executes an logout request
 */
export const logout = (): Promise<CallApiResult> => {
	return callApi(`${ENV.publicUrl}logout`, HttpMethod.POST);
};

/**
 * Executes an register request
 */
export const register = (data: RegisterFormFields): Promise<CallApiResult> => {
	return callApi(`${ENV.publicUrl}register`, HttpMethod.POST, data);
};

/**
 * Executes an register confirmation request
 */
export const confirmRegister = (token: string): Promise<CallApiResult> => {
	return callApi(`${ENV.publicUrl}confirm-registration`, HttpMethod.POST, { token });
};

/**
 * Calls the api for the authenticated account
 */
export const fetchAuthenticatedAccount = (): Promise<CallApiResult> => {
	return callApi(`${ENV.publicUrl}account`);
};

/**
 * Executes a change password request for the authenticated account
 */
export const changePassword = (data: ChangePasswordFormFields): Promise<CallApiResult> => {
	delete data.passwordConfirmation;

	return callApi(`${ENV.publicUrl}accounts/change-password`, HttpMethod.POST, data);
};

/**
 * Executes a change username request for the authenticated account
 */
export const requestChangeUsername = (data: ChangeUsernameFormFields): Promise<CallApiResult> => {
	return callApi(`${ENV.publicUrl}accounts/request-change-username`, HttpMethod.POST, data);
};

/**
 * Executes an confirm change username request
 */
export const confirmChangeUsername = (token: string): Promise<CallApiResult> => {
	return callApi(`${ENV.publicUrl}accounts/confirm-change-username`, HttpMethod.POST, { token });
};

/**
 * Executes a forgot password request for the authenticated account
 */
export const forgotPassword = (data: ForgotPasswordFormFields): Promise<CallApiResult> => {
	return callApi(`${ENV.publicUrl}request-password-reset`, HttpMethod.POST, data);
};

/**
 * Executes a reset password request for the authenticated account
 */
export const resetPassword = (token: string, password: string): Promise<CallApiResult> => {
	return callApi(`${ENV.publicUrl}reset-password`, HttpMethod.POST, { token, password });
};

/**
 * Executes a patch request for the authenticated account (customer or reseller).
 */
export const patchOwnCustomerOrResellerAccount = (data: any): Promise<CallApiResult> => {
	return callApi(`${ENV.publicUrl}my-account`, HttpMethod.PATCH, data);
};

/**
 * Executes a patch request for the authenticated account (admin or operator).
 */
export const patchOwnAccount = (data: any): Promise<CallApiResult> => {
	return callApi(`${ENV.apiUrl}accounts/${data.id}`, HttpMethod.PATCH, data);
};
