import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription, { Projection } from 'src/entities/description/EntityDescription';
import { fetchEntities } from 'src/redux/actions';
import { CREATE_BUNDLE_ITEM_WITH_PRODUCT, UPDATE_BUNDLE_ITEM_WITH_PRODUCT } from 'src/redux/actions/bundle-items';
import { BaseAction, createPatchEntitySaga, fetchEntitiesAfterSuccessAction, fetchSaga } from 'src/redux/sagas';
import { create, createBatch } from 'src/services/abstract-repository';
import { CallApiResult } from 'src/services/api';

interface BundleItemAction extends BaseAction {
	bundleId: number;
	productEntityDescription: EntityDescription;
	data: any;
}

function* patchBundleItemWithProductSaga(action: BundleItemAction): SagaIterator {
	const specificProductEntityDescription = action.productEntityDescription;

	yield call(
		createPatchEntitySaga,
		specificProductEntityDescription,
		'UPDATE',
		{
			...action,
			projection: Projection.NONE,
			body: action.data.product,
		},
		action.data.product.version,
	);

	yield call(createPatchEntitySaga, EntityDescriptions.BUNDLE_ITEM, 'UPDATE', {
		...action,
		body: action.data.bundleItem,
	});

	if (specificProductEntityDescription === EntityDescriptions.JOURNEY)
		yield put(
			fetchEntities(
				EntityDescriptions.BUNDLE_ITEM,
				Projection.FULL,
				`findByBundleId?bundleId=${action.bundleId}`,
			),
		);

	// also fetch additional entities if provided
	yield call(fetchEntitiesAfterSuccessAction, action);
}

/**
 * Saga that creates a product with bundle item
 */
function* createBundleItemWithProductSaga(action: BundleItemAction): SagaIterator {
	const specificProductEntityDescription = action.productEntityDescription;

	yield put(specificProductEntityDescription.action.request(Projection.NONE));

	// create product
	const result: CallApiResult = yield call(
		create,
		specificProductEntityDescription,
		action.data.product,
		Projection.NONE,
	);

	if (result.error) {
		yield put(specificProductEntityDescription.action.failure(result.error, Projection.NONE));
	} else {
		yield put(specificProductEntityDescription.action.successFetch(result.response, Projection.NONE));

		// create bundle item on success
		const productLink = result.response._links.self.href;
		yield call(createBundleItem, action, productLink, result.response.id);

		// also fetch additional entities if provided
		yield call(fetchEntitiesAfterSuccessAction, action);
	}
}

/**
 * Saga that creates a bundle item with the given product link
 */
function* createBundleItem(action: BundleItemAction, productLink: string, productId: number) {
	yield put(EntityDescriptions.BUNDLE_ITEM.action.request(action.projection));

	const isBodyArray = action.data.bundleItems && Array.isArray(action.data.bundleItems);

	// populate create props for single bundleItem or array of bundle items
	const props = isBodyArray
		? {
				data: action.data.bundleItems.map((item: any) => {
					return {
						...item,
						product: productId,
					};
				}),
				apiFn: createBatch,
			}
		: {
				data: {
					...action.data.bundleItem,
					product: productLink,
				},
				apiFn: create,
			};

	// create bundle item(s) with product link
	const result: CallApiResult = yield call(
		props.apiFn,
		EntityDescriptions.BUNDLE_ITEM,
		props.data,
		action.projection,
	);

	if (result.error) {
		yield put(EntityDescriptions.BUNDLE_ITEM.action.failure(result.error, action.projection));
	} else {
		if (isBodyArray)
			yield put(EntityDescriptions.BUNDLE_ITEM.action.successFetch(result.response, action.projection, true));
		else {
			// on success -> fetch bundle item with projection
			action.id = result.response.id;
			yield call(fetchSaga, EntityDescriptions.BUNDLE_ITEM, action);
		}

		// also fetch additional entities if provided
		yield call(fetchEntitiesAfterSuccessAction, action);
	}
}

export function* generateCreateBundleItemWithProductWatcher() {
	yield takeLatest(CREATE_BUNDLE_ITEM_WITH_PRODUCT, createBundleItemWithProductSaga);
}

export function* generatePatchBundleItemWithProductWatcher() {
	yield takeLatest(UPDATE_BUNDLE_ITEM_WITH_PRODUCT, patchBundleItemWithProductSaga);
}
