import EventFull from 'src/entities/event/EventFull';
import BundleFull from 'src/entities/bundle/BundleFull';
import { fromJsonArray } from 'src/helper/helper';

export default class EventDetails extends EventFull {
	bundles: BundleFull[];

	constructor(json: any) {
		super(json);

		this.bundles = fromJsonArray(BundleFull, json.bundles);
	}
}
