import Customer from 'src/entities/accounts/Customer';
import Reseller from 'src/entities/accounts/Reseller';
import Booking from 'src/entities/booking/Booking';
import BookingRowFull from 'src/entities/booking/BookingRowFull';
import Company from 'src/entities/companies/Company';
import { createAccount } from 'src/entities/factories/account-factory';
import IdName from 'src/entities/IdName';
import { InsuranceState } from 'src/entities/insurance/Insurance';
import { fromJsonArray } from 'src/helper/helper';

export default class BookingFull extends Booking {
	integration?: IdName;
	account?: Customer | Reseller;
	bookingRows: BookingRowFull[];
	insuranceState: InsuranceState;
	cancelled: boolean;
	changedExternally: boolean;

	constructor(json: any) {
		super(json);
		this.integration = json.integration ? new IdName(json.integration) : undefined;
		this.account = json.account ? createAccount(json.account) : undefined;
		this.bookingRows = fromJsonArray(BookingRowFull, json.bookingRows);
		this.insuranceState = json.insuranceState;
		this.cancelled = json.cancelled;
		this.changedExternally = json.changedExternally;
	}

	getFirstRow(): BookingRowFull {
		return this.bookingRows[0];
	}

	getCompany(): Company {
		return this.getFirstRow().bundle.company;
	}
}
