import moment from 'moment-timezone';
import Role from 'src/entities/accounts/Role';
import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import ContactData from 'src/entities/basic-types/ContactData';

export type AccountFormFields = AutoFormFields<Account>;
/**
 * Account entity acting as a super class for all different account types.
 */
export default abstract class Account extends BaseEntity {
	username: string;
	role: Role;
	registrationSubmitted?: moment.Moment;
	registrationConfirmed?: moment.Moment;
	secondToLastLogin?: moment.Moment;
	lastLogin?: moment.Moment;
	contactData: ContactData;

	protected constructor(json: any) {
		super(json);

		this.username = json.username;
		this.role = json.role;

		this.registrationSubmitted = json.registrationSubmitted ? moment(json.registrationSubmitted) : undefined;
		this.registrationConfirmed = json.registrationConfirmed ? moment(json.registrationConfirmed) : undefined;
		this.secondToLastLogin = json.secondToLastLogin ? moment(json.secondToLastLogin) : undefined;
		this.lastLogin = json.lastLogin ? moment(json.lastLogin) : undefined;
		this.contactData = ContactData.fromJson(json.contactData);
	}

	toString(): string {
		return `${this.className}: ${this.username}`;
	}

	shortUsername(): string {
		return this.username.split('@')[0];
	}

	static formFields(entity?: Account): AccountFormFields {
		return {
			...BaseEntity.formFields(entity),
			username: entity?.username,
			role: entity?.role,
			registrationSubmitted: entity?.registrationSubmitted,
			registrationConfirmed: entity?.registrationConfirmed,
			secondToLastLogin: entity?.secondToLastLogin,
			lastLogin: entity?.lastLogin,
			contactData: ContactData.formValues(entity?.contactData),
		};
	}
}
