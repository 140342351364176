import { Problem } from 'src/entities/error/Problem';

export class APIError {
	message: string;
	method: string;
	status: number;
	problem?: Problem;

	constructor(message: string, method: string, status?: number, problem?: Problem) {
		this.message = message;
		this.status = status ?? -1;
		this.method = method;
		this.problem = problem;
	}

	static fromJson = (json: any): APIError => {
		return new APIError(
			json.message,
			json.method,
			json.status,
			json.problem ? Problem.fromJson(json.problem) : undefined,
		);
	};
}
