import { BookingProgressStep } from 'src/entities/booking/progress/steps/BookingProgressStep';
import { fromJsonArray } from 'src/helper/helper';
import Passenger from '../../Passenger';

export default class PassengerDataStep extends BookingProgressStep {
	readonly passengers: Passenger[];
	readonly needsPassengerName: boolean;
	readonly needsPassengerBirth: boolean;
	readonly needsPassengerPass: boolean;
	readonly needsPassengerGroup: boolean;

	constructor(json: any) {
		super(json);

		this.needsPassengerName = json.needsPassengerName;
		this.needsPassengerBirth = json.needsPassengerBirth;
		this.needsPassengerPass = json.needsPassengerPass;
		this.needsPassengerGroup = json.needsPassengerGroup;
		this.passengers = fromJsonArray(Passenger, json.passengers);
	}
}
