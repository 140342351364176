import { Currency } from 'src/entities/basic-types/Money';
import SearchParams from 'src/entities/booking/SearchParams';
import { buildUrl } from 'src/routing';
import { BOOKING_ROUTES } from 'src/scenes/BookingRoutes';
import { ParsedUrlQuery } from 'src/types';
import PaxStep from './PaxStep';
import ProductStep from './ProductStep';
import ContactDataStep from './ContactDataStep';
import PassengerDataStep from './PassengerDataStep';
import InsuranceStep from './InsuranceStep';
import SummaryStep from './SummaryStep';

export interface GAItem {
	itemId: string;
	itemName: string;
	currency: Currency;
	price: number;
	quantity: number;
}

export enum BookingStep {
	PAX = 'PAX',
	TICKET = 'TICKET',
	ROOM = 'ROOM',
	JOURNEY = 'JOURNEY',
	GENERAL_PRODUCTS = 'GENERAL_PRODUCTS',
	CONTACT_DATA = 'CONTACT_DATA',
	PASSENGER_DATA = 'PASSENGER_DATA',
	INSURANCE = 'INSURANCE',
	SUMMARY = 'SUMMARY',
}

export type BookingStepMap<S extends BookingStep> = S extends BookingStep.PAX
	? PaxStep
	: S extends BookingStep.TICKET | BookingStep.ROOM | BookingStep.JOURNEY | BookingStep.GENERAL_PRODUCTS
		? ProductStep
		: S extends BookingStep.CONTACT_DATA
			? ContactDataStep
			: S extends BookingStep.PASSENGER_DATA
				? PassengerDataStep
				: S extends BookingStep.INSURANCE
					? InsuranceStep
					: S extends BookingStep.SUMMARY
						? SummaryStep
						: never;

export enum BookingProgressStepError {
	INSURANCE_OFFERS_ERROR = 'INSURANCE_OFFERS_ERROR',
	INSURANCE_PROGRESS_ERROR = 'INSURANCE_PROGRESS_ERROR',
	COUPON_NOT_FOUND = 'COUPON_NOT_FOUND',
	COUPON_INVALID = 'COUPON_INVALID',
}

export abstract class BookingProgressStep {
	readonly name: BookingStep;
	readonly completed: boolean;
	error?: BookingProgressStepError;

	protected constructor(json: any) {
		this.name = json.name;
		this.completed = json.completed;
		this.error = json.error ? (json.error as BookingProgressStepError) : undefined;
	}

	getGAItems(): GAItem[] {
		return [];
	}
}

export function getStepPath(name: string, query?: ParsedUrlQuery): string {
	const searchParams = SearchParams.fromQuery(query);
	return buildUrl(BOOKING_ROUTES, undefined, { step: name, ...searchParams.getLocation() });
}
