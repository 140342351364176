import Money from 'src/entities/basic-types/Money';
import Stop from 'src/entities/products/stop/Stop';

export default class NearestStop {
	stop: Stop;
	price: Money;

	constructor(json: any) {
		this.stop = new Stop(json.stop);
		this.price = Money.fromJson(json.price);
	}
}
