import BaseEntity from 'src/entities/BaseEntity';
import ContactData from 'src/entities/basic-types/ContactData';
import Document from 'src/entities/basic-types/Document';
import Location from 'src/entities/basic-types/Location';
import EntityDescription from 'src/entities/description/EntityDescription';
import { EntityDescriptions } from 'src/entities/description';

export default class CompanySearch extends BaseEntity {
	name: string;
	generalConditions: string;
	paymentConditions: string;
	cancellationConditions: string;
	privacyPolicy: string;
	logo?: Document;
	contactData: ContactData;
	location: Location;

	constructor(json: any) {
		super(json);
		this.name = json.name;
		this.generalConditions = json.generalConditions;
		this.cancellationConditions = json.cancellationConditions;
		this.privacyPolicy = json.privacyPolicy;
		this.logo = Document.fromJson(json.logo);
		this.contactData = ContactData.fromJson(json.contactData);
		this.location = Location.fromJson(json.location)!;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.COMPANY;
	}
}
