import Document from 'src/entities/basic-types/Document';
import Ticket from 'src/entities/products/Ticket';
import { fromJsonArrayWith } from 'src/helper/helper';
import { AutoFormFields } from '../BaseEntity';

export type TicketFullFormFields = AutoFormFields<TicketFull>;

export default class TicketFull extends Ticket {
	readonly documents: Document[];

	constructor(json: any) {
		super(json);

		this.documents = fromJsonArrayWith(Document.fromJson, json.documents);
	}

	static formFields(entity?: TicketFull): TicketFullFormFields {
		return {
			...Ticket.formFields(entity),
			documents: entity?.documents ?? [],
		};
	}
}
