import Bundle from 'src/entities/bundle/Bundle';
import CompanyBooking from 'src/entities/companies/CompanyBooking';

export default class BundleCompany extends Bundle {
	company: CompanyBooking;
	readonly supportsInsurance: boolean;

	constructor(json: any) {
		super(json);
		this.company = new CompanyBooking(json.company);
		this.supportsInsurance = json.supportsInsurance;
	}
}
