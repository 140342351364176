import BasicType from 'src/entities/basic-types/BasicType';
import { AutoFormFields } from '../BaseEntity';

export type ContingentFormFields = AutoFormFields<Contingent>;

export default class Contingent implements BasicType {
	// all fields except bookable are undefined in product booking projections
	readonly total?: number;
	booked?: number;
	bookable: number;
	readonly retained?: number;
	readonly bookableLimit?: number;

	constructor(total: number, booked: number, bookable: number, retained: number, bookableLimit: number) {
		this.total = total;
		this.booked = booked;
		this.bookable = bookable;
		this.retained = retained;
		this.bookableLimit = bookableLimit;
	}

	static fromJson(json: any): Contingent {
		return new Contingent(json.total, json.booked, json.bookable, json.retained, json.bookableLimit);
	}

	toString(): string {
		return `${this.total} (${this.bookable})`;
	}

	equals(other: Contingent): boolean {
		return (
			this.total === other.total &&
			this.booked === other.booked &&
			this.bookable === other.bookable &&
			this.retained === other.retained &&
			this.bookableLimit === other.bookableLimit
		);
	}

	static formFields(entity?: Contingent): ContingentFormFields {
		return {
			total: entity?.total,
			booked: entity?.booked,
			bookable: entity?.bookable,
			retained: entity?.retained,
			bookableLimit: entity?.bookableLimit,
		};
	}
}
