import { AutoFormFields } from 'src/entities/BaseEntity';
import Bundle from 'src/entities/bundle/Bundle';
import BundleBooked from 'src/entities/bundle/BundleBooked';
import BundleCancelled from 'src/entities/bundle/BundleCancelled';
import Event from 'src/entities/event/Event';
import Feature from 'src/entities/feature/Feature';
import Tag from 'src/entities/tag/Tag';
import { fromJsonArray, fromJsonArraySortedNumericallyJoined } from 'src/helper/helper';
import { Currency } from '../basic-types/Money';

export type BundleFullFormFields = AutoFormFields<BundleFull>;

export default class BundleFull extends Bundle {
	readonly bundleBookable: boolean;
	readonly bundleBooked: BundleBooked;
	readonly bundleCancelled: BundleCancelled;
	event: Event;
	tags: Tag[];
	features: Feature[];

	readonly csBusBundleId?: string;

	constructor(json: any) {
		super(json);

		this.bundleBookable = json.bundleBookable;
		this.bundleBooked = new BundleBooked(json.bundleBooked);
		this.bundleCancelled = new BundleCancelled(json.bundleBooked);
		this.event = new Event(json.event);
		this.tags = fromJsonArray(Tag, json.tags);
		this.features = fromJsonArray(Feature, json.features);

		this.csBusBundleId = fromJsonArraySortedNumericallyJoined(json.csBusBundleIds);
	}

	static formFields(entity?: BundleFull, currency?: Currency): BundleFullFormFields {
		return {
			...Bundle.formFields(entity, currency),
			event: entity?.event.getLink(),
			tags: entity?.tags.map(tag => tag.getLink()) ?? [],
			features: entity?.features.map(feature => feature.getLink()) ?? [],
			// If the given bundle has been created as default bundle when creating an event we reset the durationInDays for the user to reenter it
			durationInDays:
				entity?.modifiedAt && entity.modifiedAt.diff(entity?.event.createdAt, 'seconds') > 1
					? entity?.durationInDays
					: undefined,
		};
	}
}
