import BaseEntity from 'src/entities/BaseEntity';
import EntityDescription from 'src/entities/description/EntityDescription';
import { EntityDescriptions } from 'src/entities/description';

export default class Tag extends BaseEntity {
	name: string;

	constructor(json: any) {
		super(json);

		this.name = json.name;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.TAG;
	}
}
