import Document from 'src/entities/basic-types/Document';
import PaymentSettings from 'src/entities/basic-types/PaymentSettings';
import Company from 'src/entities/companies/Company';
import { fromJsonArrayWith } from 'src/helper/helper';
import { AutoFormFields } from '../BaseEntity';

export type CompanyFullFormFields = AutoFormFields<CompanyFull>;

export default class CompanyFull extends Company {
	documents: Document[];
	paymentSettings: PaymentSettings;

	agencyNumber: string;

	passengerGroupName: string;
	passengerGroupValues: any[];

	constructor(json: any) {
		super(json);

		this.documents = fromJsonArrayWith(Document.fromJson, json.documents);
		this.paymentSettings = new PaymentSettings(json.paymentSettings);

		this.agencyNumber = json.agencyNumber;

		this.passengerGroupName = json.passengerGroupName;
		this.passengerGroupValues = json.passengerGroupValues;
	}

	static formFields(company?: CompanyFull): CompanyFullFormFields {
		return {
			...Company.formFields(company),
			documents: company?.documents ?? [],
			paymentSettings: PaymentSettings.formFields(company?.paymentSettings),
			agencyNumber: company?.agencyNumber,
			passengerGroupName: company?.passengerGroupName,
			passengerGroupValues: company?.passengerGroupValues ?? [],
		};
	}
}
