import Document from 'src/entities/basic-types/Document';
import Hotel from 'src/entities/products/hotel/Hotel';
import Room from 'src/entities/products/Room';
import { fromJsonArray, fromJsonArrayWith } from 'src/helper/helper';
import { AutoFormFields } from '../BaseEntity';

export type RoomFullFormFields = AutoFormFields<RoomFull>;
export default class RoomFull extends Room {
	readonly documents: Document[];
	hotels: Hotel[];

	constructor(json: any) {
		super(json);

		this.documents = fromJsonArrayWith(Document.fromJson, json.documents);
		this.hotels = fromJsonArray(Hotel, json.hotels);
	}

	hotelNames(): string {
		return this.hotels.map(hotel => hotel.name).join('; ');
	}

	static formFields(entity?: RoomFull): RoomFullFormFields {
		return {
			...Room.formFields(entity),
			documents: entity?.documents ?? [],
			hotels: entity?.hotels.map(hotel => hotel.getLink()) ?? [],
		};
	}
}
