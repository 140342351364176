import { TFunction } from 'i18next';
import { sortBy } from 'lodash';
import moment from 'moment-timezone';
import BasicType from 'src/entities/basic-types/BasicType';
import { PaymentMethod } from 'src/entities/payment/Gateway';

export default class PaymentLog implements BasicType {
	status: string;
	modifiedAt: moment.Moment;
	paymentMethod: PaymentMethod;

	constructor(json: any) {
		this.status = json.status;
		this.modifiedAt = moment(json.modifiedAt);
		this.paymentMethod = json.paymentMethod;
	}

	toString(): string {
		return this.modifiedAt.toString();
	}

	equals(other: PaymentLog): boolean {
		return this.modifiedAt === other.modifiedAt;
	}

	static translatedPaymentMethod = (t: TFunction, data?: PaymentLog): string => {
		if (!data?.paymentMethod) return t('payment_method.invoice');
		return t(`payment_method.${data.paymentMethod.toLowerCase()}`);
	};
}

/**
 * Return the latest payment log. Invoice payment logs are always preferred.
 */
export function getLatestPaymentLog(paymentLogs?: PaymentLog[]): PaymentLog | undefined {
	if (paymentLogs != null && paymentLogs.length > 0) {
		const invoicePaymentLogs = paymentLogs.filter(paymentLog => paymentLog.paymentMethod === PaymentMethod.INVOICE);
		return invoicePaymentLogs.length > 0
			? sortBy(invoicePaymentLogs, 'modifiedBy').reverse()[0]
			: sortBy(paymentLogs, 'modifiedBy').reverse()[0];
	}
	return undefined;
}
