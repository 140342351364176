import BaseEntity from 'src/entities/BaseEntity';
import EntityDescription from 'src/entities/description/EntityDescription';
import { EntityDescriptions } from 'src/entities/description';
import ReviewFull from 'src/entities/review/ReviewFull';
import { isLoggedInWithRole } from 'src/redux/selectors/account';
import Role from 'src/entities/accounts/Role';
import moment from 'moment-timezone';
import BundleCompany from 'src/entities/bundle/BundleCompany';

export default class BookingRowFull extends BaseEntity {
	pax: number;
	cancelled: boolean;
	readonly cancelledAt?: moment.Moment;
	review?: ReviewFull;
	bundle: BundleCompany;

	constructor(json: any) {
		super(json);

		this.pax = Number(json.pax);
		this.cancelled = json.cancelled;
		this.cancelledAt = json.cancelledAt ? moment(json.cancelledAt) : undefined;
		this.review = json.review ? new ReviewFull(json.review) : undefined;
		this.bundle = new BundleCompany(json.bundle);
	}

	toString(): string {
		return String(this.pax);
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.BOOKING_ROW;
	}

	activeReview(): ReviewFull | undefined {
		return this.review != null && (!isLoggedInWithRole(Role.OPERATOR) || this.review.active)
			? this.review
			: undefined;
	}
}
