import Document from 'src/entities/basic-types/Document';
import GeneralProduct from 'src/entities/products/GeneralProduct';
import { fromJsonArrayWith } from 'src/helper/helper';
import { AutoFormFields } from '../BaseEntity';

export type GeneralProductFullFormFields = AutoFormFields<GeneralProductFull>;

export default class GeneralProductFull extends GeneralProduct {
	readonly documents: Document[];
	readonly csBusGeneralProductId?: string;

	constructor(json: any) {
		super(json);

		this.documents = fromJsonArrayWith(Document.fromJson, json.documents);
		this.csBusGeneralProductId = json.csBusGeneralProductId;
	}

	static formFields(entity?: GeneralProductFull): GeneralProductFullFormFields {
		return {
			...GeneralProduct.formFields(entity),
			documents: entity?.documents ?? [],
			csBusGeneralProductId: entity?.csBusGeneralProductId,
		};
	}
}
