import Address from 'src/entities/basic-types/Address';
import BasicType from 'src/entities/basic-types/BasicType';
import CompanyData from 'src/entities/basic-types/CompanyData';
import { Gender } from 'src/entities/basic-types/Gender';
import { joinToString } from 'src/helper/helper';
import { AutoFormFields } from '../BaseEntity';

export type ContactDataFormFields = AutoFormFields<ContactData>;

export default class ContactData implements BasicType {
	gender?: Gender;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	website: string;
	address: Address;
	companyData: CompanyData;

	constructor(
		gender: Gender | undefined,
		firstName: string,
		lastName: string,
		email: string,
		phone: string,
		website: string,
		address: Address,
		companyData: CompanyData,
	) {
		this.gender = gender;
		this.firstName = firstName;
		this.lastName = lastName;
		this.email = email;
		this.phone = phone;
		this.website = website;
		this.address = address;
		this.companyData = companyData;
	}

	fullName(): string {
		return joinToString(' ', this.firstName, this.lastName);
	}

	toString(): string {
		return this.fullName();
	}

	equals(other: ContactData): boolean {
		return (
			this.gender === other.gender &&
			this.firstName === other.firstName &&
			this.lastName === other.lastName &&
			this.email === other.email &&
			this.phone === other.phone &&
			this.website === other.website &&
			this.address.equals(other.address) &&
			this.companyData.equals(other.companyData)
		);
	}

	static fromJson(json: any) {
		return new ContactData(
			json.gender,
			json.firstName,
			json.lastName,
			json.email,
			json.phone || '',
			json.website || '',
			json.address ? Address.fromJson(json.address) : Address.emptyAddress(),
			json.companyData ? CompanyData.fromJson(json.companyData) : CompanyData.emptyCompanyData(),
		);
	}

	static emptyContactData(): ContactData {
		return new ContactData(undefined, '', '', '', '', '', Address.emptyAddress(), CompanyData.emptyCompanyData());
	}

	static formValues(contactData?: ContactData): ContactDataFormFields {
		return {
			gender: contactData?.gender,
			firstName: contactData?.firstName ?? '',
			lastName: contactData?.lastName ?? '',
			email: contactData?.email ?? '',
			phone: contactData?.phone ?? '',
			website: contactData?.website ?? '',
			address: Address.formFields(contactData?.address),
			companyData: CompanyData.formFields(contactData?.companyData),
		};
	}
}
