import { InvoiceFormFields } from 'src/components/booking/backend/InvoiceForm';
import { ENV } from 'src/config/env';
import Booking from 'src/entities/booking/Booking';
import BookingDTO from 'src/entities/booking/dto/BookingDTO';
import { PaymentMethod } from 'src/entities/payment/Gateway';
import callApi, { HttpMethod } from 'src/services/api';
import URI from 'urijs';

export const fetchBookingProcessByBundleSlug = (slug: string, bookingDTO?: BookingDTO) => {
	return callApi(`${ENV.publicUrl}bundle?slug=${slug}`, HttpMethod.PATCH, bookingDTO ?? null);
};

export const fetchBookingProcessByBundleId = (bundleId: string, bookingDTO?: BookingDTO) => {
	return callApi(`${ENV.publicUrl}book-bundle?bundleId=${bundleId}`, HttpMethod.PATCH, bookingDTO ?? null);
};

// TODO: Remove when no integrations are fetching by csBusEventId/csBusBundleId anymore
export const fetchBookingProcessByBundleCsBus = (
	csBusEventId: string,
	csBusBundleId: string,
	bookingDTO?: BookingDTO,
) => {
	return callApi(
		`${ENV.publicUrl}bundle-csbus?csBusEventId=${csBusEventId}&csBusBundleId=${csBusBundleId}`,
		HttpMethod.PATCH,
		bookingDTO ?? null,
	);
};

export const updateBooking = (bookingDTO: BookingDTO) => {
	return callApi(`${ENV.publicUrl}update-booking`, HttpMethod.PATCH, bookingDTO);
};

export const submitBooking = (bookingDTO: BookingDTO) => {
	return callApi(`${ENV.publicUrl}submit-booking`, HttpMethod.POST, bookingDTO);
};

export const submitBookingMetaFlags = (booking: Booking, metaFlags: Record<string, boolean>) => {
	return callApi(`${ENV.publicUrl}patchMetaFlags?token=${booking.token}`, HttpMethod.PATCH, metaFlags);
};

export const generateMarginalTaxInvoice = (bookingId: number) => {
	return callApi(`${ENV.apiUrl}bookings/${bookingId}/generateMarginalTaxInvoice`, HttpMethod.PATCH);
};

export const generateRegularTaxInvoice = (bookingId: number, productTaxes: InvoiceFormFields) => {
	return callApi(`${ENV.apiUrl}bookings/${bookingId}/generateRegularTaxInvoice`, HttpMethod.PATCH, productTaxes);
};

export const deletePendingBooking = (bookingId: number) => {
	return callApi(`${ENV.apiUrl}bookings/${bookingId}/deletePendingBooking`, HttpMethod.DELETE);
};

export const acceptPendingBooking = (bookingId: number) => {
	return callApi(`${ENV.apiUrl}bookings/${bookingId}/acceptPendingBooking`, HttpMethod.PATCH);
};

export const repayBooking = (token: string, paymentMethod?: PaymentMethod) => {
	const uri = URI(ENV.publicUrl).segment('repayBooking').addSearch('token', token);

	if (paymentMethod) uri.addSearch('paymentMethod', paymentMethod);

	return callApi(uri.toString(), HttpMethod.PATCH);
};
