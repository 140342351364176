import ReactDOM from 'react-dom';
import App from 'src/App';
import { AppShell } from 'src/AppShell';

const rootElement = document.querySelector('#root');
const application = (
	<AppShell>
		<App />
	</AppShell>
);

ReactDOM.render(application, rootElement);
