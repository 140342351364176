import Document from 'src/entities/basic-types/Document';
import Company from 'src/entities/companies/Company';
import { fromJsonArray, fromJsonArrayWith } from 'src/helper/helper';
import Gateway from 'src/entities/payment/Gateway';

export default class CompanyBooking extends Company {
	readonly passengerGroupName: string;
	readonly passengerGroupValues: any[];
	readonly documents: Document[];
	readonly paymentGateways: Gateway[];
	readonly supportsInsurance: boolean;

	constructor(json: any) {
		super(json);

		this.passengerGroupName = json.passengerGroupName;
		this.passengerGroupValues = json.passengerGroupValues;
		this.documents = fromJsonArrayWith(Document.fromJson, json.documents);
		this.paymentGateways = fromJsonArray(Gateway, json.paymentGateways);
		this.supportsInsurance = json.supportsInsurance;
	}
}
