import { UnknownAction } from '@reduxjs/toolkit';
import { PUT_BREADCRUMBS, RESOLVE_BREADCRUMBS } from 'src/redux/actions/breadcrumbs';
import { BreadcrumbState } from 'src/types';

/**
 * Reducer that manges the current active tab index of a semantic ui Tab
 */
function breadcrumbsReducer(state: BreadcrumbState | null = null, action: UnknownAction) {
	switch (action.type) {
		case RESOLVE_BREADCRUMBS:
			return { raw: action.breadcrumbs, resolved: [] };
		case PUT_BREADCRUMBS:
			return { ...state, resolved: action.breadcrumbs };
		default:
			return state ?? { raw: [], resolved: [] };
	}
}

export default breadcrumbsReducer;
