export enum PaymentMethod {
	EPS = 'EPS',
	MAESTRO = 'MAESTRO',
	MASTERCARD = 'MASTERCARD',
	DIRECTBANK = 'DIRECTBANK',
	VISA = 'VISA',
	PAYPAL = 'PAYPAL',
	INVOICE = 'INVOICE',
}

export default class Gateway {
	id: PaymentMethod;
	description: string;
	logo?: any;

	constructor(json: any) {
		this.id = json.id;
		this.description = json.description;
		this.logo = logoById(json.id);
	}

	static invoiceGateway(): Gateway {
		return new Gateway({
			id: PaymentMethod.INVOICE,
			description: 'invoice',
		});
	}
}

const logoById = (id: PaymentMethod): any => {
	switch (id) {
		case PaymentMethod.DIRECTBANK:
			return '/resources/sofort-de.png';
		case PaymentMethod.EPS:
			return '/resources/eps.png';
		case PaymentMethod.MAESTRO:
			return '/resources/maestro.png';
		case PaymentMethod.MASTERCARD:
			return '/resources/mastercard.png';
		case PaymentMethod.VISA:
			return '/resources/visa.png';
		case PaymentMethod.PAYPAL:
			return '/resources/paypal.png';
		default:
			return '/resources/invoice.png';
	}
};
