import { UnknownAction } from '@reduxjs/toolkit';
import BaseEntity from 'src/entities/BaseEntity';
import { AccountFull } from 'src/entities/accounts/AccountFull';
import Role from 'src/entities/accounts/Role';
import EntityDescription, { CollectionFetchStrategy, Projection } from 'src/entities/description/EntityDescription';

export const FETCH = 'FETCH';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';

export const REQUEST = 'REQUEST';
export const SUCCESS_FETCH = 'SUCCESS_FETCH';
export const SUCCESS_DELETE = 'SUCCESS_DELETE';
export const NOT_MODIFIED = 'NOT_MODIFIED';
export const FAILURE = 'FAILURE';

export const ROUTE_CHANGED = '@@router/LOCATION_CHANGE';
export const RESET_STORE = 'RESET_STORE';

export interface FetchDescription {
	entityDescription: EntityDescription;
	id?: number;
	projection: Projection;
	searchMethod?: string;
}

/**
 * Dispatches a {@link FETCH} action for the given entityDescription, that will fetch all items depending on the given account.
 * If the account contains a company, only items that are related to this company will be fetched. Else all items will be fetched.
 */
export const fetchEntitiesByAccount = (
	entityDescription: EntityDescription,
	account: AccountFull,
	projection?: Projection,
	customByCompanyMethod?: string,
	customByAccountMethod?: string,
) => {
	if (account != null) {
		if (
			(account.role === Role.ADMIN || account.role === Role.OPERATOR) &&
			entityDescription.collectionFetchStrategy === CollectionFetchStrategy.FIND_BY_COMPANY &&
			account.company
		) {
			const searchMethod = customByCompanyMethod ?? 'findByCompanyId';
			return fetchEntities(entityDescription, projection, `${searchMethod}?companyId=${account.company!.id}`);
		}
		if (account.role === Role.CUSTOMER || account.role === Role.RESELLER) {
			const searchMethod = customByAccountMethod ?? 'findByAccountId';
			return fetchEntities(entityDescription, projection, `${searchMethod}?accountId=${account.id}`);
		}
	}

	return fetchEntities(entityDescription, projection);
};

/**
 * Dispatches a {@link FETCH} action for the given entityDescription
 */
export const fetchEntities = (
	entityDescription: EntityDescription,
	projection?: Projection,
	searchMethod?: string,
	publicEndpoint?: boolean,
) => action(`${entityDescription.name}_${FETCH}`, { projection, searchMethod, publicEndpoint });

/**
 * Dispatches a {@link FETCH} action for a single entity by id
 */
export const fetchEntity = (entityDescription: EntityDescription, id: number, projection?: Projection) =>
	action(`${entityDescription.name}_${FETCH}`, {
		projection,
		id,
	});

/**
 * Dispatches a {@link FETCH} action for a single entity by the given endpoint
 */
export const fetchEntityByEndpoint = (entityDescription: EntityDescription, endpoint: URI, projection?: Projection) =>
	action(`${entityDescription.name}_${FETCH}`, { projection, endpoint });

/**
 * Dispatches a {@link CREATE} action for the given entity.
 * fetchAfterSuccess is an optional single entity that should be fetched after a successful creation.
 */
export const createEntity = (
	entityDescription: EntityDescription,
	body: any,
	projection?: Projection,
	fetchAfterSuccess?: FetchDescription[],
) => action(`${entityDescription.name}_${CREATE}`, { projection, body, fetchAfterSuccess });

/**
 * Dispatches a {@link UPDATE} action for the given entity.
 * fetchAfterSuccess is an optional single entity that should be fetched after a successful creation.
 */
export const patchEntity = (
	entityDescription: EntityDescription,
	body: any,
	projection?: Projection,
	fetchAfterSuccess?: FetchDescription[],
	patchMethod?: string,
) => {
	if (!body.id) throw new Error('patchEntity(): Missing id of entity in body!');

	return action(`${entityDescription.name}_${UPDATE}`, { projection, body, fetchAfterSuccess, patchMethod });
};

/**
 * Dispatches a {@link DELETE} action for the given entity.
 * fetchAfterSuccess is an optional single entity that should be fetched after a successful creation.
 */
export const deleteEntity = (
	entityDescription: EntityDescription,
	id: number,
	projection?: Projection,
	fetchAfterSuccess?: FetchDescription[],
	actionAfterSuccess?: (entity: BaseEntity) => void,
) => action(`${entityDescription.name}_${DELETE}`, { id, projection, fetchAfterSuccess, actionAfterSuccess });

/**
 * Enhances the given entityDescription with several actions for
 * request, success and failure cases in api actions
 */
export function createEntityActions(entityDescription: EntityDescription) {
	entityDescription.action = {
		request: (projection?: string) =>
			action(`${entityDescription.name}_${REQUEST}`, {
				projection,
			}),
		successFetch: (response: any, projection?: string, mergeItems?: boolean) =>
			action(`${entityDescription.name}_${SUCCESS_FETCH}`, {
				entityDescription,
				projection,
				response,
				mergeItems,
			}),
		notModified: (projection?: string) =>
			action(`${entityDescription.name}_${NOT_MODIFIED}`, {
				entityDescription,
				projection,
			}),
		successDelete: (response: any, projection?: string) =>
			action(`${entityDescription.name}_${SUCCESS_DELETE}`, {
				entityDescription,
				projection,
				response,
			}),
		failure: (error: any, projection?: string) =>
			action(`${entityDescription.name}_${FAILURE}`, {
				error,
				projection,
			}),
	};
}

/**
 * Dispatches a reset store action.
 * When this action gets dispatched, the whole redux store gets reset to it's initial state
 */
export const resetStore = () => action(RESET_STORE);

/**
 * Helper function to produces the right shape of a reducer action.
 */
export function action(type: string, payload: any = {}): UnknownAction {
	return {
		type,
		...payload,
	};
}
