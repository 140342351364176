import Bundle from 'src/entities/bundle/Bundle';
import EventFull from 'src/entities/event/EventFull';
import Feature from 'src/entities/feature/Feature';
import Tag from 'src/entities/tag/Tag';
import { fromJsonArray, fromJsonArraySortedNumericallyJoined } from 'src/helper/helper';
import { AutoFormFields } from '../BaseEntity';
import { Currency } from '../basic-types/Money';
import BundleFull from './BundleFull';

export type BundleDetailsFormFields = AutoFormFields<BundleDetails>;

export default class BundleDetails extends Bundle {
	event: EventFull;
	tags: Tag[];
	features: Feature[];

	readonly csBusBundleId?: string;

	constructor(json: any) {
		super(json);

		this.event = new EventFull(json.event);
		this.tags = fromJsonArray(Tag, json.tags);
		this.features = fromJsonArray(Feature, json.features);

		this.csBusBundleId = fromJsonArraySortedNumericallyJoined(json.csBusBundleIds);
	}

	static formFields(entity?: BundleDetails | BundleFull, currency?: Currency): BundleDetailsFormFields {
		return {
			...Bundle.formFields(entity, currency),
			event: entity?.event.getLink(),
			tags: entity?.tags.map(tag => tag.getLink()) ?? [],
			features: entity?.features.map(feature => feature.getLink()) ?? [],
		};
	}
}
