import { Action } from '@reduxjs/toolkit';
import { assign } from 'lodash';
import BookingProgress from 'src/entities/booking/progress/BookingProgress';
import { Problem } from 'src/entities/error/Problem';
import {
	FAILURE_BOOKING_PROGRESS,
	FAILURE_INSURANCE_PROGRESS,
	REQUEST_BOOKING_PROGRESS,
	SET_BOOKING_PROGRESS_STEP_ERROR,
	SET_CHECKED_PRIVACY,
	SET_CHECKED_TERMS,
	SET_CURRENT_STEP,
	SET_PAX,
	START,
	SUCCESS_FETCH_BOOKING_PROGRESS,
	SUCCESS_SUBMIT_BOOKING,
} from 'src/redux/actions/booking-progress';
import { singleEntityDefaultState } from 'src/redux/selectors';
import { SingleEntityState } from 'src/types';

interface BookingProgressAction extends Action {
	pax: number;
	checked: boolean;
	step: any;
	response: any;
	error: any;
}

export function bookingProgressReducer(
	state: SingleEntityState<BookingProgress> = singleEntityDefaultState,
	action: BookingProgressAction,
) {
	switch (action.type) {
		case START: {
			const content = state.content as BookingProgress;

			if (state.content) state.content.getFirstRow().start = true;

			return assign({}, state, { content });
		}
		case SET_PAX: {
			const content = state.content as BookingProgress;

			if (state.content) state.content.getFirstRow().setPax(action.pax);

			return assign({}, state, { content });
		}
		case SET_CHECKED_TERMS: {
			const content = state.content as BookingProgress;

			if (state.content) state.content.getFirstRow().setCheckedTerms(action.checked);

			return assign({}, state, { content });
		}
		case SET_CHECKED_PRIVACY: {
			const content = state.content as BookingProgress;

			if (state.content) state.content.getFirstRow().setCheckedPrivacy(action.checked);

			return assign({}, state, { content });
		}
		case SET_CURRENT_STEP: {
			const content = state.content as BookingProgress;

			if (state.content) state.content.getFirstRow().setCurrentStep(action.step);

			return assign({}, state, { content });
		}
		case SET_BOOKING_PROGRESS_STEP_ERROR: {
			if (!action.step || !action.error) return state;

			const content = state.content as BookingProgress;

			if (state.content) state.content.getFirstRow().setBookingProgressStepError(action.step, action.error);

			return assign({}, state, { isFetching: false, content });
		}
		case REQUEST_BOOKING_PROGRESS:
			return assign({}, state, { isFetching: true, error: undefined });
		case SUCCESS_FETCH_BOOKING_PROGRESS: {
			const content = BookingProgress.fromJson(action.response);
			return assign({}, state, { isFetching: false, content, error: undefined });
		}
		case SUCCESS_SUBMIT_BOOKING: {
			return assign({}, state, { isFetching: false, content: undefined, error: undefined });
		}
		case FAILURE_INSURANCE_PROGRESS: {
			const content = state.content as BookingProgress;

			content.getFirstRow().setInsuranceProgressError();

			return assign({}, state, { isFetching: false, content });
		}
		case FAILURE_BOOKING_PROGRESS: {
			const content = state.content as BookingProgress;

			if (action.error.problem) {
				// Reset product steps and pax and token on booking error
				// Don't reset on negative_or_zero price, just keep the old state
				if (isBookingError(action.error.problem) && !isNegativeOrZeroBookingError(action.error.problem)) {
					content.reset();
				}
			}

			return assign({}, state, { isFetching: false, content, error: action.error });
		}
		default:
			return state;
	}
}

export const isPaymentError = (error: Problem): boolean => {
	if (!error.key) return false;

	return error.key.startsWith('error.payment');
};

export const isBookingError = (error: Problem): boolean => {
	if (!error.key) return false;

	return error.key.startsWith('error.booking');
};

export const isNegativeOrZeroBookingError = (error: Problem): boolean => {
	if (!error.key) return false;

	return error.key === 'error.booking.negative_or_zero';
};

export const isBookingAlreadySubmitted = (error: Problem): boolean => {
	return error && isBookingError(error) && error.key === 'error.booking.already_exist';
};

export const isErrorWithTranslationKey = (error?: Problem): boolean => {
	if (!error?.key) return false;

	const translationKeys = [
		'error.booking.bundle_contingent',
		'error.booking.product_contingent',
		'error.booking.bookable_until',
		'error.booking.negative_or_zero',
	];

	return translationKeys.includes(error.key);
};
