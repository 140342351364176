import EntityDescription from 'src/entities/description/EntityDescription';
import { EntityDescriptions } from 'src/entities/description';
import Invoice from 'src/entities/invoice/Invoice';
import moment from 'moment-timezone';

export default class AdminInvoice extends Invoice {
	periodFrom: moment.Moment;
	periodTill: moment.Moment;
	commission: number;

	constructor(json: any) {
		super(json);

		this.periodFrom = moment(json.periodFrom);
		this.periodTill = moment(json.periodTill);
		this.commission = json.commission;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.ADMIN_INVOICE;
	}
}
