import { ENV } from 'src/config/env';
import EntityDescription, { Projection } from 'src/entities/description/EntityDescription';
import callApi, { CallApiResult, HttpMethod } from 'src/services/api';
import { RequestHeaders } from 'src/types';
import URI from 'urijs';

/**
 * Fetch all items for the given entity.
 * If the projection is present, it will be used to get the desired shape of the entity
 */
export const fetchAll = (
	entityDescription: EntityDescription,
	projection: Projection = Projection.NONE,
): Promise<CallApiResult> => {
	const endpoint = URI(`${ENV.apiUrl}${entityDescription.repository}`);

	if (projection !== Projection.NONE) endpoint.addSearch('projection', projection);

	return callApi(endpoint.valueOf());
};

/**
 * Fetch a single item of an entity by the given id.
 * If the projection is present, it will be used to get the desired shape of the entity
 */
export const fetchById = (
	entityDescription: EntityDescription,
	id: number,
	projection: Projection = Projection.NONE,
	headers?: RequestHeaders,
): Promise<CallApiResult> => {
	const endpoint = URI(`${ENV.apiUrl}${entityDescription.repository}/${id}`);

	if (projection !== Projection.NONE) endpoint.addSearch('projection', projection);

	return callApi(endpoint.valueOf(), HttpMethod.GET, undefined, headers);
};

export const fetchSingleEntityByEndpoint = (
	endpoint: URI,
	projection: Projection = Projection.NONE,
	headers?: RequestHeaders,
): Promise<CallApiResult> => {
	if (projection !== Projection.NONE) endpoint.addSearch('projection', projection);

	return callApi(endpoint.valueOf(), HttpMethod.GET, undefined, headers);
};

/**
 * Fetch entities by the given searchMethod
 * If the projection is present, it will be used to get the desired shape of the entity
 */
export function search(
	entityDescription: EntityDescription,
	searchMethod: string,
	projection: Projection = Projection.NONE,
	publicEndpoint: boolean,
): Promise<CallApiResult> {
	// distinguish between public booking endpoint or api
	const endpointPrefix = publicEndpoint ? `${ENV.publicUrl}` : `${ENV.apiUrl}${entityDescription.repository}/search/`;

	const endpoint = URI(`${endpointPrefix}${searchMethod}`);

	if (projection !== Projection.NONE) endpoint.addSearch('projection', projection);

	return callApi(endpoint.valueOf());
}

/**
 * Creates a new entity with the given body.
 * If the projection is present, it will be used to get the desired shape of the new crated entity in the response.
 */
export const create = (
	entityDescription: EntityDescription,
	body: any,
	projection: Projection = Projection.NONE,
	headers?: RequestHeaders,
): Promise<CallApiResult> => {
	return callApi(`${ENV.apiUrl}${entityDescription.repository}`, HttpMethod.POST, body, headers);
};

/**
 * Batch creates entities of the given description
 * The response will contain all created items
 */
export const createBatch = (
	entityDescription: EntityDescription,
	body: any[],
	projection: Projection = Projection.NONE,
	headers?: RequestHeaders,
): Promise<CallApiResult> => {
	return callApi(`${ENV.apiUrl}${entityDescription.repository}/batch`, HttpMethod.POST, body, headers);
};

/**
 * Patches an existing entity with the given body.
 * If the projection is present, it will be used to get the desired shape of the patched entity in the response.
 */
export const patch = (
	entityDescription: EntityDescription,
	body: any,
	projection: Projection = Projection.NONE,
	headers?: RequestHeaders,
	patchMethod?: string,
): Promise<CallApiResult> => {
	let endpoint = `${ENV.apiUrl}${entityDescription.repository}/${body.id}`;
	if (patchMethod != null) endpoint += `/${patchMethod}`;
	return callApi(endpoint, HttpMethod.PATCH, body, headers);
};

/**
 * Deletes an entity by id
 */
export const deleteById = (
	entityDescription: EntityDescription,
	id: number,
	headers?: RequestHeaders,
): Promise<CallApiResult> => {
	return callApi(`${ENV.apiUrl}${entityDescription.repository}/${id}`, HttpMethod.DELETE, undefined, headers);
};
