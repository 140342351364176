export class Violation {
	field: string;
	message: string;

	constructor(field: string, message: string) {
		this.field = field;
		this.message = message;
	}

	static fromJson = (json: any): Violation => {
		return new Violation(json.field, json.message);
	};
}
