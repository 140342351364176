import { UnknownAction } from '@reduxjs/toolkit';
import { CLEAR_NOTIFICATION, SET_NOTIFICATION } from 'src/redux/actions/system-notifications';
import { Notification } from 'src/types';

function notificationReducer(state: Notification | null = null, action: UnknownAction) {
	switch (action.type) {
		case SET_NOTIFICATION: {
			return {
				notificationType: action.notificationType,
				statusCode: action.statusCode,
				requestMethod: action.requestMethod,
				entityDescription: action.entityDescription,
				additionalNotificationProps: action.additionalNotificationProps,
				key: action.key,
				options: action.options,
			};
		}
		case CLEAR_NOTIFICATION: {
			return null;
		}
		default:
			return state;
	}
}

export default notificationReducer;
