import BundleBooking from 'src/entities/bundle/BundleBooking';
import CategoryBooking from 'src/entities/category/CategoryBooking';
import Company from 'src/entities/companies/Company';
import EventLocationBooking from 'src/entities/event-location/EventLocationBooking';
import Event from 'src/entities/event/Event';
import { fromJsonArray } from 'src/helper/helper';

export default class EventBooking extends Event {
	company: Company;
	eventLocation: EventLocationBooking;
	bundles: BundleBooking[];
	categories: CategoryBooking[];

	constructor(json: any) {
		super(json);

		this.company = new Company(json.company);
		this.eventLocation = new EventLocationBooking(json.eventLocation);
		this.bundles = fromJsonArray(BundleBooking, json.bundles);
		this.categories = fromJsonArray(CategoryBooking, json.categories);
	}
}
