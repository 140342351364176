import { TFunction } from 'i18next';
import { SemanticICONS } from 'semantic-ui-react';
import { enumMenuItems } from 'src/components/elements/form-inputs/SelectField';
import { renderTransport } from 'src/components/elements/sugar-coater/SugarCoater';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import Product from 'src/entities/products/Product';
import { joinToString } from 'src/helper/helper';
import { AutoFormFields } from '../BaseEntity';

export type JourneyFormFields = AutoFormFields<Journey>;

export enum Transport {
	BUS = 'BUS',
	TRAIN = 'TRAIN',
	SHIP = 'SHIP',
	PLANE = 'PLANE',
}

export const transportMenuItems = (t: TFunction) => enumMenuItems(Transport, 'entities:journey.transport.options', t);

export default class Journey extends Product {
	transport: Transport;

	constructor(json: any) {
		super(json);

		this.transport = json.transport;
	}

	getItemDescription(t: TFunction): string {
		return joinToString(' | ', this.name, renderTransport(this.transport, t));
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.JOURNEY;
	}

	getTravelTypeIcon(): SemanticICONS {
		switch (this.transport) {
			case Transport.BUS:
				return 'bus';
			case Transport.TRAIN:
				return 'train';
			case Transport.SHIP:
				return 'ship';
			case Transport.PLANE:
				return 'plane';
			default:
				return 'suitcase';
		}
	}

	static formFields(entity?: Journey): JourneyFormFields {
		return {
			...Product.formFields(entity),
			transport: entity?.transport,
		};
	}
}
