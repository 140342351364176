import { TFunction, TOptions } from 'i18next';
import { ENV } from 'src/config/env';
import { Violation } from 'src/entities/error/Violation';
import { fromJsonArrayWith } from 'src/helper/helper';

/**
 * Problem details.
 * https://tools.ietf.org/html/rfc7807
 * https://opensource.zalando.com/problem/constraint-violation/
 */
export class Problem {
	status: number;
	title: string;
	violations: Violation[];
	type?: string;
	details?: string;
	instance?: string;
	key?: string;
	options?: TOptions;

	constructor(
		status: number,
		title: string,
		type?: string,
		details?: string,
		instance?: string,
		key?: string,
		options?: TOptions,
		violations?: Violation[],
	) {
		this.status = status;
		this.title = title;
		this.type = type;
		this.details = details;
		this.instance = instance;
		this.key = key;
		this.options = options;
		this.violations = violations ?? [];
	}

	static fromJson = (json: any): Problem => {
		return new Problem(
			json.status,
			json.title,
			json.type,
			json.details,
			json.instance,
			json.key,
			json.options,
			json.violations ? fromJsonArrayWith(Violation.fromJson, json.violations) : [],
		);
	};

	static parentLocationProblem = (t: TFunction): Problem => {
		return new Problem(
			1,
			'Parent Location Error',
			undefined,
			'Did not receive parent location',
			undefined,
			'error.parent_location',
			{ domain: ENV.mainUrl },
		);
	};

	static unexpectedIntegrationProblem = (t: TFunction): Problem => {
		return new Problem(
			1,
			'Integration Error',
			undefined,
			'Unexpected integration problem',
			undefined,
			'error.integration_not_found',
			{ domain: ENV.mainUrl },
		);
	};
}
