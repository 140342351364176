import Reseller from 'src/entities/accounts/Reseller';
import Company from 'src/entities/companies/Company';
import { AutoFormFields } from '../BaseEntity';

export type ResellerFullFormFields = AutoFormFields<ResellerFull>;

export default class ResellerFull extends Reseller {
	company?: Company;

	constructor(json: any) {
		super(json);
		this.company = json.company ? new Company(json.company) : undefined;
	}

	static formFields(entity?: ResellerFull): ResellerFullFormFields {
		return {
			...Reseller.formFields(entity),
			company: entity?.company?.getLink(),
		};
	}
}
