import Company from 'src/entities/companies/Company';
import Event from 'src/entities/event/Event';
import { fromJsonArray } from 'src/helper/helper';
import Bundle from 'src/entities/bundle/Bundle';

export default class EventSearch extends Event {
	company: Company;
	bundles: Bundle[];

	constructor(json: any) {
		super(json);

		this.company = new Company(json.company);
		this.bundles = fromJsonArray(Bundle, json.bundles);
	}

	/**
	 * Returns the cheapest bookable bundle of this event
	 * or undefined if no bookable bundle is available
	 */
	getCheapestBookableBundle(externalIntegrationContingent: boolean): Bundle | undefined {
		const bookableBundles = this.bundles.filter(
			(bundle: Bundle) =>
				bundle.active && bundle.bookableNow && bundle.getBookable(externalIntegrationContingent) > 0,
		);

		return bookableBundles.reduce<Bundle | undefined>((prev: Bundle, current: Bundle) => {
			if (!prev) return current;
			if (!current) return prev;
			return current.getCheapestPrice().amount.isLessThan(prev.getCheapestPrice().amount) ? current : prev;
		}, undefined);
	}

	/**
	 * Determines the booking status for this event.
	 * If the event is bookable at one of the given companies, the cheapest bundle will be returned in addition.
	 */
	getCheapestBookableBundleStatus = (
		companyIds: number[],
		externalIntegrationContingent: boolean,
	): EventCheapestBundleStatus => {
		// company is not on integration
		if (!companyIds.includes(this.company.id)) return { status: BookableEventStatus.COMPANY_NOT_FOUND };

		// event not active
		if (!this.active) return { status: BookableEventStatus.EVENT_NOT_ACTIVE };

		// event is no top event
		if (!this.topEvent) return { status: BookableEventStatus.NO_TOP_EVENT };

		const cheapestBundle = this.getCheapestBookableBundle(externalIntegrationContingent);

		// no bundle is bookable from this event
		if (!cheapestBundle) return { status: BookableEventStatus.NO_BOOKABLE_BUNDLE };

		// event is bookable
		return {
			status: BookableEventStatus.OK,
			cheapestBundle,
		};
	};
}

export interface EventCheapestBundleStatus {
	status: BookableEventStatus;
	cheapestBundle?: Bundle;
}

export enum BookableEventStatus {
	COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND',
	NO_TOP_EVENT = 'NO_TOP_EVENT',
	EVENT_NOT_ACTIVE = 'EVENT_NOT_ACTIVE',
	NO_BOOKABLE_BUNDLE = 'NO_BOOKABLE_BUNDLE',
	OK = 'OK',
}
