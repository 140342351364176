import { TFunction } from 'i18next';
import moment from 'moment-timezone';
import { MenuItem } from 'src/components/elements/form-inputs/SelectField';
import { ENV } from 'src/config/env';
import EntityDescription from 'src/entities/description/EntityDescription';
import Document from './basic-types/Document';
import Location from './basic-types/Location';
import Money from './basic-types/Money';

/**
 * Every Entity extends this BaseEntity
 * containing the following metadata.
 */

export type BaseEntityFormFields = {
	id?: number;
};

export default abstract class BaseEntity {
	readonly id: number;
	readonly version: number;
	active: boolean;
	readonly createdAt: moment.Moment;
	readonly modifiedAt: moment.Moment;
	readonly className: string;
	readonly identifier: string;
	readonly _links: any;

	protected constructor(json: any) {
		this.id = json.id;
		this.version = json.version;
		this.active = json.active;
		this.createdAt = moment(json.createdAt);
		this.modifiedAt = moment(json.modifiedAt);
		this.className = json.className;
		this.identifier = json.identifier;
		this._links = json._links;
	}

	abstract toString(): string;

	abstract getEntityDescription(): EntityDescription;

	static formFields(entity?: BaseEntity): AutoFormFields<BaseEntity> {
		return {
			id: entity?.id,
		};
	}

	public getLink(): string {
		const repository = this.getEntityDescription().repository;
		return `${ENV.apiUrl}${repository}/${this.id}`;
	}

	public getMenuItem(t: TFunction): MenuItem {
		return {
			key: this.id,
			text: this.toString(),
			value: this.getLink(),
			description: this.identifier,
		};
	}
}

type IsFunction<T> = T extends (...args: any[]) => any ? true : false;
type IsComplexObject<T> = T extends object ? (object extends T ? false : true) : false;
type IsType<T, C> = T extends C | undefined ? true : false;

/**
 * Extracts all field keys of a given type.
 * Will omit all fields from BaseEntity except for the id.
 * Will omit all fields which are functions.
 * Will omit self referencial fields.
 */
type ClassFields<T> = {
	[K in keyof T]: K extends keyof BaseEntity
		? K extends 'id'
			? K
			: never
		: T[K] extends IsFunction<T[K]>
			? never
			: IsComplexObject<T[K]> extends true
				? K
				: K;
}[keyof T];

/**
 * Extract all fields of a given type to be used as form fields.
 *
 * It removes functions
 * Replaces references to Documents with a string (the src of the uploaded Document)
 * Replaces references to other Entities with a string (the link to the Entity)
 * Keeps moment objects as they are used in our form fields
 * Keeps Location objects as they are used in our form fields
 * Recursively extracts fields from complex objects
 * All fields are optional except for arrays which should be empty arrays if they are not present.
 *
 * @param T The type to extract the fields from.
 */
export type AutoFormFields<T> = {
	[K in ClassFields<T>]?: IsType<T[K], BaseEntity> extends true
		? string
		: IsType<T[K], Document> extends true
			? Document
			: IsType<T[K], moment.Moment> extends true
				? moment.Moment
				: IsType<T[K], Location> extends true
					? Location
					: IsType<T[K], Money> extends true
						? Money
						: T[K] extends Array<infer Y>
							? IsComplexObject<Y> extends true
								? AutoFormFields<T[K]>
								: Y[]
							: IsComplexObject<T[K]> extends true
								? AutoFormFields<T[K]>
								: T[K];
};
