import { History } from 'history';
import { SagaIterator } from 'redux-saga';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import BookingDTO from 'src/entities/booking/dto/BookingDTO';
import BookingProgress from 'src/entities/booking/progress/BookingProgress';
import {
	SUBMIT_BOOKING,
	UPDATE_BOOKING_PROGRESS,
	UPDATE_BOOKING_PROGRESS_BUNDLE,
	failureBookingProgress,
	requestBookingProgress,
	successFetchBookingProgress,
} from 'src/redux/actions/booking-progress';
import { isBookingAlreadySubmitted } from 'src/redux/reducers/booking-progress';
import { BaseAction } from 'src/redux/sagas';
import { getBookingProgress } from 'src/redux/selectors/booking-progress';
import { buildUrl } from 'src/routing';
import { BOOKING_ROUTES } from 'src/scenes/BookingRoutes';
import {
	fetchBookingProcessByBundleCsBus,
	fetchBookingProcessByBundleId,
	fetchBookingProcessByBundleSlug,
	submitBooking,
	updateBooking,
} from 'src/services/booking';
import { SingleEntityState } from 'src/types';

interface BookingProgressAction extends BaseAction {
	slug?: string;
	bundleId?: string;
	csBusEventId?: string;
	csBusBundleId?: string;
	bookingDTO: BookingDTO;
}

/**
 * Handles the initial booking progress fetching.
 * This saga gets called when the user selects a bundle from the search results
 */
function* fetchBookingProgressSaga(action: BookingProgressAction): SagaIterator {
	yield put(requestBookingProgress());

	let result;
	if (action.slug != null) result = yield call(fetchBookingProcessByBundleSlug, action.slug, action.bookingDTO);
	else if (action.bundleId != null)
		result = yield call(fetchBookingProcessByBundleId, action.bundleId, action.bookingDTO);
	else if (action.csBusEventId != null && action.csBusBundleId != null)
		result = yield call(
			fetchBookingProcessByBundleCsBus,
			action.csBusEventId,
			action.csBusBundleId,
			action.bookingDTO,
		);

	const { error, response } = result;

	yield error ? put(failureBookingProgress(error)) : put(successFetchBookingProgress(response));
}

/**
 * Handles updating of booking steps during the booking progress
 */
function* updateBookingProgressSaga(action: BookingProgressAction): SagaIterator {
	yield put(requestBookingProgress());
	const history: History = yield getContext('history');

	const { error, response } = yield call(updateBooking, action.bookingDTO);

	if (error) {
		yield put(failureBookingProgress(error));
		if (isBookingAlreadySubmitted(error.problem)) {
			history.replace(buildUrl(BOOKING_ROUTES.confirmation, undefined, { token: error.problem.options.token }));
			window.location.reload();
		}
	} else {
		yield put(successFetchBookingProgress(response));
	}
}

/**
 * Handles submit booking
 */
function* submitBookingSaga(action: BookingProgressAction): SagaIterator {
	yield put(requestBookingProgress());
	const history: History = yield getContext('history');

	const { error, response } = yield call(submitBooking, action.bookingDTO);

	if (error) {
		yield put(failureBookingProgress(error));
	} else {
		const bookingProgress: SingleEntityState<BookingProgress> = yield select(getBookingProgress);
		const bookingProgressContent = bookingProgress.content;

		if (bookingProgressContent == null) return console.error('No booking progress content available');

		window.dataLayer.push({
			event: 'purchase',
			ecommerce: {
				transactionId: response.token,
				...bookingProgressContent.getGAEcommerceData(),
			},
		});

		// Online payment
		if (response.paymentRedirectUrl != null) {
			// Redirect to online payment site
			if (window.parentIFrame) {
				window.parentIFrame.sendMessage({ replaceMainPageUrl: response.paymentRedirectUrl });
			} else {
				window.location.replace(response.paymentRedirectUrl);
			}
		}

		// Payment on invoice
		else {
			history.push(buildUrl(BOOKING_ROUTES.confirmation, undefined, { token: response.token }));
		}
	}
}

/**
 * Listens to update booking actions and triggers the related saga
 */
export function* generateUpdateBookingProgressWatcher() {
	yield takeLatest(UPDATE_BOOKING_PROGRESS, updateBookingProgressSaga);
}

export function* generateFetchBookingProgressWatcher() {
	yield takeLatest(UPDATE_BOOKING_PROGRESS_BUNDLE, fetchBookingProgressSaga);
}

export function* generateSubmitBookingWatcher() {
	yield takeLatest(SUBMIT_BOOKING, submitBookingSaga);
}
