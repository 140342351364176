import { Component } from 'react';
import Role from 'src/entities/accounts/Role';
import { CompiledRoutes, S_ACCESS, S_COMPONENT } from 'src/routing';

export const BOOKING_ROOT = 'booking';

export const BOOKING_ROUTES = new CompiledRoutes(BOOKING_ROOT, {
	[S_COMPONENT]: Component,
	[S_ACCESS]: [Role.NOT_AUTHENTICATED, Role.CUSTOMER, Role.RESELLER],
	confirmation: {
		[S_COMPONENT]: Component,
	},
});
