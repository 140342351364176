import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Countdown } from 'src/components/elements/countdown/Countdown';
import { Dialog } from './components/elements/dialog';
import { ENV } from './config/env';
import { useDisclosure } from './hooks/useDisclosure';
import { fetchOwnAccount } from './redux/actions/account';

const CookieWrapper: FC = ({ children }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const confirmDialog = useDisclosure();

	const cookieSetup = () => {
		// Already have cookie access -> return
		if (checkCookies()) return;

		// Request storage access for safari
		safariFix(() => {
			// If access is now granted we re-request the account
			// this sets the related authentication cookies if user is logged in
			// and triggers re-rendering
			if (checkCookies()) dispatch(fetchOwnAccount());
			// If we don't have access we redirect to the main page via iframe-resizer
			else if ('parentIFrame' in window) confirmDialog.open();
		});
	};

	return (
		<div onClick={cookieSetup}>
			{children}
			<Dialog variant="info" title={t('error.third_party_cookies.header')} {...confirmDialog}>
				<Countdown timeoutCallback={loadMainPageCookies} body={t('error.third_party_cookies.body')} />
			</Dialog>
		</div>
	);
};

function loadMainPageCookies() {
	window.parentIFrame.sendMessage({
		visitMainPage: true,
		mainPageUrl: ENV.mainUrl,
	});
}

const checkCookies = () => {
	document.cookie = 'cookie_test=1;secure;SameSite=None';
	const result = document.cookie.includes('cookie_test=');
	// Delete cookie
	document.cookie = 'cookie_test=1;secure;SameSite=None;expires=Thu, 01-Jan-1970 00:00:01 GMT';
	return result;
};

const safariFix = (callback: () => void) => {
	if (typeof document.requestStorageAccess === 'function') {
		console.log('safariFix');
		document
			.requestStorageAccess()
			.then(
				() => {
					// Now we have first-party storage access!
					console.log('Got cookie access for Safari workaround');
					if (callback) callback();
				},
				() => {
					console.log('access denied');
				},
			)
			.catch(() => {
				// Error obtaining storage access.
				console.log('Could not get access for Safari workaround');
				if (callback) callback();
			});
	} else if (callback) callback();
};

export default CookieWrapper;
