import { type LoginFormFields } from '@/components/authentication/LoginForm';

export enum Profile {
	DEVELOPMENT = 'development',
	LOCAL = 'local',
	STAGING = 'staging',
	PRODUCTION = 'production',
}

interface AutoLoginCredentials extends LoginFormFields {
	color: string;
	shortName: string;
}

interface EnvironmentConstructor {
	profile: Profile;
	apiUrl: string;
	mainUrl: string;
	googleAnalyticsId?: string;
	googleApiKey?: string;
	sentryDsn?: string;
}

class Environment {
	profile: Profile;

	version: string;

	tomcatUrl: string;

	apiUrl: string;

	publicUrl: string;

	mainUrl: string;

	googleApiKey: string;

	googleAnalyticsId: string;

	sentryDsn: string;

	constructor({ profile, apiUrl, mainUrl, googleApiKey, googleAnalyticsId }: EnvironmentConstructor) {
		this.profile = profile;
		this.version = (import.meta.env.VITE_VERSION as string) ?? 'undefined';
		this.tomcatUrl = apiUrl;
		this.apiUrl = `${apiUrl}api/`;
		this.publicUrl = `${apiUrl}public/`;
		this.mainUrl = mainUrl;
		this.googleApiKey = googleApiKey ?? 'AIzaSyCFslwPYsWPOtWFLUyVgHqNh0CjeoDAN4k';
		this.googleAnalyticsId = googleAnalyticsId ?? 'G-VDB5HCWX6C';
		this.sentryDsn = 'https://0949ad7889284abe8ddcab1ab0fa82a3@o4506518710386688.ingest.sentry.io/4506529957412864';
	}

	public autologinCredentials(): AutoLoginCredentials[] {
		if (this.profile === Profile.PRODUCTION) return [];
		return [
			{ username: 'admin@rechnerherz.at', password: 'admin', color: 'red', shortName: 'Admin' },
			{ username: 'operator@rechnerherz.at', password: 'operator', color: 'orange', shortName: 'Operator' },
			{ username: 'customer@rechnerherz.at', password: 'customer', color: 'green', shortName: 'Customer' },
			{ username: 'reseller@rechnerherz.at', password: 'reseller', color: 'teal', shortName: 'Resller' },
		];
	}
}

const ENV_VARS: { [A in Profile]: Environment } = {
	[Profile.DEVELOPMENT]: new Environment({
		profile: Profile.DEVELOPMENT,
		apiUrl: 'https://localhost:8443/',
		mainUrl: 'https://localhost:3000/',
	}),
	[Profile.LOCAL]: new Environment({
		profile: Profile.LOCAL,
		apiUrl: 'https://localhost:8443/',
		mainUrl: 'https://localhost:3000/',
	}),
	[Profile.STAGING]: new Environment({
		profile: Profile.STAGING,
		apiUrl: 'https://www.tnt.busworld24.com/tomcat/',
		mainUrl: 'https://www.tnt.busworld24.com/',
	}),
	[Profile.PRODUCTION]: new Environment({
		profile: Profile.PRODUCTION,
		apiUrl: 'https://www.busreisebox.com/tomcat/',
		mainUrl: 'https://www.busreisebox.com/',
		googleApiKey: 'AIzaSyBwME26yqavFsZZnM7vcUEmPFN-l-_FmFQ',
		googleAnalyticsId: 'G-7ZT1D806ZY',
	}),
};

// Load environment variables for the active profile
const currentProfile = (import.meta.env.VITE_PROFILE as Profile) || Profile.DEVELOPMENT;
export const ENV = ENV_VARS[currentProfile];

export const IS_DEVELOPMENT = ENV.profile === Profile.DEVELOPMENT;
export const IS_LOCAL = ENV.profile === Profile.LOCAL;
export const IS_STAGING = ENV.profile === Profile.STAGING;
export const IS_PRODUCTION = ENV.profile === Profile.PRODUCTION;

console.info(`Environment ${ENV.profile}`);
console.info(ENV);
