import Passenger from 'src/entities/booking/Passenger';
import Money from 'src/entities/basic-types/Money';
import BundleItemSurcharge from 'src/entities/booking/BundleItemSurcharge';
import { fromJsonArray } from 'src/helper/helper';

export default class ProductsPerPassenger {
	passenger: Passenger;
	items: BundleItemSurcharge[];
	bundleBasePrice: Money;
	sumPrice: Money;

	constructor(json: any) {
		this.passenger = new Passenger(json.passenger);
		this.items = fromJsonArray(BundleItemSurcharge, json.items);
		this.bundleBasePrice = Money.fromJson(json.bundleBasePrice);
		this.sumPrice = Money.fromJson(json.sumPrice);
	}
}
