import Money from 'src/entities/basic-types/Money';

export default class BundleItemSurcharge {
	name: string;
	surcharge: Money;

	constructor(json: any) {
		this.name = json.name;
		this.surcharge = Money.fromJson(json.surcharge);
	}
}
