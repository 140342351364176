import AdminFull from 'src/entities/accounts/AdminFull';
import OperatorFull from 'src/entities/accounts/OperatorFull';
import CustomerFull from 'src/entities/accounts/CustomerFull';
import ResellerFull from 'src/entities/accounts/ResellerFull';
import Role from 'src/entities/accounts/Role';
import Admin from 'src/entities/accounts/Admin';
import Operator from 'src/entities/accounts/Operator';
import Customer from 'src/entities/accounts/Customer';
import Account from 'src/entities/accounts/Account';
import { AccountFull } from 'src/entities/accounts/AccountFull';

/**
 * Creates a new instance of an Account.
 */
export function createAccount(json: any): Account {
	switch (json.role) {
		case Role.ADMIN:
			return new Admin(json);
		case Role.OPERATOR:
			return new Operator(json);
		case Role.CUSTOMER:
			return new Customer(json);
		case Role.RESELLER:
			return new ResellerFull(json);
		default:
			throw new Error(`Unexpected account role: ${json.role}`);
	}
}

/**
 * Creates a new instance of an AccountFull projection.
 */
export function createAccountFull(json: any): AccountFull {
	switch (json.role) {
		case Role.ADMIN:
			return new AdminFull(json);
		case Role.OPERATOR:
			return new OperatorFull(json);
		case Role.CUSTOMER:
			return new CustomerFull(json);
		case Role.RESELLER:
			return new ResellerFull(json);
		default:
			throw new Error(`Unexpected account role: ${json.role}`);
	}
}
