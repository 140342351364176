import { ChangePasswordFormFields } from 'src/components/account/my-profile/change-password/ChangePasswordForm';
import { ChangeUsernameFormFields } from 'src/components/account/my-profile/change-username/ChangeUsernameForm';
import { LoginFormFields } from 'src/components/authentication/LoginForm';
import { ForgotPasswordFormFields } from 'src/components/authentication/password/ForgotPasswordForm';
import { RegisterFormFields } from 'src/components/authentication/RegisterForm';
import { AccountFullFormFields } from 'src/entities/accounts/AccountFull';
import { action } from 'src/redux/actions';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const CONFIRM_REGISTER = 'CONFIRM_REGISTER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_USERNAME = 'CHANGE_USERNAME';
export const CONFIRM_CHANGE_USERNAME = 'CONFIRM_CHANGE_USERNAME';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const PATCH_OWN_ACCOUNT = 'PATCH_OWN_ACCOUNT';

export const FETCH_OWN_ACCOUNT = 'FETCH_OWN_ACCOUNT';
export const REQUEST_OWN_ACCOUNT = 'REQUEST_OWN_ACCOUNT';
export const SUCCESS_FETCH_OWN_ACCOUNT = 'SUCCESS_FETCH_OWN_ACCOUNT';
export const FAILURE_OWN_ACCOUNT = 'FAILURE_OWN_ACCOUNT';

/**
 * Dispatches a login action that is watched by the account saga.
 * If noRedirect is true, don't redirect after a successful login.
 */
export const login = (credentials: LoginFormFields, noRedirect?: boolean) => action(LOGIN, { credentials, noRedirect });

/**
 * Dispatches a logout action that is watched by the account saga.
 */
export const logout = (noRedirect?: boolean) => action(LOGOUT, { noRedirect });

/**
 * Dispatches a register action that is watched by the account saga.
 */
export const register = (data: RegisterFormFields) => action(REGISTER, { data });

/**
 * Dispatches a registration confirm action that is watched by the account saga.
 */
export const confirmRegister = (token: string) => action(CONFIRM_REGISTER, { token });

/**
 * Dispatches a change password action that is watched by the account saga.
 */
export const changePassword = (data: ChangePasswordFormFields) => action(CHANGE_PASSWORD, { data });

/**
 * Dispatches a change username action that is watched by the account saga.
 */
export const changeUsername = (data: ChangeUsernameFormFields) => action(CHANGE_USERNAME, { data });

/**
 * Dispatches a change username confirm action that is watched by the account saga.
 */
export const confirmChangeUsername = (token: string) => action(CONFIRM_CHANGE_USERNAME, { token });

/**
 * Dispatches a forgot password action that is watched by the account saga.
 */
export const forgotPassword = (data: ForgotPasswordFormFields) => action(FORGOT_PASSWORD, { data });

/**
 * Dispatches a reset password action that is watched by the account saga.
 */
export const resetPassword = (token: string, password: string) => action(RESET_PASSWORD, { token, password });

/**
 * Dispatches a fetch own Account action that is watched by the account saga.
 */
export const fetchOwnAccount = () => action(FETCH_OWN_ACCOUNT);

/**
 * Dispatches a request own account action.
 */
export const requestOwnAccount = () => action(REQUEST_OWN_ACCOUNT);

/**
 * Dispatches a successful account fetch action. response contains the authenticated account.
 */
export const successFetchOwnAccount = (response: any) => action(SUCCESS_FETCH_OWN_ACCOUNT, { response });

/**
 * Dispatches a failure account action.
 */
export const failureOwnAccount = (error: any) => action(FAILURE_OWN_ACCOUNT, { error });

/**
 * Dispatches a patch action for the own account (authenticated user).
 */
export const patchOwnAccount = (data: AccountFullFormFields) => action(PATCH_OWN_ACCOUNT, { data });
