export default class DocumentDescription {
	key: string;
	url: string;
	label: string;
	text: string;

	constructor(json: any) {
		this.key = json.key;
		this.url = json.url;
		this.label = json.label;
		this.text = json.text;
	}
}
