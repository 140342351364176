import { Reducer, UnknownAction, combineReducers } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { persistReducer } from 'redux-persist';
import { RESET_STORE } from 'src/redux/actions';
import persistConfig from 'src/redux/persistors';
import ownAccountReducer from 'src/redux/reducers/account';
import { bookingProgressReducer } from 'src/redux/reducers/booking-progress';
import breadcrumbsReducer from 'src/redux/reducers/breadcrumbs';
import createReducers from 'src/redux/reducers/entities';
import { searchFilterReducer } from 'src/redux/reducers/search-filter';
import notificationReducer from 'src/redux/reducers/system-notifications';
import { StoreState } from 'src/types';
import themeReducer from './theme';

/**
 * Combine all reducers to single rootReducer.
 */
function buildAppReducer(): Reducer<any> {
	return combineReducers({
		entities: combineReducers(createReducers()), // dynamic reducer creation from EntityDescriptions
		ownAccount: ownAccountReducer,
		notification: notificationReducer,
		booking: bookingProgressReducer,
		searchFilterState: searchFilterReducer,
		theme: themeReducer,
		breadcrumbs: breadcrumbsReducer,
	});
}

/**
 * Return the combined appReducer.
 *
 * Also handle RESET_STORE action, that resets the whole redux store to it's initial state.
 */
function buildBaseReducer(): Reducer<any> {
	const appReducer = buildAppReducer();

	return (state: {} | StoreState, action: UnknownAction) => {
		if (action.type === RESET_STORE)
			return appReducer(pick(state, ['booking', 'entities.integrations', 'theme']), action);

		return appReducer(state, action);
	};
}

function buildRootReducer() {
	const baseReducer = buildBaseReducer();
	return persistReducer(persistConfig, baseReducer);
}

export default buildRootReducer;
