import BasicType from 'src/entities/basic-types/BasicType';
import { AutoFormFields } from '../BaseEntity';

export default class SEO implements BasicType {
	title: string;
	description: string;
	keywords: string;
	googleTagManagerId: string;

	constructor(title: string, description: string, keywords: string, googleTagManagerId: string) {
		this.title = title;
		this.description = description;
		this.keywords = keywords;
		this.googleTagManagerId = googleTagManagerId;
	}

	toString(): string {
		return this.title;
	}

	equals(other: SEO): boolean {
		return this.title === other.title && this.description === other.description && this.keywords === other.keywords;
	}

	static fromJson(json: any) {
		return new SEO(json.title, json.description, json.keywords, json.googleTagManagerId);
	}

	static emptySEO(): SEO {
		return new SEO('', '', '', '');
	}

	static formFields(seo?: SEO): AutoFormFields<SEO> {
		return {
			title: seo?.title ?? '',
			description: seo?.description ?? '',
			keywords: seo?.keywords ?? '',
			googleTagManagerId: seo?.googleTagManagerId ?? '',
		};
	}
}
