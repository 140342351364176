import BasicType from 'src/entities/basic-types/BasicType';

export default class ReviewScore implements BasicType {
	count: number;
	total: number;
	scores: Record<number, { title: Record<string, string>; rating: number }>;

	constructor(count: number, total: number, scores: any) {
		this.count = count;
		this.total = total;
		this.scores = scores;
	}

	equals(other: any): boolean {
		return this.count === other.count && this.total === other.total;
	}

	toString(): string {
		return String(this.total);
	}

	static fromJson(json: any): ReviewScore | undefined {
		return json != null ? new ReviewScore(json.count, json.total, json.scores) : undefined;
	}

	stars(): number {
		return percentToStars(this.total);
	}
}

/**
 * Convert percentage to stars:
 * - 90-100 => 5 stars
 * - 70-89  => 4 stars
 * - 50-69  => 3 stars
 * - 30-49  => 2 stars
 * - 10-29  => 1 stars
 * - 0-9    => 0 stars
 */
export const percentToStars = (percent: number): number => {
	return Math.round(percent / 20);
};
