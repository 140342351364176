import { BookingProgressStep } from 'src/entities/booking/progress/steps/BookingProgressStep';
import { PaxStepPassenger } from '../../Passenger';

export default class PaxStep extends BookingProgressStep {
	pax: number;
	readonly paxStepPassengers: PaxStepPassenger[];
	prevPax?: number;
	readonly needsPassengerName: boolean;

	constructor(json: any) {
		super(json);

		this.pax = json.pax;
		this.paxStepPassengers = json.paxStepPassengers;
		this.prevPax = json.prevPax;
		this.needsPassengerName = json.needsPassengerName;
	}

	setPax(pax: number) {
		this.prevPax = this.pax;
		this.pax = pax;
	}
}
