import { Theme } from 'src/entities/integration/Integration';
import { action } from 'src/redux/actions';

export const SET_THEME = 'SET_THEME';
export const SWITCH_THEME = 'SWITCH_THEME';
export const RESTORE_PREVIOUS_THEME = 'RESTORE_PREVIOUS_THEME';

export const setTheme = (theme: Theme) => action(SET_THEME, { theme });
export const switchTheme = (theme: Theme) => action(SWITCH_THEME, { theme });
export const restorePreviousTheme = (theme?: Theme) => action(RESTORE_PREVIOUS_THEME, { theme });
