import moment from 'moment-timezone';
import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import Money from 'src/entities/basic-types/Money';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';

export type CouponFormFields = AutoFormFields<Coupon>;

export enum CouponType {
	RELATIVE = 'RELATIVE',
	ABSOLUTE = 'ABSOLUTE',
}

export const isCouponType = (value: any) => value === CouponType.ABSOLUTE || value === CouponType.RELATIVE;

export default class Coupon extends BaseEntity {
	name: string;
	code: string;
	type: CouponType;
	validFrom: moment.Moment;
	validTo?: moment.Moment;
	valid: boolean;
	quantity: number;
	discount: number | Money;
	readonly redeemCount: number;

	constructor(json: any) {
		super(json);
		this.name = json.name;
		this.code = json.code;
		this.type = json.type;
		this.validFrom = moment(json.validFrom);
		this.validTo = json.validTo ? moment(json.validTo) : undefined;
		this.valid = json.valid;
		this.quantity = json.quantity;
		this.redeemCount = json.redeemCount;
		this.discount =
			json.type === CouponType.RELATIVE ? json.relativeDiscount : Money.fromJson(json.absoluteDiscount);
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.COUPON;
	}

	static formFields(entity?: Coupon): CouponFormFields {
		return {
			...BaseEntity.formFields(entity),
			name: entity?.name,
			code: entity?.code,
			type: entity?.type ?? CouponType.RELATIVE,
			validFrom: entity?.validFrom,
			validTo: entity?.validTo,
			valid: entity?.valid,
			quantity: entity?.quantity,
			discount: entity?.discount,
			redeemCount: entity?.redeemCount,
		};
	}
}
