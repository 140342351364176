import { BookingProgressStep, BookingProgressStepError } from 'src/entities/booking/progress/steps/BookingProgressStep';
import V4InsuranceContractRS from 'src/entities/booking/progress/steps/insurance/V4InsuranceContractRS';
import V4InsuranceOfferRS, { InsuranceProduct } from 'src/entities/booking/progress/steps/insurance/V4InsuranceOfferRS';
import { fromJsonArray } from 'src/helper/helper';

export default class InsuranceStep extends BookingProgressStep {
	selectedProduct: InsuranceProduct;
	selectedOffer?: V4InsuranceOfferRS;
	acceptReceiveEmail: boolean;
	offers: V4InsuranceOfferRS[];
	contract?: V4InsuranceContractRS;
	validInsuranceContract: boolean;

	constructor(json: any) {
		super(json);

		this.contract = json.contract ? new V4InsuranceContractRS(json.contract) : undefined;
		this.offers = fromJsonArray(V4InsuranceOfferRS, json.offers);
		this.selectedProduct = json.selectedProduct as InsuranceProduct;
		this.acceptReceiveEmail = json.acceptReceiveEmail || false;
		this.validInsuranceContract = json.validInsuranceContract;
		this.selectedOffer = json.selectedOffer ? new V4InsuranceOfferRS(json.selectedOffer) : undefined;
	}

	applyInsuranceProgressError() {
		this.contract = undefined;
		this.offers = [];
		this.selectedProduct = InsuranceProduct.NONE;
		this.selectedOffer = undefined;
		this.acceptReceiveEmail = false;
		this.validInsuranceContract = false;
		this.error = BookingProgressStepError.INSURANCE_PROGRESS_ERROR;
	}
}
