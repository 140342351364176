import EntityDescription, { Projection } from 'src/entities/description/EntityDescription';
import { action, FetchDescription } from 'src/redux/actions';

export const CREATE_BUNDLE_ITEM_WITH_PRODUCT = 'CREATE_BUNDLE_ITEM_WITH_PRODUCT';
export const UPDATE_BUNDLE_ITEM_WITH_PRODUCT = 'UPDATE_BUNDLE_ITEM_WITH_PRODUCT';

export const createBundleItemWithProduct = (
	data: any,
	productEntityDescription: EntityDescription,
	projection: Projection = Projection.NONE,
	fetchAfterSuccess?: FetchDescription[],
) => action(CREATE_BUNDLE_ITEM_WITH_PRODUCT, { data, productEntityDescription, projection, fetchAfterSuccess });

export const patchBundleItemWithProduct = (
	data: any,
	productEntityDescription: EntityDescription,
	bundleId: number,
	projection: Projection = Projection.NONE,
	fetchAfterSuccess?: FetchDescription[],
) =>
	action(UPDATE_BUNDLE_ITEM_WITH_PRODUCT, {
		data,
		productEntityDescription,
		bundleId,
		projection,
		fetchAfterSuccess,
	});
