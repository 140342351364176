import Money from 'src/entities/basic-types/Money';
import Bundle from 'src/entities/bundle/Bundle';
import Feature from 'src/entities/feature/Feature';
import NearestStop from 'src/entities/products/stop/NearestStop';
import Tag from 'src/entities/tag/Tag';
import { fromJsonArray } from 'src/helper/helper';

export default class BundleBooking extends Bundle {
	tags: Tag[];
	features: Feature[];
	nearestStop?: NearestStop;
	readonly resellerPrice?: Money;

	constructor(json: any) {
		super(json);

		this.tags = fromJsonArray(Tag, json.tags);
		this.features = fromJsonArray(Feature, json.features);
		this.resellerPrice = json.resellerPrice ? Money.fromJson(json.resellerPrice) : undefined;
		this.nearestStop = json.nearestStop ? new NearestStop(json.nearestStop) : undefined;
	}
}
