import BookingDTO from 'src/entities/booking/dto/BookingDTO';
import {
	BookingProgressStepError,
	BookingStep,
	BookingProgressStep,
} from 'src/entities/booking/progress/steps/BookingProgressStep';
import { action } from 'src/redux/actions';

export const START = 'START';
export const SET_PAX = 'SET_PAX';
export const SET_CHECKED_TERMS = 'SET_CHECKED_TERMS';
export const SET_CHECKED_PRIVACY = 'SET_CHECKED_PRIVACY';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_BOOKING_PROGRESS_STEP_ERROR = 'SET_BOOKING_PROGRESS_STEP_ERROR';

export const UPDATE_BOOKING_PROGRESS_BUNDLE = 'UPDATE_BOOKING_PROGRESS_BUNDLE';
export const UPDATE_BOOKING_PROGRESS = 'UPDATE_BOOKING_PROGRESS';
export const SUBMIT_BOOKING = 'SUBMIT_BOOKING';
export const REQUEST_BOOKING_PROGRESS = 'REQUEST_BOOKING_PROGRESS';
export const SUCCESS_FETCH_BOOKING_PROGRESS = 'SUCCESS_FETCH_BOOKING_PROGRESS';
export const SUCCESS_SUBMIT_BOOKING = 'SUCCESS_SUBMIT_BOOKING';
export const FAILURE_BOOKING_PROGRESS = 'FAILURE_BOOKING_PROGRESS';
export const FAILURE_INSURANCE_PROGRESS = 'FAILURE_INSURANCE_PROGRESS';

/**
 * Updates the booking progress with the given content
 */
export const updateBookingProgress = (bookingDTO: BookingDTO) => action(`${UPDATE_BOOKING_PROGRESS}`, { bookingDTO });
export const updateBookingProgressBundle = (slug: string, bookingDTO?: BookingDTO) =>
	action(UPDATE_BOOKING_PROGRESS_BUNDLE, { slug, bookingDTO });
export const updateBookingProgressBundleId = (bundleId: string, bookingDTO?: BookingDTO) =>
	action(UPDATE_BOOKING_PROGRESS_BUNDLE, { bundleId, bookingDTO });

// TODO: Remove when no integrations are fetching by csBusEventId/csBusBundleId anymore
export const updateBookingProgressBundleCsBus = (
	csBusEventId: string,
	csBusBundleId: string,
	bookingDTO?: BookingDTO,
) => action(UPDATE_BOOKING_PROGRESS_BUNDLE, { csBusEventId, csBusBundleId, bookingDTO });
export const submitBooking = (bookingDTO: BookingDTO) => action(`${SUBMIT_BOOKING}`, { bookingDTO });

export const startBooking = () => action(START);
export const setPax = (pax: number) => action(SET_PAX, { pax });
export const setCheckedTerms = (checked: boolean) => action(SET_CHECKED_TERMS, { checked });
export const setCheckedPrivacy = (checked: boolean) => action(SET_CHECKED_PRIVACY, { checked });
export const setCurrentStep = (step: BookingProgressStep) => action(SET_CURRENT_STEP, { step });

export const requestBookingProgress = () => action(REQUEST_BOOKING_PROGRESS);
export const successFetchBookingProgress = (response: any) => action(SUCCESS_FETCH_BOOKING_PROGRESS, { response });
export const successSubmitBooking = () => action(SUCCESS_SUBMIT_BOOKING);
export const failureBookingProgress = (error: any) => action(FAILURE_BOOKING_PROGRESS, { error });
export const setBookingProgressStepError = (step: BookingStep, error: BookingProgressStepError) =>
	action(SET_BOOKING_PROGRESS_STEP_ERROR, { step, error });
