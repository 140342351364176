import moment from 'moment-timezone';
import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import Money from 'src/entities/basic-types/Money';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import { momentWithTimeZoneFromJson } from 'src/helper/helper';

export type BundleItemFormFields = AutoFormFields<BundleItem>;

export default class BundleItem extends BaseEntity {
	optional: boolean;
	excluded: boolean;
	forChildren: boolean;
	timeSlot: number;
	surcharge: Money;
	surchargeOncePerBookingText?: string;
	description: string;
	start?: moment.Moment;
	end?: moment.Moment;
	stopTime?: moment.Moment;
	stopNoTime: boolean;
	csBusStopId?: string;

	constructor(json: any) {
		super(json);

		this.optional = json.optional;
		this.excluded = json.excluded;
		this.forChildren = json.forChildren;
		this.timeSlot = json.timeSlot;
		this.surcharge = Money.fromJson(json.surcharge);
		this.surchargeOncePerBookingText = json.surchargeOncePerBookingText;
		this.description = json.description;
		this.start = json.start != null ? momentWithTimeZoneFromJson(json.start) : undefined;
		this.end = json.end != null ? momentWithTimeZoneFromJson(json.end) : undefined;
		this.stopTime = json.stopTime != null ? momentWithTimeZoneFromJson(json.stopTime) : undefined;
		this.stopNoTime = json.stopNoTime;
		this.csBusStopId = json.csBusStopId;
	}

	toString(): string {
		return this.className;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.BUNDLE_ITEM;
	}

	static formFields(entity?: BundleItem): BundleItemFormFields {
		return {
			...BaseEntity.formFields(entity),
			optional: entity?.optional,
			excluded: entity?.excluded,
			forChildren: entity?.forChildren,
			timeSlot: entity?.timeSlot,
			surcharge: entity?.surcharge,
			surchargeOncePerBookingText: entity?.surchargeOncePerBookingText,
			description: entity?.description,
			start: entity?.start,
			end: entity?.end,
			stopTime: entity?.stopTime,
			stopNoTime: entity?.stopNoTime,
			csBusStopId: entity?.csBusStopId,
		};
	}
}
