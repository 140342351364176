import { FallbackRender } from '@sentry/react';
import { Icon, Message } from 'semantic-ui-react';

export const ErrorBoundaryHandler: FallbackRender = ({ componentStack, error }) => (
	<Message icon negative size="huge">
		<Icon name="warning sign" />
		<Message.Content>
			<Message.Header>Error caught by error boundary</Message.Header>
			{error != null && (
				<>
					<p>
						{error.name}: {error.message}
					</p>
					<p style={{ whiteSpace: 'pre-line' }}>{error.stack}</p>,
				</>
			)}
			{componentStack != null && <p style={{ whiteSpace: 'pre-line' }}>{componentStack}</p>}
		</Message.Content>
	</Message>
);
