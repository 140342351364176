import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import Product from 'src/entities/products/Product';
import { AutoFormFields } from '../BaseEntity';

export type RoomFormFields = AutoFormFields<Room>;
export default class Room extends Product {
	beds: number;
	readonly csBusRoomGroup?: any;

	constructor(json: any) {
		super(json);

		this.beds = json.beds;
		this.csBusRoomGroup = json.csBusRoomGroup;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.ROOM;
	}

	static formFields(entity?: Room): RoomFormFields {
		return {
			...Product.formFields(entity),
			beds: entity?.beds,
			csBusRoomGroup: entity?.csBusRoomGroup,
		};
	}
}
