import { BookingProgressStep } from 'src/entities/booking/progress/steps/BookingProgressStep';
import { PaymentMethod } from 'src/entities/payment/Gateway';

export default class SummaryStep extends BookingProgressStep {
	checkedTerms: boolean;
	checkedPrivacy: boolean;
	checkedInsuranceConditions: boolean;
	comment: string;
	newsletter: boolean;
	paymentMethod: PaymentMethod;

	constructor(json: any) {
		super(json);

		this.checkedTerms = json.checkedTerms;
		this.checkedPrivacy = json.checkedPrivacy;
		this.checkedInsuranceConditions = json.checkedInsuranceConditions;
		this.comment = json.comment;
		this.newsletter = json.newsletter;
		this.paymentMethod = json.paymentMethod;
	}
}
