import moment from 'moment-timezone';
import { ENV } from 'src/config/env';
import BaseEntity from 'src/entities/BaseEntity';
import Document from 'src/entities/basic-types/Document';
import Money from 'src/entities/basic-types/Money';

export default abstract class Invoice extends BaseEntity {
	invoiceNumber: number;
	formattedInvoiceNumber: string;
	invoiceDate: moment.Moment;
	document: Document;
	price: Money;

	protected constructor(json: any) {
		super(json);

		this.invoiceNumber = json.invoiceNumber;
		this.formattedInvoiceNumber = json.formattedInvoiceNumber;
		this.invoiceDate = json.invoiceDate;
		this.document = Document.fromJson(json.document)!;
		this.price = Money.fromJson(json.price);
	}

	toString(): string {
		return this.formattedInvoiceNumber;
	}

	getInvoiceUrl(): string {
		return `${ENV.apiUrl}invoices/documents/${this.identifier}.pdf`;
	}
}
